//Je veux afficher tout mes contrats regrouper par collection
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import axios from "axios"
import { useRef } from 'react'
import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa'
import { Link, useLoaderData } from 'react-router-dom';
import Header from '../../UI/Header';
import ContractAPI from '../../../APIs/ContractAPI';

function CollectionList() {
    const currentUser = useLoaderData();


    const [contracts, setContracts] = useState({
        id_contracts: '',
        name: '',
        collection_id: '',
        address: '',
        owner: '',
        created_date: '',
        modified_date: ''
    });


    const getContracts = useCallback(async () => {

        const response = await ContractAPI.get('/')
        if (response.data.length !== 0) {
            console.log(response.data)
            const contractsArr = []
            response.data.forEach((contract) => {
                contractsArr.push(contract)
            })

            setContracts(contractsArr)
        }



    }, [contracts.id_contracts, contracts.name, contracts.collection_id, contracts.address, contracts.owner, contracts.created__date, contracts.modified_date, contracts.deployed])




    useEffect(() => {
        getContracts()
    }, [getContracts])

    if (typeof currentUser !== 'undefined') {
        if (currentUser.role === 'Administrator') {
            let contractsDisplay;
            if (contracts.id_contracts !== '') {

                contractsDisplay = contracts.map((contract, index) => {
                    console.log(contract)
                    return (
                        <div key={index}>
                            <p>contract name : {contract.name}</p>
                            <p>contract owner : {contract.owner}</p>
                            <p>contract address : {contract.address}</p>
                            <p>{contract.deployed === 1 ? 'En cours' : "A l'arrêt"}</p>
                            <Link to={`/admin/contracts/${contract.name}`}>
                                <button>+ de details</button>
                            </Link>
                        </div>
                    )


                })
            }

            return (
                <React.Fragment>
                    <Header />
                    <Link to='/admin/contracts/create'>
                        <button>Créer un contrat</button>
                    </Link>
                    {contractsDisplay}
                </React.Fragment>
            )
        }
    } else {
        return (<></>)
    }
}

export default CollectionList;