// @ts-nocheck
import React, { useCallback, useRef } from 'react'
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
import { EffectCoverflow } from "swiper";
import "swiper/swiper.scss";
import "swiper/modules/effect-coverflow/effect-coverflow.scss";
import "swiper/modules/pagination/pagination.scss";
import stylecard from '../../styles/NFTS/NFTArtistPreview.module.scss';
import Tilt from 'react-vanilla-tilt'
import CollectionAPI from '../../APIs/CollectionAPI';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Image from '../Image/Image';
import { AiFillCloseCircle } from 'react-icons/ai'
import { BsZoomIn } from 'react-icons/bs'
import LoaderSpinner from '../Loading/LoaderSpinner';
function CustomSwiper() {

    const [metadatas, setMetadatas] = useState(...[]);
    const [imgSrc, setImgSrc] = useState('');
    const [displayLargeImage, setDisplayLargeImage] = useState(false)
    const [loading, setLoading] = useState(true)
    const location = useLocation();
    const swiperRef = useRef();
    const currentImageRef = useRef();


    const getMetadatas = useCallback(async () => {

        setLoading(true)
        const collectionName = decodeURI(location.pathname.slice(9))
        const contractsInfos = await CollectionAPI.get(`/contracts/${collectionName}`)
        if (contractsInfos.data.length !== 0) {
            const contract = contractsInfos.data[0]
            const ipfs = contract.ipfs.replace('ipfs://', 'https://nfsquat.mypinata.cloud/ipfs/')
            const tokenLength = contract.name === 'SDB' ? 4 : 3
            const promises = []
            for (let i = 0; i < tokenLength; i++) {
                promises.push(axios.get(`${ipfs}${i}.json`, { headers: { 'Accept': 'text/plain' } }))
            }

            Promise.all(promises)
                .then((responses) => {
                    const metadatasArr = []
                    responses.forEach((response) => {
                        const data = response.data
                        if (data.image.includes('gateway.pinata.cloud')) {
                            const newSrc = data.image.replace('gateway.pinata.cloud', 'nfsquat.mypinata.cloud')
                            data.image = newSrc
                        }
                        metadatasArr.push(data)
                    })
                    setMetadatas(metadatasArr)
                })
                .catch((error) => {
                    console.log(error)
                })
                setLoading(false)

        }

    }, [location.pathname, setMetadatas])

    const handleDisplayLargeImage = (src) => {
        setImgSrc(src)

        setDisplayLargeImage(true)
    }

    useEffect(() => {
        getMetadatas();
    }, [getMetadatas])



    return (

        <>
            {!loading ?
                <Swiper
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    effect={"coverflow"}
                    centeredSlides={true}
                    slidesPerView={4}
                    initialSlide={1}
                    coverflowEffect={{
                        rotate: 100,
                        stretch: 10,
                        depth: 20,
                        modifier: 0.5,
                        slideShadows: false,

                    }}
                    pagination={{ clickable: true }}
                    modules={[EffectCoverflow]}
                    className="mySwiper"
                >

                    {!loading && metadatas ? metadatas.map((metadata, index) => {
                        const swiper = swiperRef.current
                        console.log(metadata)
                        return (
                            <SwiperSlide ref={swiper} onClick={() => swiper.slideTo(swiper.clickedIndex)} key={index}>
                                <Tilt className={stylecard.TiltCard} style={{ backgroundColor: 'transparent' }}>
                                    <div className={`${stylecard.card} ${stylecard.shadow}`} >
                                        <Image innerRef={currentImageRef} classes={metadata.authors === 'Bruno Verjus' ? stylecard.cardimgBrunoVerjus : stylecard.cardimg} urls={[metadata.image]} alt={metadata.description} />
                                        <div className={stylecard.imageOverlay}>
                                            <h2 className={stylecard.h2Inside}>{metadata.name} - {metadata.properties.supply} ex</h2>

                                            < BsZoomIn onClick={() => { handleDisplayLargeImage(metadata.image) }} className={stylecard.ZoomIcon} />
                                        </div>
                                    </div>
                                </Tilt>
                            </SwiperSlide>

                        )
                    }) : <LoaderSpinner width={'7em'} height={'7em'} />}

                </Swiper>



                : <LoaderSpinner width={'7em'} height={'7em'} />}

            {displayLargeImage ?
                <div className={stylecard.largeImageContainer}>
                    <Tilt className={stylecard.largeTiltCard} style={{ backgroundColor: 'transparent' }}>
                        <div className={`${stylecard.largeCard} ${stylecard.largeShadow}`} >

                            <Image innerRef={currentImageRef} classes={stylecard.largeCardimg} urls={[imgSrc]} alt={""} />
                            <div className={stylecard.largeImageOverlay}>
                                <AiFillCloseCircle onClick={() => setDisplayLargeImage(false)} />
                            </div>
                        </div>
                    </Tilt>
                </div>

                : ''}
        </>
    );
    //            {isLottery ? <div className={stylecard.ButtonParticipation}><button className={stylecard.ParticipationLot}> Participer à la loterie promotionnelle </button></div> : ''}

}


export default CustomSwiper;

