// @ts-nocheck
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react'
import Header from '../../UI/Header'
import styles from '../../../styles/Artists/Artist.module.scss'
import { useLocation, useNavigate, useLoaderData } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'
import ArtistAPI from '../../../APIs/ArtistAPI'

function CreateArtist() {


    const [currentArtist, setCurrentArtist] = useState({
        firstname: '',
        lastname: '',
        pseudo: '',
        instagram: '',
        twitter: '',
        facebook: '',
        website: '',
        bio: ''

    })

    const currentUser = useLoaderData()



    async function createArtist() {

        const payload = { ...currentArtist, image: '', slug: currentArtist.pseudo }
        console.log(payload)
        await ArtistAPI.post(`/create`, payload)
        window.location.replace(`${window.location.origin}/talents`);

    }


    const handleChange = event => {
        const { name, value } = event.target
        setCurrentArtist({ ...currentArtist, [name]: value })
    }

    console.log(currentUser)
    if (typeof currentUser !== 'undefined') {
        if (currentUser.role === 'Administrator') {
            return (
                <React.Fragment>
                    <Header />
                    <div>
                        <h1>CreateArtist Component</h1>
                        <input name='firstname' onChange={handleChange} className='' placeholder='Prénom'></input>
                        <input name='lastname' onChange={handleChange} className='' placeholder='Nom'></input>
                        <input name='pseudo' onChange={handleChange} className='' placeholder='Pseudo'></input>
                        <input name='instagram' onChange={handleChange} className='' placeholder='Instagram'></input>
                        <input name='twitter' onChange={handleChange} className='' placeholder='Twitter'></input>
                        <input name='facebook' onChange={handleChange} className='' placeholder='Facebook'></input>
                        <input name='webiste' onChange={handleChange} className='' placeholder='Website'></input>
                        <textarea rows={3} cols={50} name='bio' onChange={handleChange} className='' placeholder='Bio'></textarea>
                        <button type='submit' onClick={createArtist}>Créer un nouveau talent</button>
                    </div>
                    <h1>{currentUser.role}</h1>

                </React.Fragment>
            )
        }

    } else if (typeof currentUser !== 'undefined') {
        if (currentUser.role !== 'Administrator') {
            return (
                <React.Fragment>
                    <Header />
                    <h1>Vous n'avez pas la permission d'accéder à cette page</h1>
                    <h1>{currentUser.role}</h1>
                </React.Fragment>
            )
        }

    } else {
        <React.Fragment>

            <Header />
            <h1>Loading</h1>
        </React.Fragment>
    }

}



export default CreateArtist