import React, { useState, useEffect } from 'react'
import LoaderSpinner from '../Loading/LoaderSpinner';
import styles from '../../styles/Image/Image.module.scss'
import ImageLoader from '../Loading/ImageLoader';


function Image({ urls, classes, alt, innerRef }) {

    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoading) {
                setIsLoading(false)
                setHasError(true)
            }
        }, 30000);
        return () => clearTimeout(timer);
    }, [isLoading]);

    const onLoad = (index, event) => {
        if (index === urls.length - 1) {
            setIsLoading(false)

            const parent = event.target.parentElement
            if (parent.classList.contains('NFTArtistPreview_card')) {
                parent.style = ""
            }
        }
    };

    if (urls && urls.length > 0) {
        if (typeof classes === 'undefined') {
            classes = ''
        }
        return (
            <>
                {isLoading && <LoaderSpinner  width={'5em'} height={'5em'}/>}
                {hasError && <p>Une erreur est survenue au chargement de l'image</p>}
                {!hasError && urls.map((url, index) => (
                    <img ref={innerRef} className={isLoading ? styles.imageIsLoading : `${styles.imageLoaded} ${classes}`} src={url} onLoad={(event) => onLoad(index, event)} key={index} alt={alt} />
                ))}
            </>
        )
    } else {
        return (
            <>
                <p>Une erreur est survenue : aucune URL n'a été fournie</p>
            </>
        )
    }
}

export default Image
