// @ts-nocheck
import React, { useState, useCallback, useEffect, useContext, useTransition } from 'react'
import { Link, useNavigate, useLoaderData, useLocation } from 'react-router-dom'
import { ethers } from 'ethers';
import axios from 'axios';
import * as ContractFunctions from '../../Functions/ContractFunctions'
import CollectionAPI from '../../APIs/CollectionAPI';
import CustomCountdown from '../Countdown/CustomCountdown';
import { Triangle } from 'react-loader-spinner';
import { BsPatchCheckFill } from 'react-icons/bs';
import { AiOutlineWarning } from 'react-icons/ai';
import { FaEthereum } from 'react-icons/fa'
import { TbWaveSine } from 'react-icons/tb'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FiCheckCircle } from 'react-icons/fi'
import { CirclesWithBar } from 'react-loader-spinner';
import { BiUserCircle } from 'react-icons/bi'
import { IoMdCloseCircle } from 'react-icons/io'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import styles from '../../styles/NFTS/NFT.module.scss'
import stylesLottery from '../../styles/Lottery/Lottery.module.scss'
import stylesPerso from '../../styles/Lottery/LotteryPerso.module.scss'
import stylesPopup from '../../styles/TransactionPopup/TransactionPopup.module.scss'
import UserAPI from '../../APIs/UserAPI';
import { ProviderContext, UserContext } from '../Context/Context';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { useTranslation } from 'react-i18next';
import PriceConverter from '../NFTs/PriceConverter';

function EnterLottery(props) {

    const location = useLocation()
    const navigate = useNavigate();
    const [metadatas, setMetadatas] = useState();
    const [error, setError] = useState()
    const [transactionReceipt, setTransactionReceipt] = useState();
    const [transactionIsLoading, setTransactionIsLoading] = useState();
    const [transactionStatus, setTransactionStatus] = useState();
    const [transactionSuccess, setTransactionSuccess] = useState();
    const [showPopup, setShowPopup] = useState(false)
    const [showEmailStep, setShowEmailStep] = useState(false)
    const [collection, setCollection] = useState()
    const [extras, setExtras] = useState({ contractAddress: '', contractName: '', contractId: '', lottery: '', lotteryPrice: '' })
    const [players, setPlayers] = useState([])
    const [playerAlreadyEnter, setPlayerAlreadyEnter] = useState();
    const [ethPrice, setEthPrice] = useState()
    const [eurPrice, setEurPrice] = useState()
    const [priceSale, setPriceSale] = useState()
    const [chain, setChain] = useState(null)
    const [currentStyle, setCurrentStyle] = useState()
    const [contract, setContract] = useState(null)

    const [provider] = useContext(ProviderContext)
    const [user, setUser] = useContext(UserContext);
    const { t } = useTranslation();



    const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY,
        {
            extensions: [new ConnectExtension()]
        })
    let collectionName;
    if (location.state === null || location.state.data === undefined) {
        const url = window.location.pathname.split('/')[2]
        collectionName = decodeURI(url)

    } else {
        collectionName = location.state.data
    }




    const getCollection = useCallback(async () => {

        try {
            const response = await CollectionAPI.get(`/name/${collectionName}`)
            if (response.data.length !== 0) {
                setCollection(response.data[0])
            }
        } catch (e) {
            if (e)
                throw Error(e.message)
        }
    }, [setCollection])

    const getExtras = useCallback(async () => {
        if (typeof collection !== 'undefined') {
            const response = await CollectionAPI.get(`/${collection.idcollection}/contracts`)
            if (response.data.length !== 0) {
                const contractName = response.data[0].name;
                const collectionId = response.data[0].collection_id;
                const contractAddress = response.data[0].address
                setExtras({ contractAddress: contractAddress, contractName: contractName, contractId: response.data[0].id_contracts, lottery: response.data[0].lottery, lotteryPrice: response.data[0].lottery_price, collectionId })


            }
        }
    }, [setExtras, location, collection])

    const getPlayers = useCallback(async () => {
        if (collection && provider.hasOwnProperty("_isProvider")) {

            const response = await CollectionAPI.get(`/${collection.idcollection}/contracts`)

            if (user && user.hasOwnProperty("wallet") && provider.hasOwnProperty('_isProvider')) {
                try {
                    if (response.data.length !== 0) {
                        const contractName = response.data[0].name;
                        const collectionId = response.data[0].collection_id;
                        const contractAddress = response.data[0].address;
                        const network = await provider.getNetwork()

                        if (network.chainId === 1) {
                            const signer = provider.getSigner()
                            const json = await ContractFunctions.loadAbiContract(contractName, collectionId)
                            const contract = new ethers.Contract(contractAddress, json.abi, signer)
                            const players = await contract.getPlayers();
                            setPlayers(players)
                            setChain(network.chainId)
                        } else {
                            setPlayers([])
                            setChain(network.chainId)
                            setPlayerAlreadyEnter(false)
                        }

                    }
                } catch (e) {
                    if (e)
                        throw Error(e.message)
                }
            }

        }
    }, [collection, setPlayers, setChain, provider])





    const getMetadata = useCallback(async () => {
        if (typeof collection !== 'undefined') {
            const response = await CollectionAPI.get(`/${collection.idcollection}/contracts`)
            if (response.data.length !== 0) {
                const ipfs = response.data[0].ipfs
                const ipfsFormatted = ipfs.replace('ipfs://', 'https://nfsquat.mypinata.cloud/ipfs/')
                let tokenIds = [0, 1, 2];
                const isSDB = response.data[0].name === 'SDB' ? true : false
                if (isSDB) {
                    tokenIds.push(3)
                }

                const metadatasArr = []
                for (const index of tokenIds) {

                    const datas = await axios.get(`${ipfsFormatted}${index}.json`)
                    if (datas.data.length !== 0) {
                        metadatasArr.push(datas.data)
                    }
                }
                setMetadatas(metadatasArr)
            }
        }


    }, [collection, setMetadatas])



    async function enterWithMagic() {
        if (user && user.hasOwnProperty("wallet")) {
            if (collection) {
                setTransactionIsLoading(true)
                setTransactionStatus('waiting')

                const response = await CollectionAPI.get(`/${collection.idcollection}/contracts`)
                if (response.data.length !== 0) {
                    try {
                        const contractName = response.data[0].name;
                        const collectionId = response.data[0].collection_id;
                        const contractAddress = response.data[0].address

                        const signer = provider.getSigner()
                        const json = await ContractFunctions.loadAbiContract(contractName, collectionId)
                        const contract = new ethers.Contract(contractAddress, json.abi, signer)
                        const priceSale = await contract.priceSale();
                        let overrides = {
                            from: user.wallet,
                            value: priceSale.toString()
                        }
                        const gasEstimated = await contract.estimateGas.enter(overrides);
                        console.log(gasEstimated)
                        const newGasLimit = gasEstimated.toNumber() * 1.30

                        overrides.gasLimit = Math.ceil(newGasLimit)
                        console.log(overrides)
                        const tx = await contract.enter(overrides)
                        if (tx !== null) {
                            setTransactionStatus('user confirm')
                            const txFinal = await tx.wait()

                            if (txFinal !== null) {

                                setTransactionReceipt(txFinal)
                                setPlayerAlreadyEnter(true)
                                setTransactionStatus('success')
                                setTransactionIsLoading(false)

                            } else {
                                setTransactionStatus('failed')
                                setTransactionIsLoading(false)
                                setPlayerAlreadyEnter(false)


                            }
                        }
                    } catch (e) {
                        if (e.message.includes('insufficient funds')) {
                            handleShowMagicWallet();
                            setError("Vous n'avez pas les fonds pour participer. Le wallet va s'ouvrir pour que vous puissiez ajouter des fonds.")
                            setTransactionIsLoading(false)
                            setShowPopup(false)
                            setPlayerAlreadyEnter(false)


                        } else if (e.code === 'ACTION_REJECTED') {
                            setError("Transaction annulée")
                            setTransactionIsLoading(false)
                            setShowPopup(false)
                            setPlayerAlreadyEnter(false)



                        } else {
                            setError(e.message)
                            setTransactionIsLoading(false)
                            setShowPopup(false)
                            setPlayerAlreadyEnter(false)

                        }
                    }

                }
            }

        }
    }
    const handleShowMagicWallet = async () => {
        const { walletType } = await magic.wallet.getInfo();

        if (walletType === "magic") {
            await magic.wallet.showUI().catch((e) => {
                console.log(e);
            })
        } else {
            console.log('bad wallet')
        }
    }



    const checkPlayerStatus = useCallback(async () => {
        if (user && user.hasOwnProperty('idusers')) {

            if (players.length > 0) {
                const findAddress = players.find((element) => element.toLowerCase() === user.wallet.toLowerCase())
                if (typeof findAddress !== 'undefined') {

                    setPlayerAlreadyEnter(true)
                    setTransactionStatus('success')
                } else {

                    setPlayerAlreadyEnter(false)
                }

            } else {

                setPlayerAlreadyEnter(false)

            }

        }

    }, [players.length, user])



    const userExistInDb = async (wallet) => {
        const user = await UserAPI.get(`/${wallet}`)
        if (user.data.length !== 0) {
            return user.data[0]
        } else {
            return false
        }
    }


    const createUserForDB = async (payload) => {
        const userCreated = await UserAPI.post(`/create`, payload)
        if (userCreated.affectedRows !== 0) {
            const newUser = await UserAPI.get(`/${payload.wallet}`)
            return newUser
        }
    }



    const handleUpdateEmail = async (event) => {
        if (user && user.hasOwnProperty('idusers')) {
            let parentDiv;
            if (event.target.nodeName === 'svg') {
                parentDiv = event.target.parentElement
            } else {
                parentDiv = event.target.parentElement.parentElement
            }
            const input = parentDiv.children[0]
            if (input.value) {
                const email = input.value
                const wallet = user.wallet
                const payload = {
                    username: user.username,
                    lastname: user.lastname,
                    firstname: user.firstname,
                    email,
                    phone: user.phone,
                    wallet

                }
                const response = await UserAPI.put(`update/${wallet}`, payload)
                if (response.status === 200) {
                    setShowPopup(true)
                    setShowEmailStep(false)
                    if (transactionStatus !== 'waiting' && transactionStatus !== 'user confirm' && transactionStatus !== 'success') {
                        enterWithMagic()
                    }
                }
            } else {
                setError("veuillez enter une addresse mail")
            }
        }
    }




    const handleNavigateToProfile = () => {
        navigate('/profile')

    }

    const handleEntrerInLottery = async () => {
        if (!playerAlreadyEnter && user && user.hasOwnProperty('email')) {
            if (user.email === "" || user.email === null) {

                setShowEmailStep(true)
                setShowPopup(false)
            }
            else {
                setShowPopup(true)
                enterWithMagic()
            }


        }
    }

    const login = async () => {


        const accounts = await magic.wallet.connectWithUI();
        if (accounts[0]) {
            const walletType = await magic.wallet.getInfo();
            const userExist = await userExistInDb(accounts[0])
            if (userExist) {
                setUser(userExist);
            } else {
                const payload = { wallet: accounts[0], walletType: walletType.walletType }
                const newUser = await createUserForDB(payload)
                if (newUser.data.length !== 0) {
                    setUser(newUser.data[0])
                } else {
                    setUser({ user: null })
                }
            }
        }


    };

    useEffect(() => {
        getCollection()
        if (props.styleName) {
            setCurrentStyle(props.styleName)
        }
    }, [getCollection, props.styleName])


    useEffect(() => {
        getMetadata()

    }, [getMetadata])


    useEffect(() => {
        getExtras()
        if (user && user.hasOwnProperty('wallet')) {
            getPlayers()
            checkPlayerStatus()
        }

    }, [getExtras, getPlayers, checkPlayerStatus, user])


    let priceFormattedEUR = ''
    let priceFormattedETH = ''
    if (eurPrice && user.hasOwnProperty('chainId')) {
        const mainnet = 1 //11155111
        if (user.chainId === mainnet && user.wallet) {
            const calculatedPrice = eurPrice.eur * (priceSale)
            priceFormattedEUR = calculatedPrice.toFixed(2);
            priceFormattedETH = priceSale
        } else {
            const calculatedPrice = eurPrice.eur * (priceSale)
            priceFormattedEUR = calculatedPrice.toFixed(2);
            priceFormattedETH = priceSale

        }
    }


    return (
        <>
            {
                showEmailStep ?
                    <div className={stylesPopup.popupDisplayed}>
                        <AiFillCloseCircle className={stylesPopup.closeBtn} onClick={() => setShowEmailStep(false)} />

                        <div className={stylesPopup.emailPopup}>
                            <div className={stylesPopup.emailContent}>
                                <div className={stylesPopup.emailInput}>
                                    <input type={"email"} placeholder={"Entrer votre email"}></input><BsArrowRightCircleFill onClick={handleUpdateEmail} />
                                </div>

                                <p>({t('Transaction.EmailTextInfo')})</p>
                            </div>

                        </div>
                    </div>
                    : ''
            }
            {showPopup ?
                <div className={stylesPopup.popupDisplayed}>
                    <AiFillCloseCircle className={stylesPopup.closeBtn} onClick={() => setShowPopup(false)} />

                    <div className={stylesPopup.popupContent}>

                        <div className={transactionStatus === 'waiting' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>

                            <CirclesWithBar
                                height="50"
                                width="50"
                                color="#962ce5"
                                wrapperStyle={{}}
                                wrapperClass={stylesPopup.loaderSpinner}
                                outerCircleColor="#962ce5"
                                innerCircleColor="#5f93f1"
                                barColor="#30d0e9"
                                ariaLabel='circles-with-bar-loading'
                                visible={transactionStatus === 'waiting' ? true : false}

                            />

                            {transactionStatus === 'waiting' ? <p className={stylesPopup.PopupInfos}>{t('Transaction.Waiting')}</p> : <p className={stylesPopup.PopupInfos}><FiCheckCircle className={stylesPopup.successIcon} color='white' size="25px" /> {t('Transaction.Confirm')}</p>}
                        </div>
                        {transactionStatus === 'user confirm' || transactionStatus === 'success' ?
                            <>
                                <span className={transactionStatus === 'user confirm' || transactionStatus === 'success' ? stylesPopup.popupLineActive : stylesPopup.popupLine}></span>
                                <div className={transactionStatus === 'user confirm' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>

                                    <CirclesWithBar
                                        height="50"
                                        width="50"
                                        color="#962ce5"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        outerCircleColor="#962ce5"
                                        innerCircleColor="#5f93f1"
                                        barColor="#30d0e9"
                                        ariaLabel='circles-with-bar-loading'
                                        visible={transactionStatus === 'user confirm' ? true : false}

                                    />

                                    {transactionStatus === 'user confirm' ? <p className={stylesPopup.PopupInfos}>{t("Transaction.During")}</p> : <p className={stylesPopup.PopupInfos}><FiCheckCircle className={stylesPopup.successIcon} color='white' size="25px" /> {t("Transaction.Success")}</p>}
                                </div>
                            </>

                            : ''}



                        {transactionStatus === 'success' ?
                            <>
                                <span className={transactionStatus === 'success' ? stylesPopup.popupLineActive : stylesPopup.popupLine}></span>
                                <div className={transactionStatus === 'success' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>
                                    <div className={stylesPopup.popupSuccessMessage}>
                                        <p className={stylesPopup.PopupInfos}><FiCheckCircle className={stylesPopup.successIcon} color='white' size="25px" /> {t("Transaction.Congrats")}</p>
                                        <p className={stylesPopup.PopupInfos}>{t("Transaction.Congrats2", { collectionName: collection ? collection.collection_name : '' })}</p>
                                        <p className={`${stylesPopup.PopupInfos} ${stylesPopup.PopupInfosLite}`}> <span style={{ textDecoration: 'double' }}>{t('Transaction.Conditions')}</span> </p>
                                        {transactionReceipt ? <a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${transactionReceipt.hash}`}>{t('Transaction.ShowTransacOnEther')}</a> : ''}
                                        <button className={stylesPopup.ProfButton} onClick={handleNavigateToProfile}><BiUserCircle className={stylesPopup.successIcon} color='black' size="20px" /> {t('Transaction.Profile')}</button>
                                    </div>

                                </div>
                            </>
                            : ''}

                        {transactionStatus === 'failed' ?
                            <>
                                <span className={transactionStatus === 'success' ? stylesPopup.popupLineActive : stylesPopup.popupLine}></span>
                                <div className={transactionStatus === 'success' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>
                                    <IoMdCloseCircle color='red' className={stylesPopup.successIcon} />
                                    <p>{t('Transaction.Error')}</p>
                                </div>
                            </>
                            : ''}

                    </div>
                </div>
                : ''}


            <div className={currentStyle && currentStyle === 'basic' ? stylesLottery.container : stylesPerso.container}>
                {window.innerWidth >= 768 && currentStyle && currentStyle === 'basic'?
                    <p style={{

                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Exo 2, sans-serif",
                        textDecoration: "none !important",
                        fontWeight: "900",
                        textTransform: "uppercase",
                        letterSpacing: "3px",
                        fontSize: "35px",
                        textShadow: " #fff 0px 0px 60px",
                        textAlign: "center"
                    }}>Série limitée : jusqu'à épuisement</p>
                    :
                    <p style={{
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Exo 2, sans-serif",
                        textDecoration: "none !important",
                        fontWeight: "900",
                        textTransform: "uppercase",
                        letterSpacing: "3px",
                        fontSize: "23px",
                        textShadow: " #fff 0px 0px 60px",
                        textAlign: "center"
                    }}>Série limitée : jusqu'à épuisement</p>}
                <div className={currentStyle && currentStyle === 'basic' ? stylesLottery.price : stylesPerso.price}>
                    <PriceConverter _styleName={window.innerWidth >= 768 ? currentStyle :'perso'} _collectionId={extras.collectionId} />


                </div>
                {error ? <p style={{ textAlign: 'center', marginBottom: "2em" }}>{error}</p> : ''}
                {
                    user && user.hasOwnProperty('wallet') ?
                        <>
                            {playerAlreadyEnter === true ? <p>Votre participation a bien été enregistrée. La loterie promotionnelle se clôturera le 31 Mars 2023 à 00h. Le tirage au sort aura lieu dans un délai de 72h, nous vous tiendrons informé par mail. </p> : ''}

                            {playerAlreadyEnter === false ? <button className={currentStyle && currentStyle === 'basic' ? stylesLottery.button : stylesPerso.button} disabled >{t("Gallery.Lottery.ButtonText")} </button> : ''}
                        </>
                        : ''
                }
                {user && user.hasOwnProperty("user") && user.user === null ? <button  className={currentStyle && currentStyle === 'basic' ? stylesLottery.button : stylesPerso.button} disabled>{t("Gallery.Lottery.ButtonText")}</button> : ''}

                {typeof collection !== 'undefined' && collection.hasOwnProperty('idcollection') ?
                    <Link className={currentStyle && currentStyle === 'basic' ? stylesLottery.linkRules : stylesPerso.linkRules} to='/lottery-rules' state={{ collection: `${collection.collection_name}` }}><p>Réglement de la loterie promotionnelle {collection.collection_name}</p></Link>

                    :
                    ''
                }
            </div>
        </>
    )
}

//onClick={handleEntrerInLottery} ligne 574
//onClick={login} ligne 578

//                {user && user.hasOwnProperty("user") ? <button onClick={handleNavigateToTutorial} className={currentStyle && currentStyle === 'basic' ? stylesLottery.button : stylesPerso.button} >Participer à loterie promotionnelle</button> : ''}
//                         <CustomCountdown styleName={currentStyle} timestamp='1680285600000' dateString='31 Mars 2023 - 20H ' />
/* {priceFormattedEUR !== '' ? <p className={currentStyle && currentStyle === 'basic' ? stylesLottery.price : stylesPerso.price}>{priceFormattedETH}<span> <FaEthereum /></span> </p> : ''}
             {priceFormattedEUR !== '' ? <p className={currentStyle && currentStyle === 'basic' ? stylesLottery.price : stylesPerso.price}><span><TbWaveSine /></span> {priceFormattedEUR}€</p> : ''}*/


export default EnterLottery