// @ts-nocheck
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react'
import { FaBars, FaIgloo, FaTimes, FaUserCircle } from 'react-icons/fa'
import { Link, redirect, useLoaderData } from 'react-router-dom';
import Header from '../../UI/Header';
import CollectionAPI from '../../../APIs/CollectionAPI';
import ArtistAPI from '../../../APIs/ArtistAPI';



function CreateCollection() {
    const currentUser = useLoaderData();


    const [collection, setCollection] = useState({
        collectionName: '',
        artist: '',
        artistName: '',
        preview: ''

    })
    const [artists, setArtists] = useState({
        id: '',
        pseudo: '',
        name: ''
    });

    const loadArtists = useCallback(async () => {
        const response = await ArtistAPI.get('/')
        if (response.data.length !== 0) {
            const artistsArr = []
            response.data.forEach(artist => {
                artistsArr.push({ id: artist.id, pseudo: artist.pseudo, name:`${artist.firstname} ${artist.lastname}` })
            })

            setArtists(artistsArr)
        } else {
            setArtists('Aucun Artiste disponible')
        }
    }, [artists.id, artists.pseudo,artists.name])


    const handleChange = event => {
        const { name, value } = event.target

        if (name === 'artist') {
            console.log()
            const artistName = event.target.selectedOptions[0].innerText.toLowerCase();
            console.log(artistName)
            setCollection({ ...collection, [name]: value, artistName: artistName })
        } else {
            setCollection({ ...collection, [name]: value })

        }
        console.log(name + ' ' + value)
        console.log(collection)
    }

    useEffect(() => {
        loadArtists();
    }, [loadArtists])

    let artistsDisplay;
    if (artists[0] !== undefined) {
        artistsDisplay = artists.map((artist, key) => {
            return (
                <option key={key} value={artist.id}>
                    {artist.pseudo !== '' ? artist.pseudo : artist.name}
                </option>
            )
        })
    }

    const createCollection = async () => {
        let nameFormatted;
        if (collection.collectionName.includes('#')) {
            const nameReplaced = collection.collectionName.replaceAll('#', '')
            nameFormatted = nameReplaced.replaceAll(' ', '-')
        } else {

            nameFormatted = collection.collectionName.replaceAll(' ', '-')
        }
        const payload = {
            collectionName: collection.collectionName,
            artist: collection.artist,
            preview: `/images/previews/${collection.artistName.replace(' ', '-')}/${nameFormatted.toLowerCase()}/${collection.preview}`
        }

        await CollectionAPI.post('/create', payload)
        window.location.replace(`${window.location.origin}/admin/collections`)
    }

    if (typeof currentUser !== 'undefined') {


        if (currentUser.role === 'Administrator') {
            return (
                <React.Fragment>
                    <Header />
                    <h1>Créer une nouvelle collection</h1>
                    <div >
                        <p>Nom de la collection :</p>
                        <input onChange={handleChange} name='collectionName' type="text" placeholder='Nom de la collection'></input>
                    </div>
                    <div >
                        <label htmlFor="artist">Choisissez un artiste:</label>
                        <select onChange={handleChange} name="artist" id="artist">
                            <option>Artists</option>
                            {artistsDisplay ? artistsDisplay : 'Aucun Artist'}
                        </select>
                    </div>
                    <div >
                        <p>Nom du fichier preview :</p>
                        <input onChange={handleChange} name='preview' type="text" placeholder='Nom du fichier preview'></input>
                    </div>

                    <button onClick={createCollection}>Créer la collection</button>

                </React.Fragment>
            )
        }
    } else {
        return (<></>)
    }
}

export default CreateCollection;