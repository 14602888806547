// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import ProgramsAPI from '../../../APIs/ProgramsAPI'
import Footer from '../../UI/Footer'
import Header from '../../UI/Header'
import {Link} from 'react-router-dom'
import ContractAPI from '../../../APIs/ContractAPI'
import {useLoaderData} from 'react-router-dom'
function ProgramsList() {
  const currentUser = useLoaderData();

  const [programs, setPrograms] = useState()
  let programsDisplay;

  const [contractList, setContractList] = useState();

    const getContractList = useCallback(
        async () => {
            const contractsArr = [];
            const response = await ContractAPI.get('/')
            response.data.forEach(contract => {
                contractsArr.push(contract)
            })
            setContractList(contractsArr)

        },
        [setContractList],
    )

  const getPrograms = useCallback(async () => {
      const response = await ProgramsAPI.get('/')
      
      if(response.data.length !== 0){
          const programsArr = []
          response.data.forEach(async program => {
              console.log(program)
              programsArr.push(program)
          })
          setPrograms(programsArr)
      }else{
        setPrograms('Aucune program disponible')
      }

  },[setPrograms])

  useEffect(()=>{
    getPrograms();
  },[getPrograms])


  useEffect(() => {
    getContractList();
}, [getContractList])
if(typeof currentUser !== 'undefined')
if (currentUser.role === 'Administrator') {
  if(typeof programs !== 'string' && contractList){
    programsDisplay = programs.map((program, key) => {
      const programContract = contractList.find(contract => contract.id_contracts === program.contract)
      console.log(programContract)
      return (
          <div key={key}>
            <p>{program.content}</p>
            <p>{program.conditions}</p>
            <p>{programContract.name}</p>
          </div>
      )
  })

  }
  return (
    <React.Fragment>
      <Header/>
      <Link to='/admin/programs/create'>
                <button>Créer un programme</button>
            </Link>
      {programsDisplay ? programsDisplay : 'Aucun programmes crée'}
      <Footer/>
    </React.Fragment>
  )
}else{
  return <></>
}
}

export default ProgramsList