import React from 'react'
import style from '../../styles/LoaderSpinner/LoaderSpinner.module.scss'
function LoaderSpinner({width, height}) {
   
    return (
        <div className={style.divLoader}>
            <svg className={style.svgLoader} viewBox="0 0 100 100" width={width ? width : '5em'} height={height ? height : '5em'}>
                <path fill="white" color="white" stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
                    transform="rotate(179.719 50 51)" />
            </svg>
        </div>
    )
}

export default LoaderSpinner