import { ethers } from 'ethers';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import Image from '../Image/Image';
import Tilt from 'react-vanilla-tilt'
import * as ContractFunctions from '../../Functions/ContractFunctions'
import ContractAPI from '../../APIs/ContractAPI';
import stylecard from '../../styles/NFTS/NFTArtistPreview.module.scss';
import { useNavigate } from 'react-router-dom';
import { ProviderContext, UserContext } from '../Context/Context';
import { ThreeDots } from 'react-loader-spinner';

function UserNFTs() {

  const navigate = useNavigate();
  const [tokensOwned, setTokensOwned] = useState([]);
  const [metadatas, setMetadatas] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [loadingStatus, setLoadingStatus] = useState('')
  const currentImageRef = useRef();

  const [provider, setProvider] = useContext(ProviderContext)
  const [user, setUser] = useContext(UserContext)


  const checkUserBalance = async (_contract) => {
    setIsLoading(true)

    let hasToken = { status: false };
    if (_contract && provider.hasOwnProperty('_isProvider')) {
      try {
        if (user && user.hasOwnProperty('wallet')) {
          const batchAccounts = [user.wallet, user.wallet, user.wallet]
          console.log(batchAccounts)
          const ABI = await ContractFunctions.loadAbiContract(_contract.contractName, _contract.collectionId)
          const contract = new ethers.Contract(_contract.contractAddress, ABI.abi, provider);
          let interfaceType;
          try {

            const cint = await contract.supportsInterface(0x80ac58cd);
            console.log(cint)
            if (cint) {
              interfaceType = 'ERC721'
            } else {
              interfaceType = 'ERC1155'
            }
          } catch (e) {
            console.log(e)
            interfaceType = 'ERC1155'
          }
          let balanceInBigNumber

          try {
            console.log(interfaceType)
            console.log(_contract.contractName)
            if (interfaceType === 'ERC1155') {

              balanceInBigNumber = await contract.balanceOfBatch(batchAccounts, [0, 1, 2])
              console.log(balanceInBigNumber)
            } else {
              balanceInBigNumber = [await contract.balanceOf(user.wallet)]
              console.log(balanceInBigNumber)
            }

          } catch (err) {
            if (err) {
              balanceInBigNumber = []
            }
          }
          const balance = [];
          if (balanceInBigNumber.length !== 0) {
            for (const token of balanceInBigNumber) {
              balance.push(Number(token))
            }

            const tokenId = balance.indexOf(1)
            console.log(tokenId)
            if (tokenId !== -1) {
              return hasToken = { status: true, tokenId: tokenId };
            }
          }
        } else {
          console.log('no user')
          setIsLoading(false)

        }
      } catch (err) {
        console.error(err)
        setIsLoading(false)

      }
    }
    return hasToken
  }
  const getTokenOfUser = useCallback(async () => {
    setIsLoading(true)

    const response = await ContractAPI.get('/')
    if (response.data.length !== 0) {
      for (const contract of response.data) {
        const args = {
          contractName: contract.name,
          contractAddress: contract.address,
          collectionId: contract.collection_id
        }

        const hasToken = await checkUserBalance(args)
        if (hasToken.status === true) {
          contract.tokenId = hasToken.tokenId
          if (tokensOwned.length === 0) {
            setTokensOwned([contract])
          } else {
            setTokensOwned(current => [...current, contract])
          }
        }
      }
    }
    setIsLoading(false)

  }, [setTokensOwned])

  const displayOwnedNFT = useCallback(async () => {


    if (tokensOwned.length !== 0) {
      console.log(tokensOwned)
      for (const token of tokensOwned) {
        const ipfsURL = token.ipfs.replace('ipfs://', 'https://nfsquat.mypinata.cloud/ipfs/')
        try {
          const response = await axios.get(`${ipfsURL}${token.tokenId}.json`)
          if (response.data.length !== 0) {
            if (metadatas.length === 0) {
              setMetadatas([response.data])

            } else {
              setMetadatas(current => [...current, response.data])

            }
          }
        } catch (err) {
          setLoadingStatus('error')
        }


      }
    }

  }, [setIsLoading, setLoadingStatus, setMetadatas, tokensOwned.length])


  useEffect(() => {
    getTokenOfUser()

  }, [getTokenOfUser])


  useEffect(() => {

    displayOwnedNFT();

  }, [displayOwnedNFT])

  const handleNavigateToNFT = (_collectionName) => {


    navigate(`/gallery/${_collectionName}`)

  }

  let metadatasDisplay;
  console.log(isLoading)
  if (isLoading) {
    metadatasDisplay = <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      margin: 'auto'}}>
      <p>Nous vérifions si vous possédez des NFTs dans votre wallet, ceci peut prendre quelques minutes</p>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#ffffff"
        ariaLabel="three-dots-loading"
        visible={true}
      /></div>
  } else if (!isLoading && loadingStatus === 'error') {
    metadatasDisplay = <div>Une erreur est survenue veuillez ressayer dans quelques instants </div>
  } else {
    metadatasDisplay = metadatas.map((metadata, index) => {
      /* const showTransaction = () => {
         console.log(currentReceipt.current)
         console.log(currentReceipt.current.dataset.display)
         console.log(currentReceipt)
       }*/
      const collectionName = metadata.authors === 'Club des Annonceurs' ? 'Club des Annonceurs' : metadata.properties.collection
      console.log(metadata)
      return (

        <div key={index}>
          
          <Tilt className={stylecard.TiltCard} style={{ backgroundColor: 'transparent' }}>
            <div className={`${stylecard.card} ${stylecard.shadow}`} >
              <Image innerRef={currentImageRef} classes={stylecard.cardimg} urls={[metadata.image]} alt={metadata.description} />
              <div className={stylecard.imageOverlay}>
                <h2 className={stylecard.h2Inside}>{metadata.name}</h2>
                <button onClick={() => handleNavigateToNFT(collectionName)} className={stylecard.SeeMoreButton}>Afficher dans la galerie</button>
              </div>
            </div>
          </Tilt>

        </div>

      )
    })
  }
  //          <Receipt innerRef={currentReceipt} contract={tokensOwned[index]} />

  return (
    <>
      {metadatasDisplay}
    </>
  )


}

export default UserNFTs