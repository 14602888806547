import React, { useCallback, useContext, useEffect, useState } from 'react'
import CollectionAPI from '../../APIs/CollectionAPI';
import { ProviderContext } from '../Context/Context';
import * as ContractFunctions from '../../Functions/ContractFunctions'
import { ethers } from 'ethers';
import stylesLottery from '../../styles/Lottery/Lottery.module.scss'
import stylesPerso from '../../styles/Lottery/LotteryPerso.module.scss'
import { TbWaveSine } from 'react-icons/tb';
import { FaEthereum } from 'react-icons/fa';
import { ThreeDots } from 'react-loader-spinner';

function PriceConverter({ _styleName, _collectionId }) {

  const [currentEtherPrice, setCurrentEtherPrice] = useState(null);
  const [currentEuroPrice, setCurrentEuroPrice] = useState(null);
  const [contractPrice, setContractPrice] = useState(null);
  const [priceConverted, setPriceConverted] = useState(null)
  const [style, setStyle] = useState(null)
  const [provider, setProvider] = useContext(ProviderContext)

  const fetchWithFallback = async (links, obj) => {
    let response;
    for (let link of links) {
      try {
        response = await fetch(link, obj)
        if (response.ok)
          return response
      } catch (e) { }
    }
    return response
  }


  const getContractPrice = useCallback(async () => {
    const response = await CollectionAPI.get(`/${_collectionId}/contracts`)
    try {
      if (response.data.length !== 0) {
        const contractName = response.data[0].name;
        const collectionId = response.data[0].collection_id;
        const contractAddress = response.data[0].address;
        const json = await ContractFunctions.loadAbiContract(contractName, collectionId)
        const contract = new ethers.Contract(contractAddress, json.abi, provider)
        const priceSale = await contract.priceSale();
        setContractPrice(Number(priceSale) / 1e18)

      }
    } catch (e) {
      if (e)
        console.log(e)
    }
  }, [_collectionId, provider])


  const convertPrice = useCallback(() => {
    if (currentEuroPrice && contractPrice) {
      const calculatedPrice = currentEuroPrice * (contractPrice)
      setPriceConverted(calculatedPrice);

    } else {
      setPriceConverted('Waiting for prices')
    }

  }, [contractPrice, currentEuroPrice])

  const getEuroPrice = useCallback(async () => {
    await fetchWithFallback([
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eth/eur.min.json",
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eth/eur.json",
      "https://raw.githubusercontent.com/fawazahmed0/currency-api/1/latest/currencies/eth/eur.min.json",
      "https://raw.githubusercontent.com/fawazahmed0/currency-api/1/latest/currencies/eth/eur.json"
    ])
      .then(res => res.json())
      .then((res) => setCurrentEuroPrice(res.eur))
  }, [setCurrentEuroPrice])

  useEffect(() => {
    getContractPrice();

  }, [getContractPrice])

  useEffect(() => {
    getEuroPrice();
  }, [])

  useEffect(() => {
    convertPrice()
  }, [convertPrice])

  useEffect(() => {
    if (_styleName) {
      setStyle(_styleName)
    } else {
      setStyle(null)
    }
  }, [_styleName])


  console.log(contractPrice)

  console.log(currentEuroPrice)
  console.log(priceConverted)

  return (
    <>
      {priceConverted && priceConverted !== 'Waiting for prices'
        ?
        <>
          <p data-testid="prices" className={style && style === 'basic' ? stylesLottery.price : stylesPerso.price}>{contractPrice}<span> <FaEthereum /></span> </p>
          <p data-testid="prices" className={style && style === 'basic' ? stylesLottery.price : stylesPerso.price}><span><TbWaveSine /></span> {priceConverted.toFixed(2)}€</p>

        </>

        : <ThreeDots 
        height="80" 
        width="80" 
        radius="9"
        color="#ffffff" 
        ariaLabel="three-dots-loading"
        visible={true}
         />
      }

    </>
  )
}

export default PriceConverter