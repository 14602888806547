import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../../styles/NFTS/NFTCardPreview.module.scss'
import ArtistCard from '../Artists/ArtistCard';
import CollectionAPI from '../../APIs/CollectionAPI';
import LoaderSpinner from '../Loading/LoaderSpinner';
import Tilt from 'react-vanilla-tilt'
import 'swiper/swiper.scss';
import 'swiper/swiper-vars.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import stylesAribon from '../../styles/NFTS/NFTArtistPreview.module.scss';


function NFTCardPreview(props) {

    const { t } = useTranslation()

    const [collection, setCollection] = useState({
        collectionName: '',
        artistId: '',
        preview: ''
    });

    const navigate = useNavigate();




    const getCollectionInfo = useCallback(async () => {

        const response = await CollectionAPI.get(`/name/${props.collection}`)
        if (response.data.length !== 0) {
            setCollection({ collectionName: response.data[0].collection_name, artistId: response.data[0].artist, preview: response.data[0].preview })
        }
    }, [ props.collection])

    const handleNavigateToNFT = (_collectionName)=>{
  

        navigate(`/gallery/${_collectionName}`)
   
    }


    useEffect(() => {
        getCollectionInfo()

    }, [getCollectionInfo, props.collection]);



    if (window.innerWidth <= 425) {
        return (
            <Suspense fallback={<LoaderSpinner  width={'10em'} height={'10em'}/>}>
                <div className={styles.nftCard} >

                        <div className={`${styles.card} ${styles.shadow}`} >
                        {collection.collectionName === 'Brusk For SOS MEDITERRANEE' ?
                                    <div className={`${stylesAribon.arribbon} ${stylesAribon.arribbonleft}`}>
                                        <div className={stylesAribon.arribboninner}>NFT SOLIDAIRE
                                        </div>
                                    </div>


                                    : ''}
                            <img className={styles.cardimg} src={collection.preview} alt={collection.collectionName}/>
                            <Link reloadDocument to={`/gallery/${collection.collectionName}`} state={{ data: collection.collectionName }}>
                                <div className={styles.imageOverlay}>
                                    <h3 className={styles.h3Inside}>{collection.collectionName ? collection.collectionName : ''}</h3>

                                </div>
                            </Link>
                            <ArtistCard artistId={`${collection.artistId}`} />
                        </div>
            
                    </div>
                    <button onClick={()=> handleNavigateToNFT(collection.collectionName)} className={styles.SeeMoreButtonMob}>{t('Common.VoirPlus')}</button>

            </Suspense>
        )
        
    }else {
        return (
            <Suspense fallback={<LoaderSpinner  width={'10em'} height={'10em'}/>}>
                <div className={styles.nftCard} >
                    <Tilt className={styles.TiltCard} options={{ scale: 10, glare: true }} style={{ backgroundColor: 'transparent' }}>

                        <div className={`${styles.card} ${styles.shadow}`} >
                        {collection.collectionName === 'Brusk For SOS MEDITERRANEE' ?
                                    <div className={`${stylesAribon.arribbon} ${stylesAribon.arribbonleft}`}>
                                        <div className={stylesAribon.arribboninner}>NFT SOLIDAIRE
                                        </div>
                                    </div>


                                    : ''}
                            <img className={styles.cardimg} src={collection.preview} />
                            <Link reloadDocument to={`/gallery/${collection.collectionName}`} state={{ data: collection.collectionName }}>
                                <div className={styles.imageOverlay}>
                                    <h3 className={styles.h3Inside}>{collection.collectionName ? collection.collectionName : ''}</h3>
                                    <button className={styles.SeeMoreButton}>{t('Common.VoirPlus')}</button>

                                </div>
                            </Link>
                            <ArtistCard artistId={`${collection.artistId}`} />
                        </div>

                    </Tilt>
                </div>
            </Suspense>
        )
    }
       
    



}


export default NFTCardPreview;