// @ts-nocheck
import React, { useEffect, useState, useCallback,useRef } from 'react'
import axios from 'axios';
import { Link, useLoaderData } from 'react-router-dom';
import { FiEdit, FiTwitter, FiFacebook } from 'react-icons/fi'
import { FaUser } from 'react-icons/fa'
import { BsArrowBarDown } from 'react-icons/bs'
import { AiOutlineInstagram } from 'react-icons/ai'
import NFTArtistPreview from '../NFTs/NFTArtistPreview';
import Header from '../UI/Header'
import Banner from '../UI/Banner';
import Footer from '../UI/Footer';
import styles from '../../styles/Artists/Artist.module.scss'
import ArtistAPI from '../../APIs/ArtistAPI';
import CollectionAPI from '../../APIs/CollectionAPI';
import FormContainer from '../Form/FormContainer';
import Image from '../Image/Image';
import { Helmet } from 'react-helmet-async';

function Artist() {

    const currentImage = useRef()

    const [artist, setArtist] = useState({
        firstname: '',
        lastname: '',
        pseudo: '',
        slug: '',
        image: '',
        instagram: '',
        twitter: '',
        facebook: '',
        bio: '',
        artistId: ''

    });
    const [collectionCount, setCollectionCount] = useState()

    const [showMore, setShowMore] = useState(false)

    const artistParam = window.location.pathname.split('/')[2];
    const currentUser = useLoaderData()

    const getArtist = useCallback(async () => {
        if (artistParam) {


            const response = await ArtistAPI.get(`/${artistParam}`)
            if (response.data.length !== 0) {
                setArtist({
                    firstname: response.data[0].firstname,
                    lastname: response.data[0].lastname,
                    pseudo: response.data[0].pseudo,
                    slug: response.data[0].slug,
                    image: response.data[0].image,
                    instagram: response.data[0].instagram,
                    twitter: response.data[0].twitter,
                    bio: response.data[0].bio,
                    artistId: response.data[0].id,
                    facebook: response.data[0].facebook,
                    website: response.data[0].website

                })
            }
        }
    }, [setArtist, window.location.pathname])

    const collectionCountForArtist = useCallback(async () => {
        if (artist.artistId !== '') {

            const response = await ArtistAPI.get(`/count/collections/${artist.artistId}`)
            console.log(response)
            if (response.data.length !== 0) {
                setCollectionCount(response.data[0].nb_collection)
            } else {
                setCollectionCount('No Collection')
            }
        }
    }, [artist.artistId, setCollectionCount])

    const handleShowMoreEvent = (event) => {
        if (showMore === false) {
            event.target.classList.add('anim')
            setShowMore(true)


        } else {
            event.target.classList.remove('anim')
            setShowMore(false)

        }
        event.preventDefault()
    }

    useEffect(() => {
        getArtist()
        collectionCountForArtist()
    }, [getArtist, collectionCountForArtist, window.location.pathname, showMore])

    console.log(artist)
    let artistName;
    if(artist){
        if(artist.pseudo){
            artistName = artist.pseudo
        }else {
            artistName = `${artist.firstname} ${artist.lastname}`
        }
    }
    console.log(artist)
    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>NFSQUAT - {artist.firstname.toUpperCase()} {artist.lastname.toUpperCase()}</title>
                <meta name='description' content={`The  Collection `}></meta>
                <link rel='canonical' href={`/gallery/`} />
            </Helmet>
            <Banner content={artistName} content2={'NFT GALLERY FOR TALENT'} />
            <div className={styles.container}>
                <div className={styles.artistsCard}>
                    {artist.image !== '' ? <Image innerRef={currentImage} alt={`Photo de ${artistName}`} urls={[process.env.PUBLIC_URL + artist.image]} classes={'undefined'} /> : <FaUser />}
                    </div>
                    <div className={styles.InformationArtist}>
                        <p className={styles.artistname}>{artist.firstname.toUpperCase()} <strong>{artist.lastname.toUpperCase()}</strong></p>
                        {artist.pseudo ?<p className={styles.artistPseudo}>@ {artist.pseudo.toUpperCase()}</p> : ''}
                        <p className={styles.artistDesc}>{artist.bio} </p>
                       
                        
                    </div> 
                    <div className={styles.social}>
                        <h2>Suivre le talent</h2>
                        <div className={styles.socialIcons}>
                            {artist.instagram !== null ? <React.Fragment>
                                <svg width="0" height="0">
                                    <linearGradient id="instagram" x1="100%" y1="100%" x2="0%" y2="0%">
                                        <stop stopColor="#feda75" offset="20%" />
                                        <stop stopColor="#fa7e1e" offset="40%" />
                                        <stop stopColor="#d62976" offset="60%" />
                                        <stop stopColor="#962fbf" offset="80%" />
                                        <stop stopColor="#4f5bd5" offset="100%" />
                                    </linearGradient>
                                </svg>
                                <a  rel="noopener noreferrer" target="_blank" href={`https://instagram.com/${artist.instagram}`}>
                                    <AiOutlineInstagram style={{ fill: "url(#instagram)" }} />
                                </a>
                            </React.Fragment>
                                : ''}

                            {artist.facebook !== null ?
                                <React.Fragment>
                                    <a  rel="noopener noreferrer" target="_blank" href={`https://facebook.com/${artist.facebook}`}>
                                    
                                        <FiFacebook />
                                    </a>
                                </React.Fragment>
                                : ''}
                            {artist.twitter !== null ?
                                <React.Fragment>
                                    <a  rel="noopener noreferrer" target="_blank" href={`https://twitter.com/${artist.twitter}`}>
                                        <FiTwitter />
                                    </a>
                                </React.Fragment>
                                : ''}

                        </div>
                    </div>


                </div>
                <div className={styles.containerCollecTitre}>
                <h2 className={styles.Titrecollection}>Les collections du talent</h2>
                </div>
                <div className={styles.container}>
                    <div className={styles.collection}>
                    
                    <div className={styles.collectioncard}>
                        {artist.artistId !== '' ? <NFTArtistPreview artistId={artist.artistId} page='artist' /> : ''}
                        </div>
                    {collectionCount === 0 || typeof collectionCount === 'undefined' ? "Le talent ne possède pas encore de collection" : ''}
                </div>
            </div>

            {typeof currentUser !== 'undefined' && currentUser.role === 'Administrator' ? <Link to={'/talents/edit/' + artist.slug} ><FiEdit /></Link> : ""}

            <FormContainer />
            <Footer />

        </React.Fragment>
    )

}



export default Artist