// @ts-nocheck
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation,  Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import { ethers } from 'ethers'
import * as ContractFunctions from '../../Functions/ContractFunctions'
import CustomSwiper from '../Swiper/CustomSwiper'
import EnterLottery from '../Lottery/EnterLottery'
import FormContainer from '../Form/FormContainer'
import ProgramsDisplay from '../Programs/ProgramsDisplay'
import Banner from '../UI/Banner'
import Footer from '../UI/Footer'
import Header from '../UI/Header'
import CollectionAPI from '../../APIs/CollectionAPI'
import ArtistAPI from '../../APIs/ArtistAPI'
import { FaEthereum } from 'react-icons/fa'

import stylescard from '../../styles/NFTS/NFTArtistPreviewTalent.module.scss'
import CardStyles from '../../styles/Artists/Artist.module.scss'
import stylesCollection from '../../styles/NFTS/Collection.module.scss'
import "swiper/swiper.scss";
import "swiper/modules/effect-coverflow/effect-coverflow.scss";
import "swiper/modules/pagination/pagination.scss";
import { ProviderContext, UserContext } from '../Context/Context'
import { useMediaQuery } from 'react-responsive'
import MobileDisplay from './MobileDisplay'
import LoaderSpinner from '../Loading/LoaderSpinner'

function Collection() {

  const isMobileScreen = useMediaQuery({ minWidth: 0, maxWidth: 986 });
  const isDesktopScreen = useMediaQuery({ minWidth: 987 });

  const { t, i18n } = useTranslation();
  const [user] = useContext(UserContext);
  const location = useLocation();
  const [userOwnTokens, setUserOwnTokens] = useState([])
  const [showMore, setShowMore] = useState(false)

  const [collection, setCollection] = useState({
    idcollection: '',
    artist: '',
    collection_name: '',
    created_date: '',
    modified_date: '',
    preview: '',
    contractAdress: ''
  })
  const [metadatas, setMetadatas] = useState([])
  const [artist, setArtist] = useState({
    firstname: '',
    lastname: '',
    pseudo: '',
    slug: '',
    image: '',
    instagram: '',
    twitter: '',
    bio: '',
    artistId: '',
  })

  const [provider] = useContext(ProviderContext)

  const checkUserBalance = useCallback(async (_contract) => {

    if (_contract.length !== 0 && user.hasOwnProperty("idusers") && provider.hasOwnProperty("_isProvider")) {
      try {
        if (user.hasOwnProperty("wallet")) {
          const batchAccounts = [user.wallet, user.wallet, user.wallet]

          const ABI = await ContractFunctions.loadAbiContract(_contract[0].contractName, _contract[0].collectionId)
          const signer = provider.getSigner()
          const contract = new ethers.Contract(_contract[0].contractAddress, ABI.abi, signer);

          const balance = await contract.balanceOfBatch(batchAccounts, [0, 1, 2])
          const balanceFormat = [];
          if (balance.length !== 0) {
            for (const token of balance) {
              balanceFormat.push(Number(token))
            }
            const areEquals = balanceFormat.every(element => element === 0)
            if (areEquals === true) {
              setUserOwnTokens([])
            } else {
              setUserOwnTokens(balanceFormat)
            }
          }

        }
      } catch (err) {

        console.error(err.code)

      }
    } else {
      console.log('no contract')

    }


  }, [userOwnTokens, user])



  const getCollection = useCallback(async () => {
    const url = window.location.pathname.split('/')[2]
    const collectionName = decodeURI(url)

    try {
      const response = await CollectionAPI.get(`/name/${collectionName}`)
      if (response.data.length !== 0) {
        setCollection(response.data[0])
      }
    } catch (e) {
      if (e) throw Error(e.message)
    }
  }, [
    collection.idcollection,
    collection.artist,
    collection.collection_name,
    collection.created_date,
    collection.modified_date,
    collection.preview,
  ])



  const getMetadatas = useCallback(async () => {
    if (collection.idcollection !== '') {
      const response = await CollectionAPI.get(`/${collection.idcollection}/contracts/`)
      if (response.data.length !== 0) {
        const contract = response.data[0]
        let tokenIds = [0, 1, 2];
        let isSDB = false;
        if (contract.name.includes("SDB")) {
          isSDB = true
        }
        if (isSDB) {
          tokenIds.push(3)
        }
        let metadatasArr = []
        for (let i = 0; i < tokenIds.length; i++) {
          const datas = await axios.get(`https://nfsquat.mypinata.cloud/ipfs/${contract.ipfs.replace('ipfs://', '')}${tokenIds[i]}.json`, {
            headers: {
              'Accept': 'text/plain'
            }
          })
          if (datas.data.length !== 0) {
            datas.data.contractAddress = contract.address
            datas.data.contractId = contract.id_contracts
            datas.data.lottery = contract.lottery
            datas.data.lotteryPrice = contract.lottery_price
            datas.data.contractName = contract.name
            datas.data.tokenId = tokenIds[i]
            datas.data.collectionId = collection.idcollection
            metadatasArr.push(datas.data)
          }


        }
        setMetadatas(metadatasArr)
      } else {
        setMetadatas('Aucun Contrat')
      }

    }
  }, [collection, setMetadatas])



  const getArtist = useCallback(async () => {
    if (collection.artist) {
      const response = await ArtistAPI.get(`/${collection.artist}`)
      if (response.data.length !== 0) {
        setArtist({
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          pseudo: response.data[0].pseudo,
          slug: response.data[0].slug,
          image: response.data[0].image,
          instagram: response.data[0].instagram,
          twitter: response.data[0].twitter,
          bio: response.data[0].bio,
          artistId: response.data[0].id,
        })
      }
    }
  }, [getCollection, artist.firstname, artist.lastname, artist.pseudo, artist.slug, artist.image, artist.instagram, artist.twitter, artist.bio, artist.artistId])

  const handleShowMoreEvent = (event) => {
    if (showMore === false) {
      event.target.classList.add('anim')
      setShowMore(true)
    } else {
      event.target.classList.remove('anim')
      setShowMore(false)
    }
    event.preventDefault()
  }

  function formatImage(image) {
    let ipfsURL;

    if (image) {

      if (image.includes('gateway.pinata.cloud')) {
        ipfsURL = image.replace('gateway.pinata.cloud', 'nfsquat.mypinata.cloud')
      } else if (image.includes('gateway.ipfs.io')) {
        ipfsURL = image.replace('gateway.pinata.cloud', 'nfsquat.mypinata.cloud')
      } else {
        ipfsURL = image
      }
    }
    return ipfsURL

  }


  useEffect(() => {
    getCollection()
    getArtist()
  }, [getCollection, getArtist])

  useEffect(() => {
    getMetadatas()

  }, [getMetadatas, user])

  useEffect(() => {
    if (user) {
      if (metadatas.length !== 0 && metadatas[0].lottery === 0) {
        if (user.hasOwnProperty("idusers")) {
          checkUserBalance(metadatas)
        }
      }
    }
  }, [metadatas, user])

  const handleUserToken = (value) => {
    setUserOwnTokens(value)
  }




  let programsDisplay

  if (typeof metadatas !== 'string' && metadatas.length !== 0) {

    programsDisplay = metadatas.map((metadata, index) => {
      let name;
      if (metadata.contractName === 'SDB') {
        if (index === 0) {
          name = 'The Unique';
        } else if (index === 1) {
          name = 'The Super Rare';
        } else if (index === 2) {

          name = 'The Rare'
        } else {
          name = 'The Limited'
        }
      } else {
        if (index === 0) {
          name = 'The Unique';
        } else if (index === 1) {
          name = 'The Rare'
        } else {

          name = 'The Limited'
        }
      }

      return (<ProgramsDisplay userOwnTokens={userOwnTokens} handleUserToken={handleUserToken} key={index} lottery={metadata.lottery} name={name} contract={metadata} token={index} />)
    })
  } else {
    programsDisplay = ''
  }
  
  let categories;
  if (i18n.language !== 'en-EN') {
    categories = 'le Limited, le Rare, le Super-rare ou le Unique'
    if (collection.collection_name !== 'Studio Stories') {
      categories = 'le Limited, le Rare, ou le Unique';
    }
  } else {
    categories = 'The Limited, the Rare, the Super-rare or the Unique'
    if (collection.collection_name !== 'Studio Stories') {
      categories = 'the Limited, the Rare or the Unique';
    }
  }



if(metadatas.length !== 0){
  const mobileDisplay = <>
    <div className={stylesCollection.me}>
      <span className={stylesCollection.mecontent}>{t('Gallery.Lottery.HowToStep1')}</span>
      <div className={stylesCollection.Notme}>
        <span>01</span>
        <span>
        </span>
      </div>
      <span className={stylesCollection.mecontent}>{
        metadatas[0].lottery === 1
          ? t('Gallery.Lottery.HowToStep2')
          : t('Gallery.NormalBuy.HowToStep2')
      }</span>
      <div className={stylesCollection.Notme}>
        <span>02</span>
        <span>
        </span>
      </div>
      <span className={stylesCollection.mecontent}>{
        metadatas[0].lottery === 1
          ? t('Gallery.Lottery.HowToStep3')
          : t('Gallery.NormalBuy.HowToStep3')
      }</span>
      <div className={stylesCollection.Notme}>
        <span>03</span>
        <span>
        </span>
      </div>
    </div>
    <p style={{ marginTop: '1em', textAlign: 'justify' }}>
      {
        metadatas[0].lottery === 1
          ? t('Gallery.Lottery.HowToStep4')

          : ''
      }
    </p>
  </>


  const desktopDisplay = <div className={stylesCollection.insideme}>
    <div className={stylesCollection.me}>
      <span className={stylesCollection.mecontent}>{t('Gallery.Lottery.HowToStep1')}</span>
      <div className={stylesCollection.Notme}>
        <span>01</span>
        <span>
        </span>
      </div>
      <span className={stylesCollection.mecontent}>{
        metadatas[0].lottery === 1
          ? t('Gallery.Lottery.HowToStep2')

          : t('Gallery.NormalBuy.HowToStep2')
      }</span>
      <div className={stylesCollection.Notme}>
        <span>02</span>
        <span>
        </span>
      </div>
      <span className={stylesCollection.mecontent}>{
        metadatas[0].lottery === 1
          ? t('Gallery.Lottery.HowToStep3') : t('Gallery.Lottery.HowToStep3')

      }</span>
      <div className={stylesCollection.Notme}>
        <span>03</span>
        <span>
        </span>
      </div>
    </div>
  </div>
  return (
    <React.Fragment>
      <Helmet>
        <title>NFSQUAT - {collection ? collection.collection_name.toUpperCase() : ''}</title>
        <meta name='description' content="The "></meta>
        <link rel='canonical' href={`/gallery/${collection ? collection.collection_name.toUpperCase() : ''}`} />
      </Helmet>
      <Header />
      {collection.collection_name === "Homard Façon Table" ?
        <Banner content2={collection.collection_name.toUpperCase()} content={'BRUNO VERJUS'} />
        :
        <Banner content={collection.collection_name.toUpperCase()} content2={'NFT GALLERY FOR TALENT'} />
      }
      <div className={stylesCollection.container}>
        <div className={stylesCollection.collectionContainer}>
          <div>
            <div className={stylesCollection.starsec}></div>
            <div className={stylesCollection.starthird}></div>
            <div className={stylesCollection.starfourth}></div>
            <div className={stylesCollection.starfifth}></div>
          </div>
          <div className={stylesCollection.content}>
            {metadatas.length !== 0 ?
              <div className={stylesCollection.SectionLeft}><span className={stylesCollection.Loterystep}>
                {
                  metadatas[0].lottery === 1
                    ? t('Gallery.Lottery.HowToTitle')

                    : t('Gallery.NormalBuy.HowToTitle')
                }</span>

                <p style={{ marginTop: '1em', textAlign: 'justify' }}>
                  {
                    metadatas[0].lottery === 1
                      ? t('Gallery.Lottery.HowToText', { categories: categories })

                      : t('Gallery.NormalBuy.HowToText', { categories: categories })
                  }
                </p>

                {isDesktopScreen ? desktopDisplay : mobileDisplay}


                <span style={{ marginTop: '3em' }} className={stylesCollection.Loterystep}>{t('Gallery.Details')}</span>
                {metadatas.length !== 0 ?
                  <div className={stylesCollection.LoterydetailSection}>
                    <div className={stylesCollection.LoterydetailColumn}>
                      <span className={stylesCollection.Loterydetail}>{t('Gallery.Talent')}</span>
                      <Link reloadDocument to={'/talents/' + artist.artistId}><span className={stylesCollection.Loterydetailcontent}>{artist.pseudo ? artist.pseudo : `${artist.firstname} ${artist.lastname}`}</span></Link>
                    </div>
                    <div className={stylesCollection.LoterydetailColumn}>
                      <span className={stylesCollection.Loterydetail}>{t('Gallery.Quantity')}</span>
                      <span className={stylesCollection.Loterydetailcontent}>{metadatas[0].contractName === "SDB" ? metadatas[0].properties.supply + metadatas[1].properties.supply + metadatas[2].properties.supply + metadatas[3].properties.supply : metadatas[0].properties.supply + metadatas[1].properties.supply + metadatas[2].properties.supply}</span>

                    </div>
                    {metadatas[0].lottery === 1 ?
                      <div className={stylesCollection.LoterydetailColumn}>
                        <span className={stylesCollection.Loterydetail}>{t('Gallery.Price')}</span>
                        <span className={stylesCollection.Loterydetailcontent}>{metadatas[0].lotteryPrice} <FaEthereum /></span>
                      </div>

                      : ''}

                  </div>

                  : ''}
              </div>
              : ''}
            <div className={stylesCollection.SectionRight}>


              {isDesktopScreen && metadatas.length !== 0 ? <CustomSwiper /> : <MobileDisplay metadatas={metadatas} handleUserToken={handleUserToken} userOwnTokens={userOwnTokens}/>}

            </div>
          </div>
        </div>

        <div className={stylesCollection.mainContainer}>

          <div>
            <div className={stylesCollection.starsec}></div>
            <div className={stylesCollection.starthird}></div>
            <div className={stylesCollection.starfourth}></div>
            <div className={stylesCollection.starfifth}></div>
          </div>
          {isDesktopScreen ?
            <>
              <div className={stylesCollection.bannerProgram}>
                <h3 className={stylesCollection.bannerProgramTitle}>CONTENU DE LA COLLECTION</h3>
              </div>
              <div className={stylesCollection.Cardcontainer}>
                {programsDisplay ? programsDisplay : ''}

              </div>
            </> : ''}
          {metadatas[0] && metadatas[0].lottery === 1 ? <div className={stylesCollection.lotterycollec}><EnterLottery styleName={"basic"} /></div> : ""}
        </div>


      </div>
      {metadatas[0] && metadatas[0].lottery === 1 && isDesktopScreen? <div className={stylesCollection.Stickybottom}><EnterLottery styleName={"perso"} /></div> : ''}

      <FormContainer />
      <Footer />
    </React.Fragment>
  )
}else {
  return(<>
  <Header/>
  <LoaderSpinner  width={'10em'} height={'10em'}/>
  </>
  )
}
   
 
}

export default Collection


