import React, { Component } from 'react';
import PinkFlamingoRules from './Rules/PinkFlamingoRules';
import PierreTaisneSeries1Rules from './Rules/PierreTaisneSeries1Rules';
import StudioStoriesRules from './Rules/StudioStoriesRules'
import BrunoVerjusRules from './Rules/BrunoVerjusRules';



function RulesMap(props) {

    const components = {
        PinkFlamingo: PinkFlamingoRules,
        PierreTaisneSeries1: PierreTaisneSeries1Rules,
        StudioStories: StudioStoriesRules,
        HomardFaçonTable: BrunoVerjusRules
    };

    const TagName = components[props.tag || 'PinkFlamingo'];

  return (
    <TagName />
  )
}

export default RulesMap