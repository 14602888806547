import React from 'react';

import styles from '../../styles/UI/Footer.module.scss'
import { FaInstagram, FaDiscord, FaLinkedinIn } from 'react-icons/fa'
import { useTranslation } from 'react-i18next';


function Footer() {

    const {t} = useTranslation()


    return (
        <React.Fragment>
            <footer className={styles.footer}>
                <div className={styles.social}>
                    <p>{t('Footer.SuivezNous')}</p>
                    <a rel="noopener noreferrer" target="_blank" href='https://www.instagram.com/nfsquat'  > <FaInstagram /> </a>
                    <a rel="noopener noreferrer" target="_blank" href='https://discord.gg/KG3ZtaSeVa'  > <FaDiscord /> </a>
                    <a rel="noopener noreferrer" target="_blank" href='https://www.linkedin.com/showcase/nfsquat/'  > <FaLinkedinIn /> </a>
                </div>

                <ul className={styles.footerNav}>
                    <li><a href='/cgu#mentions-legales'>{t('Footer.ML')}</a></li>
                    <li><a href='/cgu#politique-de-confidentialites'>{t('Footer.PDC')}</a></li>
                    <li><a href='/cgu'>{t('Footer.CGV')}</a></li>
                    <li><a href='/cgu'>{t('Footer.CGU')}</a></li>
                </ul>
            </footer>

        </React.Fragment>
    )
}

export default Footer;