import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import Header from '../UI/Header'
import Banner from '../UI/Banner'
import Footer from '../UI/Footer'
import styles from '../../styles/Artists/ArtistCollection.module.scss'
import { Link } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'
import ArtistAPI from '../../APIs/ArtistAPI';
import Image from '../Image/Image';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

function ArtistCollection() {
    const { t } = useTranslation();

    const imageRef = useRef();
    const [data, setData] = useState([]);

    const loadData = async () => {
        const response = await ArtistAPI.get("/");
        setData(response.data)
    }



    useEffect(() => {
        loadData()
    }, [])



    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>NFSQUAT - LES TALENTS</title>
                <meta name='description' content={`Here you can find every talents on NFSQUAT `}></meta>
                <link rel='canonical' href={`/gallery/talents`} />
            </Helmet>
            <Banner content={t('Talents.Banner')} content2={'NFT GALLERY FOR TALENT'}/>
            <div className={styles.artistsCollection}>
                {data.map((artist, index) => {
                    if (artist.private === 0) {
                        return (
                            <div key={index} className={styles.artistsCard}>
                                <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>

                                    <div className={styles.artistImageLink}>
                                        <Link reloadDocument to={'/talents/' + artist.slug}>
                                            {artist.image !== '' ? <Image innerRef={imageRef} alt={`Photo de ${artist.firstname} ${artist.lastname}`} urls={[process.env.PUBLIC_URL + artist.image]} classes={undefined} /> : <FaUser />}
                                        </Link>
                                    </div>
                                    <div className={styles.artistInformations}>
                                        <p className={styles.artistdetails}><span className={styles.Firstname}>{artist.firstname !== '' ? artist.firstname : ''} </span> <span className={styles.Pseudoname}>{artist.lastname !== '' ? artist.lastname : ''}</span> {artist.pseudo !== '' ? <span className={styles.PseudoArtist}>"{artist.pseudo}"</span> : ''} </p>


                                    </div>
                                    <p className={styles.artistDescription}>{artist.bio.substring(0, 255)} ...</p>
                                    <Link reloadDocument className={styles.SeeMoreButton} to={'/talents/' + artist.slug}>
                                        Plus d'infos                            </Link>
                                </div>
                            </div>
                        )
                    }

                })}
            </div>

            <Footer />
        </React.Fragment>
    )
}



export default ArtistCollection