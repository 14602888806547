// @ts-nocheck
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react'
import Header from '../../UI/Header'
import styles from '../../../styles/Artists/Artist.module.scss'
import {useLoaderData } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';
import ArtistAPI from '../../../APIs/ArtistAPI';

function EditArtist() {

    const [artist, setArtist] = useState({
        firstname: '',
        lastname: '',
        pseudo: '',
        slug: '',
        image: '',
        instagram: '',
        twitter: '',
        bio:'',
        artistId: ''

    });

    const  currentUser = useLoaderData()


    const fetchArtist = useCallback(async () => {

        const artistId = window.location.pathname.split('/')[3]


        const response = await ArtistAPI.get(`/${artistId}`);
        setArtist({
            firstname: response.data[0].firstname,
            lastname: response.data[0].lastname,
            pseudo: response.data[0].pseudo,
            slug: response.data[0].slug,
            image: response.data[0].image,
            instagram: response.data[0].instagram,
            twitter: response.data[0].twitter,
            bio:response.data[0].bio,
            artistId: response.data[0].id

        })
    }, [])

    async function updateArtist() {
        if (currentUser.role === 'Administrator') {
            const payload = {
                pseudo: artist.pseudo,
                firstname: artist.firstname,
                lastname: artist.lastname,
                slug: artist.slug,
                instagram: artist.instagram,
                twitter: artist.twitter,
                bio:artist.bio,
                artistId: artist.artistId

            }
            await ArtistAPI.put(`/update/${artist.slug}`, payload).catch((error) => console.log(error))
            window.location.replace(`${window.location.origin}/artists`);

        } else {
            console.log("Vous n'êtes pas admin")
        }

    }
    async function deleteArtist() {
        if (currentUser.role === 'Administrator') {
            const payload = {
                id: artist.artistId
            }
            await ArtistAPI.delete(`/delete/${artist.artistId}`, payload).catch((error) => console.log(error))
            window.location.replace(`${window.location.origin}/artists`);
        } else {
            console.log("Vous n'êtes pas admin")
        }

    }


    useEffect(() => {
         fetchArtist()
    }, [fetchArtist])


    const handleChange = event => {
        const { name, value } = event.target

        setArtist(artist => ({ ...artist, [name]: value }))
    }
    if (currentUser.role === 'Administrator') {
    return (
        <React.Fragment>
            <Header />
            <h1>Artist Component</h1>
            <div className={styles.artists_collection}>


                <div className={styles.artists_card}>
                    <img src={process.env.PUBLIC_URL + artist.image} alt='' />
                    <div className={styles.artist_informations}>
                        <input type='text' name='firstname' onChange={handleChange} defaultValue={artist.firstname !== null ? `${artist.firstname}` : ""}></input>
                        <input type='text' name='lastname' onChange={handleChange} defaultValue={artist.lastname !== null ? `${artist.lastname}` : ""}></input>
                        <input type='text' name='pseudo' onChange={handleChange} defaultValue={artist.pseudo !== null ? `${artist.pseudo}` : ""}></input>
                        <textarea type='textarea' name='bio' onChange={handleChange} defaultValue={artist.bio !== null ? `${artist.bio}` : ""}></textarea>
                        <h3>{artist.artistId}</h3>

                        <button onClick={updateArtist}>Update Artist</button>
                        <button onClick={deleteArtist}>Delete Artist</button>

                    </div>
                </div>


            </div>
        </React.Fragment>
    )
    }else {
        <></>
    }
}



export default EditArtist