// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../UI/Header'
import axios from 'axios'
import * as ContractFunctions from '../../../Functions/ContractFunctions'
import CollectionAPI from '../../../APIs/CollectionAPI';
import * as fs from 'fs'
import ContractAPI from '../../../APIs/ContractAPI';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { useLoaderData } from 'react-router-dom'

function CreateContract() {
    const currentUser = useLoaderData();

    const [contract, setContract] = useState({
        name: '',
        collectionId: '',
        collectionName: '',
        symbol: '',
        ipfs: '',
        prices: [0, 0, 0],
        deployed: false,
        lotery: '',
        loteryPrice: ''
    })




    const [collections, setCollections] = useState({
        idcollection: '',
        artist: '',
        collection_name: '',
        preview: '',
        created_date: '',
        modified_date: '',
        nb_contract: ''
    })


    const getCollections = useCallback(async () => {
        const response = await CollectionAPI.get('/')

        if (response.data.length !== 0) {
            const collectionsArr = []
            response.data.forEach(async collection => {
                collectionsArr.push(collection)
            })
            setCollections(collectionsArr)
        } else {
            setCollections('Aucune collection disponible')
        }

    }, [collections.idcollection, collections.artist, collections.collection_name, collections.created_date, collections.preview, collections.modified_date, collections.nb_contract])


    useEffect(() => {
        getCollections();
    }, [getCollections])

    const handleChange = event => {
        const { name, value } = event.target

        if (name === 'collectionId') {
            const htmlSelect = event.target
            const index = htmlSelect.selectedIndex;
            const selected = htmlSelect.options[index]
            console.log(selected.innerText)
            setContract({ ...contract, ['collectionName']: selected.innerText, [name]: value })
        } else if (name === 'ipfs') {

            setContract({ ...contract, [name]: `ipfs://${value}/` })
        } else if (name.includes('prices')) {

            const valueInWei = value * 1e18
            setContract((prevContract) => {
                const sliceIndex = name.slice(-1)
                const index = sliceIndex - 1
                const newContract = { ...prevContract };
                newContract.prices[index] = String(valueInWei)

                return newContract
            })
        }
        else {
            setContract({ ...contract, [name]: value })
        }
        console.log(contract)
    }


    let collectionsDisplay;
    if (collections[0] !== undefined && typeof collections !== 'string') {

        collectionsDisplay = collections.map((collection, index) => {
            return (
                <option key={index} value={collection.idcollection}>
                    {collection.collection_name}
                </option>
            )
        })
    }

    if (typeof currentUser !== 'undefined') {
        if (currentUser.role === 'Administrator') {

            return (
                <React.Fragment>
                    <Header />
                    <h1>Fonctionalitées du Contrat</h1>
                    <div className='function'>
                        <input onChange={handleChange} name='name' type="text" placeholder='Nom du fichier correspondant au contrat '></input>
                        <input onChange={handleChange} name='symbol' type="text" placeholder='Symbole '></input>
                        <input onChange={handleChange} name='ipfs' type="text" placeholder='CID '></input>
                        <input onChange={handleChange} name='prices_1' type="number" placeholder='Price For 1st NFT '></input>
                        <input onChange={handleChange} name='prices_2' type="number" placeholder='Price For 2nd NFT '></input>
                        <input onChange={handleChange} name='prices_3' type="number" placeholder='Price For 3th NFT '></input>
                        <input onChange={handleChange} name='lotery' type="number" placeholder='0 not 1 yes'></input>
                        <input onChange={handleChange} name='loteryPrice' type="number" placeholder='Price in EUR'></input>
                    </div>
                    <div >
                        <label htmlFor="collectionId">Choisissez un artiste:</label>
                        <select onChange={handleChange} name="collectionId" id="collectionId">
                            <option>Choissiez une collection</option>
                            {collectionsDisplay ? collectionsDisplay : ""}
                        </select>
                    </div>


                    <button type='submit' onClick={() => ContractFunctions.deployContractERC1155(contract)}>Deployé le contrat</button>


                </React.Fragment>
            )
        }
    } else {
        return (<></>)
    }

}


export default CreateContract;