import React, { useState } from 'react';
import styles from '../../styles/Form/Form.module.scss'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
function CollaborationForm(props) {
    const [formValue, setFormValue] = useState({
        type: 'compagny',
        lastname: '',
        firstname: '',
        compagnyName: '',
        talentName: '',
        email: '',
        question: ''
    })

    const [isSended, setIsSended] = useState(false)
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    const { t, i18n } = useTranslation()
    const handleChange = event => {
        const { name, value } = event.target

        if (name === 'type') {
            if (value === 'compagny') {
                setFormValue({ ...formValue, [name]: value, 'talentName': '' })
            } else {
                setFormValue({ ...formValue, [name]: value, 'compagnyName': '' })
            }

        } else {
            setFormValue({ ...formValue, [name]: value })
        }
        console.log(formValue)
    }

    const handleSubmit = async () => {
        if (formValue.email !== '' && formValue.question !== '') {
            setIsLoading(true)

            await axios.post('http://localhost:5000/api/v1/send-email', formValue)
                .then((res) => {
                    if (res.status === 200) {
                        setIsSended(true)
                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
                .catch((e) => {
                    if (e) {
                        console.log(e)
                        setError(true)
                    }
                })

        }
    }

    if (props.display === 'none') {
        return <></>
    } else {
        return (

            <div className={styles.form}>
                <label htmlFor="type">{i18n.language === 'fr-FR' ? 'Vous êtes ' : 'You are'}</label>
                <select className={styles.formDropdown} onChange={handleChange} name="type" id="type">
                    <option value="compagny">{t('ContactForm.DropdownEntreprise')}</option>
                    <option value="talent">{t('ContactForm.DropdownTalent')}</option>
                </select>
                {formValue.type === 'compagny'
                    ? <input className={styles.formInput} type="text" name="compagnyName" placeholder={t('ContactForm.InputEntreprise')} onChange={handleChange} />
                    : <input className={styles.formInput} type="text" name="talentName" placeholder={t('ContactForm.InputTalent')} onChange={handleChange} />}
                <div className={styles.formName}>
                    <input className={styles.formInput} type="text" name="lastname" placeholder={t('ContactForm.Nom')} onChange={handleChange} />
                    <input className={styles.formInput} type="text" name="firstname" placeholder={t('ContactForm.Prenom')} onChange={handleChange} />

                </div>

                <input className={styles.formInput} type="text" name="email" placeholder='Email' onChange={handleChange} />
                <textarea rows={3} cols={30} className={styles.formInput} name="question" placeholder={t('ContactForm.Question')} onChange={handleChange} />

                <button className={`${styles.submitButton} ${styles.type1}`} onClick={handleSubmit} >{t('ContactForm.Envoyer')}</button>
                {isLoading ? "En cours d'envoie" : ''}
                {isSended ? <p>{t('ContactForm.Success')}</p> : ''}
                {error ? <p>{t('ContactForm.Error')}</p> : ''}
            </div>

        )
    }



}

export default CollaborationForm;