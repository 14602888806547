import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import CollaborationForm from './CollaborationForm';
import ContactUsForm from './ContactUsForm';
import styles from '../../styles/Form/Form.module.scss'

function FormContainer() {
    const [formType, setFormType] = useState('classic');
    const { t } = useTranslation()


    return (
        <div className={styles.formContainer}>
            <div className={styles.formBorder}>

                <button className={`${styles.submitButton} ${styles.type1}`} onClick={() => setFormType('collaboration')}>{t('ContactForm.BtnCollaboration')}</button>
                <button className={`${styles.submitButton} ${styles.type1}`} onClick={() => setFormType('classic')}>{t('ContactForm.BtnQuestion')}</button>

                <div className={styles.form}>
                    <h1 className={styles.formTitle}>{t('ContactForm.Titre')}</h1>


                    {formType === 'collaboration' ? <CollaborationForm /> : <ContactUsForm />}

                </div>
            </div>
        </div>
    )
}

export default FormContainer