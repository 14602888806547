import React from 'react'
import Header from '../UI/Header'
import styles from '../../styles/CG/CG.module.scss'
import FormContainer from '../Form/FormContainer'
import Footer from '../UI/Footer'
function CG() {
    return (
        <>
            <Header />
            <section className={styles.container}>

                <h1>CONDITIONS GÉNÉRALES D'UTILISATION</h1>
                <section className={styles.content}>
                    <h2>LEXIQUE</h2>
                    <li>« Société » désigne la plateforme NFSQUAT édité par la société S2R STARBRAND (voir chapitre « Mentions légales »).</li>
                    <li>« Plateforme »  www.nfsquat.com désigne la présente plateforme qui accueille les NFT dont fait l’objet les présentes conditions générales d’utilisation.</li>
                    <li>« Personnes concernées » désigne les personnes concernées par les présentes conditions générales. Voir chapitre « Personnes concernées »</li>
                    <li><a href='https://www.amf-france.org/fr' rel="noopener noreferrer" target="_blank"><span>« AMF » désigne l’Autorité des marchés financiers : www.amf.org</span></a></li>
                    <li>« Livre blanc »: désigne le document présentant le projet. La terminologie livre blanc est utilisée dans le cadre d’un document n’ayant pas obtenu le VISA de l’AMF.</li>
                    <li><a href='https://www.amf-france.org/fr/reglementation/doctrine/DOC-2019-06' rel="noopener noreferrer" target="_blank"><span>« Document d’information » désigne un livre blanc ayant obtenu le VISA de l’AMF. Le document d’information doit répondre aux exigences du DOC-2019-06 de l’AMF.</span></a></li>
                    <li>« Blockchain » désigne une technologie de stockage et de transmission d’informations sans organe de contrôle. Techniquement, il s’agit d’une base de données distribuée dont les informations envoyées par les utilisateurs et les liens internes à la base sont vérifiés et groupés à intervalles de temps réguliers en blocs, l’ensemble étant sécurisé par cryptographie, et formant ainsi une chaîne de blocs. Par extension, une chaîne de blocs est une base de données distribuée qui gère une liste d’enregistrements protégés contre la falsification ou la modification par les nœuds de stockage. Une blockchain est donc un registre distribué et sécurisé de toutes les transactions effectuées depuis le démarrage du système réparti. Pour notre site Web, la Blockchain utilisée est la Blockchain Ethereum.</li>
                    <li>« Frais de minage » désigne les frais permettant de réaliser les transactions sur la blockchain. C’est frais sont reversés au mineur validant la transaction sur la Blockchain.</li>
                    <li>« Smart contract » ou contrat intelligent, désigne un programme informatique irrévocable, déployé sur une blockchain, qui exécutent un ensemble d’instructions prédéfinies.</li>
                </section>
                <section className={styles.content}>
                    <h2>ACCEPTATION DES CONDITIONS GÉNÉRALES</h2>
                    <p>Mise à part les simples visiteurs, les personnes concernées s’engagent à avoir lu et accepté les présentes conditions générales avant toutes utilisations des prestations ou services proposées par la société ainsi que, pour les investisseurs, avant toute contribution sur un projet publié sur la Plateforme.</p>
                </section>
                <section className={styles.content}>
                    <h2>MODIFICATION DES CONDITIONS GÉNÉRALES</h2>
                    <p>La société se réserve le droit de modifier unilatéralement les présentes conditions générales, à sa seule discrétion, à tout moment et sans préavis.</p>
                    <p>Toute modification prend effet immédiatement à compter de sa publication. Dans le cas où ces modifications seraient importantes, la société les notifiera aux personnes concernées dans les meilleurs délais. Faute d’accord avec ces modifications, les personnes concernées ne pourront plus utiliser les services. A défaut, les modifications apportées aux conditions générales seront réputées acceptées à partir du moment où les personnes concernées continueront d’utiliser les services après publication desdits changements.</p>
                </section>
                <section id='mentions-legales' className={styles.content}>
                    <h2>MENTIONS LÉGALES</h2>
                    <p>Nom de la société	S2R Starbrand</p>
                    <p>Président	Cyril Estarellas</p>
                    <p>Siège social	4 av Laurent Cély, 92600 Asnières sur Seine</p>
                    <p>Code APE	7021Z</p>
                    <p>Numéro SIREN	50374261100026</p>
                    <p>Numéro de TVA intracommunautaire	FR49503742611</p>
                    <p>Date d’immatriculation	17 août 2009</p>
                    <p>Capital Social	1 500 €</p>
                    <p>Cadre juridique	SAS</p>
                    <p>Contact	contact@nfsquat.com</p>
                </section>
                <section className={styles.content}>
                    <h2>LANGUE APPLICABLE</h2>
                    <p>La langue officielle de la Plateforme est le français. La Plateforme propose un logiciel de traduction instantanée mais la société ne peut pas en garantir un résultat fidèle. Il est de la responsabilité des visiteurs de vérifier les traductions dans les langues de leurs choix le cas échéant.</p>
                    <p>Les présentes conditions générales sont rédigées en langue française. Toute traduction des présentes conditions générales dans une langue étrangère au français n’est donnée qu’à titre informatif. En cas de contradiction entre la version française et une version traduite en langue étrangère, seule la version française aura valeur juridique.</p>
                </section>
                <section className={styles.content}>
                    <h2>DISPONIBILITÉ DE LA PLATEFORME</h2>
                    <p>La Plateforme est accessible 7 jours sur 7 et 24 heures sur 24, sauf opération de maintenance ou indisponibilité des réseaux due à une cause non imputable à la société.</p>
                    <p>La société met en œuvre ses meilleurs efforts pour maintenir la disponibilité de ses services. Cependant, en cas d’indisponibilité de la Plateforme, aucune indemnité ne pourra être exigée.</p>
                    <p>L’accès aux services est par ailleurs conditionné par le bon fonctionnement du navigateur internet, du portefeuille Ethereum et/ou du système d’exploitation des personnes concernées. Il est donc de la responsabilité des personnes concernées de s’assurer des bonnes mises à jour des logiciels utilisés.</p>
                </section>
                <section className={styles.content}>
                    <h2>PRÉREQUIS TECHNIQUES</h2>
                    <p>Pour une utilisation optimale de la Plateforme, la société recommande d’utiliser des navigateurs utilisant la technologie WEB3 (navigateur traditionnel équipé d’un portefeuille de cryptomonnaie compatible Ethereum ).</p>
                    <ul>Sur ordinateur la société recommande d’utiliser :
                        <li>Les navigateurs Google Chrome ou Mozilla Firefox</li>
                        <li>L'extension de navigateur Metamask</li>
                    </ul>
                    <ul>Sur smartphone IOS ou Android la société recommande d’utiliser :
                        <li>L'application Metamask</li>
                    </ul>
                    <p>Il ne s’agit que de simples recommandations et la responsabilité de la société ne pourra en aucun cas être recherchée en cas de dysfonctionnement de ces logiciels. Les personnes concernées, en validant les conditions générales d’utilisation, s’engagent aussi à avoir pris connaissance des conditions d’utilisations des logiciels qu’elles utilisent.</p>
                </section>
                <section className={styles.content}>
                    <h2>JURIDICTION COMPÉTENTE EN CAS DE LITIGE</h2>
                    <p>Ce chapitre s’applique dans le cas d’un litige ou d’une réclamation avec la société.</p>
                    <p>En cas de litige portant sur la validité, la formation, l’exécution ou l’interprétation des présentes conditions générales, seul le tribunal de commerce de Cergy Pontoise sera compétent, nonobstant pluralité de parties au litige et nonobstant tout lien de connexité ou de litispendance.</p>
                    <p>En cas de litige, la personne concernée est tenue de s’adresser en priorité à la société pour essayer de trouver une solution amiable.</p>
                    <p>Toutes les réclamations relatives aux services ou à l’exécution des conditions générales devront être adressées au service client de la société et accompagnées des pièces justificatives, aux coordonnées suivantes :</p>
                    <p>Par lettre recommandée avec accusé de réception à l’adresse suivante : </p>
                    <p>S2R STARBRAND / NFSQUAT, La Tour d'Asnières, 4 av Laurent Cély, 92600 Asnières sur Seine ou par email : contact@s2r-starbrand.com</p>
                    <p>Toute réclamation doit être adressée dans les 30 (trente) jours après la date du fait auquel elle se rapporte. Aucune réclamation ne pourra être prise en compte après ce délai.</p>
                    <p>En application de l’article L612-1 et L616-2 du code de la consommation issus de l’ordonnance n° 2015-1300 du 20 août 2015 relative au règlement extrajudiciaire des litiges de consommation, les personnes concernées peuvent recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable d’un éventuel litige dans le cadre de l’exécution des présentes conditions générales.</p>
                    <p>Les personnes concernées ont la possibilité, à leurs frais, de se faire représenter par un avocat ou de se faire assister par toute personne de son choix.</p>
                </section>
                <section className={styles.content}>
                    <h2>PERSONNES CONCERNÉES : VISITEUR</h2>
                    <p>Visiteur</p>
                    <p>Définition</p>
                    <p>Un visiteur est une personne physique ou morale qui n’a pas de compte sur notre Plateforme. Un visiteur n’a pas besoin d’être inscrit pour pouvoir naviguer librement sur les pages publiques de la Plateforme.</p>

                </section>
                <section id='politique-de-confidentialite' className={styles.content}>
                    <h2>POLITIQUE DE CONFIDENTIALITÉ</h2>
                    <h4>Données collectées / enregistrées</h4>
                    <p>Pour plus de détails sur les données collectées et enregistrées par la société pour chacune des personnes concernées, merci de consulter les chapitres « personnes concernées ».</p>
                    <p>Ci-joint un tableau récapitulatif de toutes les données collectées, conservées et enregistrées.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Données collectées</th>
                                <th scope="col">Sur serveur</th>
                                <th scope="col">Sur disque externe</th>
                                <th scope="col">Partenaire KYC/AML</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Prénom</th>
                                <td>V</td>
                                <td>V</td>
                                <td>V</td>
                            </tr>
                            <tr>
                                <th scope="row">Nom</th>
                                <td>V</td>
                                <td>V</td>
                                <td>V</td>
                            </tr>
                            <tr>
                                <th scope="row">Email</th>
                                <td>V</td>
                                <td>V</td>
                                <td>V</td>
                            </tr>
                            <tr>
                                <th scope="row">Adresse Ethereum</th>
                                <td>V</td>
                                <td>V</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <th scope="row">Adresse postale</th>
                                <td>X</td>
                                <td>V</td>
                                <td>V</td>
                            </tr>
                            <tr>
                                <th scope="row">Document d’information</th>
                                <td>V</td>
                                <td>V</td>
                                <td>X</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th scope="row">Utilisation des données par la société</th>
                                <td>V</td>
                                <td>V</td>
                                <td>X</td>
                            </tr>
                        </tfoot>
                    </table>
                    <p>Durée de conservation</p>
                    <p>5 ans après demande clôture du compte le cas échéant.</p>
                    <p>Droit à l’effacement (« droit à l’oubli »)</p>
                    <p>À tout moment les personnes concernées peuvent faire une demande de clôture de leur compte et de suppression de leurs données par lettre en recommandée avec accusé de réception ou directement par courriel. La date de prise en compte de la demande sera alors la date de réception du courrier. La date effective de suppression des données prendra effet après la durée de conservation.</p>
                    <p>Si vous avez un compte sur notre site, vous pouvez demander à recevoir un fichier d’export contenant les données personnelles vous concernant, y compris toutes les données que vous nous avez fournies. Cela n’inclut pas les données que nous avons l’obligation de conserver pour des raisons administratives, légales ou de sécurité. Pour exercer vos droits, vous pouvez le faire en nous contactant via le formulaire contact.</p>
                    <p>Comment protégeons nous vos données ?  </p>
                    <p>Le site Web est accessible uniquement en https, chiffré avec la technologie SSL. Les données sont stockées dans une base de données protégées par un identifiant et un mot de passe fort. Les sauvegardes sont faites quotidiennement sur un stockage protégé par un identifiant et un mot de passe fort.</p>
                </section>
                <section className={styles.content}>
                    <h2>FACTEURS DE RISQUES</h2>
                    <p>Risque d’erreurs ou de failles de sécurité permettant un piratage ou un vol de données</p>
                    <p>Les smart contracts de la plateforme créée par la société sont tous audités et aucune vulnérabilité connue n’a été détectée. La société a pris toutes les dispositions nécessaires en termes de cybersécurité pour se prémunir des différentes attaques connues et susceptibles d’interrompre la Plateforme. Il est important de préciser que la Plateforme est décorrélée de la blockhain sur laquelle l’ICO/NCO est hebergée. Une perturbation de l’une des couches n’impacte pas l’autre. Les fonds collectés et les jetons émis sont sécurisés par la blockchain.</p>
                    <p>Cependant, la société décline toute responsabilité en cas de dysfonctionnement ou opérations inattendues et imprévisibles liées à la blockchain, perte de jetons par le souscripteur, piratage ou toute situation impliquant l’impossibilité pour le souscripteur d’avoir accès à ses jetons.</p>
                    <p>Risque de perte ou de vol du support de la clé privée du souscripteur</p>
                    <p>Toutes les transactions réalisées sur la blockchain se font par l’intermédiaire de la clé publique et privée du souscripteur. En cas de perte de la clé privée, les fonds et jetons détenus seront définitivement perdus. Ni la société émettrice ni la société possèdent les clés privées et en aucun cas une clé privée ne doit être communiquée à une tierce personne.</p>
                    <p>Nous attirons l’attention de tout utilisateur qu’il est de la responsabilité du souscripteur de conserver l’accès à son adresse de crypto wallet via sa clé privée et sa clé publique.</p>
                    <p>Les smart contract ont été audités et aucune faille de sécurité ou d’erreur dans le code n’a été détectée. Cependant, il peut exister un risque de dysfonctionnement inhérent à tout programme informatique et, malgré les contrôles effectués par la société, une défaillance technique reste toujours possible</p>
                    <p>Risques liés à la blockchain sur laquelle sont inscrits les jetons et aux plateformes sur lesquelles les jetons peuvent être échangés</p>
                    <p>La société utilise exclusivement la blockchain Ethereum qui depuis leurs créations, à la connaissance de la société, n’ont connu aucune vulnérabilité, ni corruption.</p>
                    <p>Pour plus de sécurité, seule les plateformes d’échange enregistrée auprès de l’AMF sont recommandé par la société. La société émettrice doit néanmoins recommander à ses souscripteurs de lire attentivement les conditions générales d’utilisation de celles ci ou de toutes autres plateformes qu’elle aura choisi. La société décline toute responsabilité en cas de mauvaise utilisation, de failles techniques ou de piratage de celles-ci.</p>
                </section>
                <section className={styles.content}>
                    <h2>DESCRIPTIF DES DISPOSITIFS DE CYBER-SÉCURITÉ ET DE DÉTECTION DES DYSFONCTIONNEMENTS TECHNIQUES MIS EN PLACE </h2>
                    <ul>L’architecture informatique de la Plateforme repose sur plusieurs couches réseaux :

                        <li>Le réseau Web sur lequel est développée notre plateforme ; Le réseau blockchain Ethereum sur lequel s’exécutent les smart contracts et les transactions de jetons et de crypto-actifs de façon sécurisées ; Le réseau IPFS basé sur un protocole P2P, qui a pour objectif de stocker des fichiers (metadatas, images) de manière décentralisée.</li>
                        <li>La Plateforme possède un certificat SSL et fonctionne uniquement en HTTPS.</li>
                        <li>La base de données de la Plateforme ne contient que le username, nom, prénom, email et adresse publique Ethereum de chaque utilisateur. Aucune donnée sensible n’est stockée sur les serveurs de la société.</li>
                        <li>Les jetons et crypto-monnaies ainsi que l’ensemble des transactions sont conservés dans la blockchain. La blockchain est sauvegardée à vie par l’ensemble des nœuds qui constituent le réseau. L’historique des transactions est infalsifiable.</li>
                    </ul>
                    <ul>Sur la Plateforme, les dispositifs mis en place sont :

                        <li>Protection anti attaques DDoS </li>
                        <li>Sauvegardes journalières de la base de données  </li>
                        <li>Alerte email si le site est hors service</li>
                        <li>Logiciels de sécurité installés et mis à jour </li>
                        <li>Pare-feu </li>
                        <li>Détection et blocage en cas de tentative de modification frauduleuse de l’adresse Ethereum d’un smart contract de l’ICO/NCO </li>
                        <li>Journalisation de tous les événements en relation avec la blockchain ou les utilisateurs </li>
                        <li>Mises à jour système hebdomadaires</li>
                        <li>La Plateforme est un portail qui permet d’exécuter des transactions sur la blockchain Ethereum. Il est important de préciser qu’en cas d’arrêt de la Plateforme, le fonctionnement de la blockchain et des smart contracts resteront fonctionnels. Aucune perturbation de la blockchain ne peut être causée par la platefortme. En cas de dysfonctionnement de la blockchain, celà peut causer des perturbations sur la plateforme. </li>
                    </ul>
                    <p>Déclaration obligatoire</p>
                    <p>Les personnes physiques, les associations, les sociétés n’ayant pas la forme commerciale, domiciliées ou établies en France, sont tenues de déclarer, en même temps que leur déclaration de revenus ou de résultats, les références des comptes de cryptoactifs ouverts, détenus, utilisés ou clos auprès d’entreprises, personnes morales, institutions ou organismes établis à l’étranger.</p>
                    <p>Ainsi, même si vous n’avez retiré aucun gain en crypto-monnaie durant l’année, vous serez tenu de déclarer l’ensemble de vos comptes ouverts à l’étranger. Cette déclaration s’effectue grâce au formulaire suivant : CERFA 3916 – Déclaration par un résident d’un compte ouvert hors de France.</p>
                </section>


            </section>

            <FormContainer />
            <Footer />
        </>
    )
}

export default CG