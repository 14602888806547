import React from 'react';
import stylesCollection from '../../styles/NFTS/Collection.module.scss'
import CardStyles from '../../styles/Artists/Artist.module.scss'
import ProgramsDisplay from '../Programs/ProgramsDisplay';
import stylescard from '../../styles/NFTS/NFTArtistPreviewTalent.module.scss'

const MobileDisplay = ({ metadatas, userOwnTokens, handleUserToken }) => {
  return (
    <>
      {metadatas.map((metadata, index) => {
        let name;
        if (metadata.contractName === 'SDB') {
          if (index === 0) {
            name = 'The Unique';
          } else if (index === 1) {
            name = 'The Super Rare';
          } else if (index === 2) {
            name = 'The Rare';
          } else {
            name = 'The Limited';
          }
        } else {
          if (index === 0) {
            name = 'The Unique';
          } else if (index === 1) {
            name = 'The Rare';
          } else {
            name = 'The Limited';
          }
        }
        return (
          <section key={index} className={stylesCollection.collectionDetails}>
            <div className={CardStyles.collectioncard}>
              <div className={CardStyles.container}>
                <div className={`${stylescard.card} ${stylescard.shadow}`}>
                  <img className={stylescard.cardimg} src={metadata.image} />
                </div>
              </div>
            </div>
            <ProgramsDisplay userOwnTokens={userOwnTokens} handleUserToken={handleUserToken} key={index} lottery={metadata.lottery} name={name} contract={metadata} token={index} />
          </section>
        )
      })}
    </>
  );
};

export default MobileDisplay;
