// @ts-nocheck
import React, {  useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from "@magic-ext/connect";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes, FaUserCircle, FaWallet } from 'react-icons/fa'
import { MdOutlineExitToApp } from 'react-icons/md'
import { UserContext } from '../Context/Context';
import LogoutButton from '../Login/LogoutButton';
import UserAPI from '../../APIs/UserAPI';
import '../../styles/UI/Header.scss';

function Header() {
    useEffect(() => {
        window.scrollTo(-10, -10)
    }, [])

    const navRef = useRef()
    const headerRef = useRef()
    const [error, setError] = useState({ code: '', message: '' })
    const [language, setLanguage] = useState('');
    const { t } = useTranslation()
    const currentLanguage = localStorage.getItem("i18nextLng")
    const [user, setUser] = useContext(UserContext)
    const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY,
        {
            extensions: [new ConnectExtension()]
        })


    const showHeader = () => {
        navRef.current.classList.toggle('responsive_header')
    }

    const handScrolling = () => {
        const header = headerRef.current
        const scrollTop = window.pageYOffset;
        if (header) {

            scrollTop >= 480 ? header.classList.add('sticky') : header.classList.remove('sticky');
        }
    };


    const handleChangeLanguage = (option) => {
        i18next.changeLanguage(option.target.value)
        setLanguage(option.target.value)
    }
    const login = async () => {

        try {
            const magicPerso = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY,
                {
                    extensions: [new ConnectExtension()]
                })
            const accounts = await magicPerso.wallet.connectWithUI();
            if (accounts[0]) {
                const walletType = await magicPerso.wallet.getInfo();
                const userExist = await userExistInDb(accounts[0])
                if (userExist) {
                    setUser(userExist);
                } else {
                    const payload = { wallet: accounts[0], walletType: walletType.walletType }
                    const newUser = await createUserForDB(payload)
                    if (newUser.data.length !== 0) {
                        setUser(newUser.data[0])
                    } else {
                        setUser({ user: null })
                    }
                }
            }


        } catch (e) {
            setError({code: e.code, message:e.message})
        }




    };
    const handleShowMagicWallet = async () => {
        const {walletType} = await magic.wallet.getInfo();

        if (walletType === "magic") {
            await magic.wallet.showUI().catch((e) => {
                console.log(e);
            })
        } else {
            console.log('bad wallet')
        }
    }
    const userExistInDb = async (wallet) => {
        const user = await UserAPI.get(`/${wallet}`)
        if (user.data.length !== 0) {
            return user.data[0]
        } else {
            return false
        }
    }


    const createUserForDB = async (payload) => {
        const userCreated = await UserAPI.post(`/create`, payload)
        if (userCreated.affectedRows !== 0) {
            const newUser = await UserAPI.get(`/${payload.wallet}`)
            return newUser
        }
    }


    useEffect(() => {
        window.addEventListener('scroll', handScrolling);
        return () => {
            window.removeEventListener('scroll', handScrolling);
        };
    });

    const handleLanguage = useCallback(()=>{
        setLanguage(currentLanguage)

    },[currentLanguage])

    useEffect(() => {
        handleLanguage()

    }, [handleLanguage ])
    //<li><Link className='header-link' to='/about-us' >À notre sujet</Link></li>
    return (
        <header className='' ref={headerRef}>
            <NavLink reloadDocument className='header-link-img' to='/' ><img src='/images/logo.png' alt='Logo NFSQUAT'></img></NavLink>

            <nav ref={navRef}>
                {error.code !== '' ? <p>{error.message}</p> : ''}
                <li><NavLink reloadDocument className='header-link' to='/' >{t('Header.Accueil')}</NavLink></li>
                <li> <NavLink reloadDocument className='header-link' to='/gallery' >{t('Header.Galerie')}</NavLink></li>
                <li> <NavLink reloadDocument className='header-link' to='/talents' >{t('Header.Talents')}</NavLink></li>
                {user && user.hasOwnProperty('user') && user.user === 'loading' ? '' :
                    <>
                        {user && user.hasOwnProperty('role') && user.role === 'Administrator' ? <li><NavLink className='header-link admin-icon' to='/admin' ><MdOutlineExitToApp /> </NavLink></li> : ''}
                        {user && user.hasOwnProperty('wallet') && user.wallet ? <li> <NavLink reloadDocument className='header-link profil-link' to='/profile' ><FaUserCircle className='profil-icon' /> {t('Header.MonCompte')}</NavLink></li> : ''}
                        {user && user.user !== 'loading' && user.user !== null ? <li><LogoutButton type={user.wallet_type} /> </li> : ''}
                        {user && user.user !== 'loading' && user.user === null ? <li><button title="Se connecter" onClick={login}>{t('Header.Connect')}</button></li> : ''}
                        {user && user.hasOwnProperty('wallet') && user.wallet_type === 'magic' ? <li> <button title="Voir le wallet" onClick={handleShowMagicWallet}><FaWallet /></button></li> : ''}



                    </>
                }

                <button className='header-btn header-close-btn' onClick={showHeader}>
                    <FaTimes />
                </button>
                <select onChange={handleChangeLanguage} value={language}>
                    <option value='fr-FR'>FR</option>
                    <option value='en-EN'>EN</option>
                </select>
            </nav>
            <button className='header-btn' onClick={showHeader}>
                <FaBars />
            </button>

        </header>
    )
}
/*
    {user && user.hasOwnProperty('wallet_type') && user.wallet_type === 'magic' ? <button onClick={login}>Show Magic Wallet</button> : ''}

*/
export default Header;