// @ts-nocheck
import React from 'react'
import Header from './Components/UI/Header'
import Banner from './Components/UI/Banner';
import Footer from './Components/UI/Footer';
import styles from './App.module.scss'
import NFTCardPreview from './Components/NFTs/NFTCardPreview';
import ParticlesBackground from './Components/UI/Particles/ParticlesBakcground';
import glitchStyles from './styles/utils/glitch.module.scss'
import FormContainer from './Components/Form/FormContainer';
import { GiCrownedHeart } from 'react-icons/gi';
import { BsCollection } from 'react-icons/bs';
import { TbPaperclip } from 'react-icons/tb';
import FAQ from './Components/FAQ/FAQ';
import Image from './Components/Image/Image';
import { Helmet } from 'react-helmet-async';
import 'swiper/swiper.scss';
import 'swiper/swiper-vars.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

function App() {

  const { t } = useTranslation()
  const isMobileScreen = useMediaQuery({ minWidth: 0, maxWidth: 380 });
  const isTabletScreen = useMediaQuery({ minWidth: 381, maxWidth: 768 });
  const isDesktopSDScreen = useMediaQuery({ minWidth: 769, maxWidth: 1200 });
  const isDesktopHDScreen = useMediaQuery({ minWidth: 1201, maxWidth: 1440 });
  const isWidescreenScreen = useMediaQuery({ minWidth: 1441 });

  const mobileDisplay =
    <div className={styles.content}>
      <NFTCardPreview collection='Homard Façon Table' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <GiCrownedHeart className={styles.iconService} />
          <h2>{t('Home.Talents.Title')}</h2>
          <p>{t('Home.Talents.Content')}</p>
        </div>
      </div>
      <NFTCardPreview collection='Studio Stories' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <TbPaperclip className={styles.iconService} />
          <h2>{t('Home.APropos.Title')}</h2>
          <p>{t('Home.APropos.Content')}</p>
        </div>
      </div>
      <NFTCardPreview collection='Pink Flamingo' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <BsCollection className={styles.iconService} />
          <h2>{t('Home.NosCollections.Title')}</h2>
          <p>{t('Home.NosCollections.Content')}</p>
        </div>
      </div>
    </div>

  const tabletDisplay =
    <div className={styles.content}>
      <NFTCardPreview collection='Homard Façon Table' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <GiCrownedHeart className={styles.iconService} />
          <h2>{t('Home.Talents.Title')}</h2>
          <p>{t('Home.Talents.Content')}</p>
        </div>
      </div>
      <NFTCardPreview collection='Studio Stories' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <TbPaperclip className={styles.iconService} />
          <h2>{t('Home.APropos.Title')}</h2>
          <p>{t('Home.APropos.Content')}</p>
        </div>
      </div>
      <NFTCardPreview collection='Pink Flamingo' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <BsCollection className={styles.iconService} />
          <h2>{t('Home.NosCollections.Title')}</h2>
          <p>{t('Home.NosCollections.Content')}</p>
        </div>
      </div>
    </div>


  const desktopSdDisplay =
    <div className={styles.content}>
      <NFTCardPreview collection='Homard Façon Table' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <GiCrownedHeart className={styles.iconService} />
          <h2>{t('Home.Talents.Title')}</h2>
          <p>{t('Home.Talents.Content')}</p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <TbPaperclip className={styles.iconService} />
          <h2>{t('Home.APropos.Title')}</h2>
          <p>{t('Home.APropos.Content')}</p>
        </div>
      </div>
      <NFTCardPreview collection='Studio Stories' />
      <NFTCardPreview collection='Pink Flamingo' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <BsCollection className={styles.iconService} />
          <h2>{t('Home.NosCollections.Title')}</h2>
          <p>{t('Home.NosCollections.Content')}</p>
        </div>
      </div>
    </div>

  const desktopHdDisplay =
    <div className={styles.content}>
      <NFTCardPreview collection='Homard Façon Table' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <GiCrownedHeart className={styles.iconService} />
          <h2>{t('Home.Talents.Title')}</h2>
          <p>{t('Home.Talents.Content')}</p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <TbPaperclip className={styles.iconService} />
          <h2>{t('Home.APropos.Title')}</h2>
          <p>{t('Home.APropos.Content')}</p>
        </div>
      </div>
      <NFTCardPreview collection='Studio Stories' />
      <NFTCardPreview collection='Pink Flamingo' />
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <BsCollection className={styles.iconService} />
          <h2>{t('Home.NosCollections.Title')}</h2>
          <p>{t('Home.NosCollections.Content')}</p>
        </div>
      </div>
    </div>

  const WidescreenDisplay =
    <div className={styles.content}>
      <div className={styles.nftContainer}>
        <NFTCardPreview collection='Homard Façon Table' />
        <NFTCardPreview collection='Studio Stories' />
        <NFTCardPreview collection='Pink Flamingo' />
        <NFTCardPreview collection='Pierre Taisne Series 1' />
      </div>
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <GiCrownedHeart className={styles.iconService} />
          <h2>{t('Home.Talents.Title')}</h2>
          <p>{t('Home.Talents.Content')}</p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <TbPaperclip className={styles.iconService} />
          <h2>{t('Home.APropos.Title')}</h2>
          <p>{t('Home.APropos.Content')}</p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={`${styles.ContainerBorderStyle} ${styles.type1}`}>
          <BsCollection className={styles.iconService} />
          <h2>{t('Home.NosCollections.Title')}</h2>
          <p>{t('Home.NosCollections.Content')}</p>
        </div>
      </div>
    </div>
 
    return (
      <React.Fragment>
        <Helmet>
          <meta name='description' content='Welcome to the NFSQUAT Gallery. Here you can find our collections and talents. You can also buy the NFT you want !  ' />
          <link rel='canonical' href='/' />
        </Helmet>
        <Header />
        <Banner content={t('Home.Banner')} content2={'NFT GALLERY FOR TALENT'} />

        {isMobileScreen ? mobileDisplay : ''}
        {isTabletScreen ? tabletDisplay : ''}
        {isDesktopSDScreen ? desktopSdDisplay : ''}
        {isDesktopHDScreen ? desktopHdDisplay : ''}
        {isWidescreenScreen ? WidescreenDisplay : ''}

        <div className={styles.content}>
          <ParticlesBackground type='content' />
          <div className={styles.container} data-content='secondary'>
            <Image classes={styles.imageHomepage} urls={[process.env.PUBLIC_URL + '/images/brusk.png']} alt='Oeuvre de Brusk pour SOS Méditerranée' />
            <div className={styles.contentTitle}>

              <h3 className={`${glitchStyles.hero} ${glitchStyles.glitch} ${glitchStyles.layers}`} data-text={t('Home.PQNfsquat.Title')}>
                <span>{t('Home.PQNfsquat.Title')} </span>
              </h3>
              <p>{t('Home.PQNfsquat.Content')}</p>
            </div>
          </div>
        </div>
        <div className={styles.faq}>
          <FAQ />
          <Image classes={styles.imageHomepage} urls={[process.env.PUBLIC_URL + '/images/w2cj-preview.gif']} alt='Photo de Photo W2CJ' />
        </div>
        <FormContainer />
        <Footer />

      </React.Fragment>
    );
  }


export default App;
