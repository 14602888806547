// @ts-nocheck
import React, { useState, useEffect, useCallback, useContext } from 'react';
import Header from '../../UI/Header';
import axios from 'axios';
import { ethers } from 'ethers';
import * as ContractFunctions from '../../../Functions/ContractFunctions';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import ContractAPI from '../../../APIs/ContractAPI';
import { useLoaderData } from 'react-router-dom'
import { ProviderContext } from '../../Context/Context';

function EditContract() {
    const currentUser = useLoaderData();

    const [contract, setContract] = useState({})
    const [image, setImage] = useState()
    const IPFS_PATH = process.env.REACT_APP_IPFS
    const [provider] = useContext(ProviderContext)

    const fetchContract = useCallback(async () => {

        const contractAddress = window.location.pathname.split('/')[3];
        const response = await ContractAPI.get(`/address/${contractAddress}`);
        const contractName = response.data[0].name
        const collectionId = response.data[0].collection_id
        const owner = response.data[0].owner

        if (contractName.length !== 0) {
            const json = await ContractFunctions.loadAbiContract(contractName, collectionId)
            if (provider.hasOwnProperty("_isProvider")) {
                const newContract = new ethers.Contract(contractAddress, json.abi, provider)
                
                try {
                    const currentSymbol = await newContract.symbol_();
                    const currentCost = await newContract.cost();
                    const currentBalance = await provider.getBalance(contractAddress)
                    const currentBaseURI = await newContract.uri(0)
                    const currentStatus = await newContract.paused()
                    const currentMaxPerWallet = await newContract.maxPerWallet()

                    
                    setContract({
                        name: contractName,
                        collectionId: collectionId,
                        owner: owner,
                        address: contractAddress,
                        symbol: currentSymbol,
                        cost: currentCost.toString() / 10 ** 18,
                        balance: currentBalance.toString() / 10 ** 18,
                        baseURI: currentBaseURI,
                        paused: currentStatus,
                        maxPerWallet: currentMaxPerWallet.toString(),
                        newURI: ''
                    })

                } catch (e) {
                    throw e.message
                }
            }
        }
    })

    const fetchImage = async () => {
        if (typeof contract.baseURI !== 'undefined') {
            const baseUri = contract.baseURI.replace('ipfs://', IPFS_PATH)
            const response = await axios.get(`${baseUri}/`)
            if (response.data.length !== 0) {
                const  image_uri = response.data.image
                let uriFormatted
                if (image_uri) {
                    
                    if (image_uri.substring(0, 4) === 'ipfs') {
                        uriFormatted = image_uri.replace('ipfs://', IPFS_PATH)

                    } else {
                        if (image_uri.includes('gateway.pinata.cloud')) {
                            uriFormatted = image_uri.replace('gateway.pinata.cloud', 'nfsquat.mypinata.cloud')
                        }else {
                            uriFormatted = image_uri
                        }
                    }

                   
                   
                }

                setImage(uriFormatted)
            }
        }
    }

    const handleChange = event => {
        const { name, value } = event.target
        setContract({ ...contract, [name]: value })
        console.log(contract)
    }

    useEffect(() => {
        fetchContract();

    }, [])

    if (typeof currentUser !== 'undefined') {

        if (currentUser.role === 'Administrator') {
            if (contract.name !== undefined) {
                return (
                    <React.Fragment>
                        <Header />
                        <h1>Fonctionalitées du Contrat</h1>

                        <img style={{ width: '30%' }} src={image} alt=''></img>
                        <div >
                            <p>Base URI : {contract.baseURI}</p>
                            <input onChange={handleChange} name='newURI' type="text" placeholder='Nouvelle URI'></input>
                            <button onClick={() => { ContractFunctions.setContractBaseURI(contract.owner, contract.name, contract.address,contract.collectionId, contract.newURI) }}>Mettre à jour</button>
                        </div>

                        <div >
                            <p>Prix par NFT en Ether</p>
                            <p>{contract.cost} ETH</p>
                            <input defaultValue={(contract.cost)} onChange={handleChange} name='cost' type="number" placeholder='Nouveau Prix en ETH'></input>
                            <button onClick={() => { ContractFunctions.setContractCost(contract.owner, contract.name, contract.address, contract.collectionId, contract.cost) }}>Mettre à jour</button>
                        </div>
                        <div >
                            <p>Nombre de Mint maximum par transaction</p>
                            <p>{contract.maxPerWallet}</p>
                            <input defaultValue={contract.maxPerWallet} onChange={handleChange} name='maxPerWallet' type="number" placeholder='Nombre de mint par transaction'></input>
                            <button onClick={() => { ContractFunctions.setContractMaxMint(contract.owner, contract.name, contract.address, contract.maxPerWallet) }}>Mettre à jour</button>
                        </div>
                        <div >
                            <p>État du contrat</p>
                            <select name='paused' defaultValue={contract.paused} onChange={handleChange}>
                                <option value={"default"} disabled>
                                    Changer l'état : {contract.paused}
                                </option>
                                <option value={"true"}>Pause</option>
                                <option value={"false"}>Activer</option>
                            </select>
                            <button onClick={() => { ContractFunctions.setContractStatus(contract.owner, contract.name, contract.address, contract.paused) }}>Mettre à jour</button>
                        </div>
     
                        <div >
                            <p>Transférer les fonds du contrat vers l'addresse du propriétaire</p>
                            <p>{contract.balance} ETH</p>
                            <button onClick={() => { ContractFunctions.withdraw(contract.name, contract.address, contract.collectionId) }}>Transférer</button>
                            <p>{contract.walletOfOwner}</p>
                        </div>
                        <div >
                            <p>Burn Contract</p>
                            <button onClick={() => { ContractFunctions.burn(contract.name, contract.address) }}>Supprimer le contrat</button>
                        </div>


                    </React.Fragment>
                )
            }
            else {
                <React.Fragment>
                    <Header />

                    <h1>Chargement</h1>
                </React.Fragment>
            }
        }
    } else {
        return <></>
    }

}


export default EditContract;