import { ConnectExtension } from '@magic-ext/connect';
import { Magic } from 'magic-sdk';
import React, { useContext } from 'react'
import { BiLogInCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { ProviderContext, UserContext } from '../Context/Context';

function LogoutButton(props) {
    const [user, setUser] = useContext(UserContext)
    const [provider, setProvider] = useContext(ProviderContext)
    const navigate = useNavigate()
    const handleLogout = async () => {
        const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY,
            {
                extensions: [new ConnectExtension()]
            })

        await magic.wallet.disconnect() // clears user session
        setUser({ user: null })
        setProvider({ provider: null })
        navigate(0)
    }

    return (
        <button  title="Deconnexion"  onClick={handleLogout}><BiLogInCircle/> </button>
    )
}

export default LogoutButton