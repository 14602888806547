// @ts-nocheck
import axios from 'axios';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import styles from '../../styles/NFTS/NFTArtistPreview.module.scss';
import Banner from '../UI/Banner';
import Footer from '../UI/Footer';
import Header from '../UI/Header';
import ArtistCard from '../Artists/ArtistCard';
import CollectionAPI from '../../APIs/CollectionAPI';
import { BallTriangle } from 'react-loader-spinner'
import FormContainer from '../Form/FormContainer';
import Image from '../Image/Image';
import Tilt from 'react-vanilla-tilt'
import { Virtual, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next'
import 'swiper/swiper.scss';
import 'swiper/swiper-vars.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/autoplay/autoplay.scss';

import { Helmet } from 'react-helmet-async';

function Galery() {

    const [collections, setCollections] = useState([]);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const getCollection = useCallback(async () => {

        const response = await CollectionAPI.get('/')
        const collectionsArr = [];
        if (response.data.length !== 0) {
            response.data.forEach((collection) => {
                if (collection.private === 0) {
                    console.log(collection)
                    collectionsArr.push(collection);
                }
            })
            setCollections(collectionsArr.reverse())
        }

    }, [setCollections])

    const handleClickToCollection = (collection_name)=>{
        navigate(`/gallery/${collection_name}`)
    }

    useEffect(() => {

        getCollection()

    }, [getCollection])

    let collectionsDisplay;
    if (collections.length !== 0) {
        collectionsDisplay = collections.map((collection, index) => {
            if (window.innerWidth >= 1201) {
                return (

                    <div key={index} className={styles.nftCard} >


                        <Tilt className={styles.TiltCard} options={{ scale: 10, glare: true }} style={{ backgroundColor: 'transparent' }}>
                            <div className={`${styles.card} ${styles.shadow}`} >

                                {collection.collection_name === 'Brusk For SOS MEDITERRANEE' ?
                                    <div className={`${styles.arribbon} ${styles.arribbonleft}`}>
                                        <div className={styles.arribboninner}>NFT SOLIDAIRE
                                        </div>
                                    </div>


                                    : ''}

                                <img className={styles.cardimg} src={collection.preview} />
                                <Link reloadDocument to={`/gallery/${collection.collection_name}`} state={{ data: collection.collectionName }}>
                                    <div className={styles.imageOverlay}>
                                        <h2 className={styles.h2Inside}>{collection.collection_name}</h2>
                                        <button className={styles.SeeMoreButton} to={`/gallery/${collection.collection_name}`} state={{ data: collection.collectionName }}>{t('Common.VoirPlus')}</button>

                                    </div>
                                </Link>
                                <ArtistCard artistId={`${collection.artist}`} />
                            </div>

                        </Tilt>
                    </div>


                )

            }
            else {
                return (
                    <div key={index} className={styles.nftCardMob} >



                        <div style={{cursor:'pointer'}} onClick={()=>{handleClickToCollection(collection.collection_name)}}>
                            <h2 className={styles.h2InsideMob}>{collection.collection_name}</h2>
                            <div className={`${styles.cardMob} ${styles.shadow} ${styles.Mob}`} >

                                <img className={styles.cardimgMob} src={collection.preview} />


                                <button className={styles.SeeMoreButton} to={`/galery/${collection.collection_name}`} state={{ data: collection.collectionName }}>{t('Common.VoirPlus')}</button>


                                <ArtistCard artistId={`${collection.artist}`} />
                            </div>
                        </div>
                    </div>
                )
            }
        }
        )
    }
    return (

        <>
            <Helmet>
                <title>NFSQUAT - GALLERY</title>
                <meta name="description" content="The NFSQUAT's Gallery Page. Here you can navigate accross our collections and talents !"></meta>
                <link rel='canonical' href='/gallery' />
            </Helmet>


            <Header />

            <Suspense fallback={<p>Loading...</p>}>
                <Banner content={t('Gallery.Banner')} content2={'NFT GALLERY FOR TALENT'}/>
                <div className={styles.container}>
                    {collectionsDisplay ? collectionsDisplay : "CHARGEMENT DE LA GALERIE"}

                </div>
            </Suspense>
            <FormContainer />
            <Footer />


        </>

    )





}


export default Galery;