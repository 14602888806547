import React, { useState } from 'react';
import styles from '../../styles/Form/Form.module.scss'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import EmailAPI from '../../APIs/EmailAPI';
function ContactUsForm(props) {
    const [formValue, setFormValue] = useState({
        lastname: '',
        firstname: '',
        email: '',
        question: ''
    })

    const [isSended, setIsSended] = useState(false)
    const [error, setError] = useState(false);


    const {t} = useTranslation()

    const handleChange = event => {
        const { name, value } = event.target
        setFormValue({ ...formValue, [name]: value })
    }

    const handleSubmit = async () => {
        if (formValue.email !== '' && formValue.question !== '') {

            await EmailAPI.post('/', formValue)
            .then((res) => {
                if(res.status === 200){
                    setIsSended(true)
                }
            })
                .catch((e) => {
                    if (e) {
                        console.log(e)
                        setError(true)
                    }
                })

        }
    }

    if (props.display === 'none') {
        return <></>
    } else {
        return (
                    <div className={styles.form}>
                        <div className={styles.formName}>
                            <input className={styles.formInput} type="text" name="lastname" placeholder={t('ContactForm.Nom')} onChange={handleChange} />
                            <input className={styles.formInput} type="text" name="firstname" placeholder={t('ContactForm.Prenom')} onChange={handleChange} />
                        </div>
                        <input className={styles.formInput} type="text" name="email" placeholder='Email' onChange={handleChange} />
                        <textarea rows={3} cols={30} className={styles.formInput} name="question" placeholder={t('ContactForm.Question')} onChange={handleChange} />

                        <button className={`${styles.submitButton} ${styles.type1}`} onClick={handleSubmit} >{t('ContactForm.Envoyer')}</button>
                        {isSended ? <p>{t('ContactForm.Success')}</p> : ''}
                        {error ?  <p>{t('ContactForm.Error')}</p> : ''}
                    </div>
        )
    }



}

export default ContactUsForm;