import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'

function Default404() {
  return (
    <>
    <Header/>
      <p>La page à laquelle vous essayer d'accéder n'existe pas....</p>
      <Link reloadDocument to='/'>Retourner à l'accueil</Link>
    </>
  )
}

export default Default404