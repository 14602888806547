import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../UI/Header';
import RulesMap from './RulesMap';
function LotteryRules() {

    const location = useLocation();
    const [collection, setCollection] = useState(undefined);
    const [componentToRender, setComponentToRender] = useState(undefined)
    const choices = [ 'PinkFlamingoRules', 'PierreTaisneSeries1Rules', 'StudioStoriesRules']
console.log(location)

    const loadComponent = (collectionName) =>{
        const componentName = `${collectionName.replaceAll(" ",'')}Rules`
        setComponentToRender(<RulesMap tag={componentName}/>)
    }

    useEffect(()=>{
        if(location.state !== null && location.state.hasOwnProperty('collection')){
            setCollection(location.state.collection)
            console.log(location.state)

        }else{
           setCollection(undefined)
        }
    }, [location])

    useEffect(()=>{
        if(typeof collection !== 'undefined'){
            loadComponent(collection)
        }
    },[collection])
    if(!collection){
    
        return (
            <>
                <Header />
                <div>Veuillez accéder à cette page depuis une collection pour charger son réglement</div>
            </>
        )
    }else{
        return (
            <>
                <Header />
                <RulesMap tag={collection.replaceAll(" ",'')}/>
            </>
        )
    }



}

export default LotteryRules