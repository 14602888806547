// @ts-nocheck
import React, { useCallback, useState, useEffect } from 'react'
import ProgramsAPI from '../../APIs/ProgramsAPI';
import stylesCollection from '../../styles/NFTS/Collection.module.scss'
import BuyButton from '../NFTs/BuyButton';
import { useTranslation } from 'react-i18next';
function ProgramsDisplay(props) {

  const [programs, setPrograms] = useState()
  const [userOwnTokens, setUserOwnTokens] = useState(false);
  const [tokenId, setTokenId] = useState();
  const [transactionStart, setTransactionStart] = useState(false)
  const { t } = useTranslation()
  let programsDisplay;
  const getPrograms = useCallback(async () => {
    const response = await ProgramsAPI.get(`/contract/${props.contract.contractId}/${props.token}`)
    if (response.data.length !== 0) {
      const programsArr = []
      response.data.forEach(async program => {
        programsArr.push(program)
      })
      setPrograms(programsArr)
    } else {
      setPrograms('Aucune program disponible')
    }

  }, [setPrograms, props.contract.contractId, props.token])

  useEffect(() => {
    getPrograms();
  }, [getPrograms, props])

  useEffect(() => {

    setUserOwnTokens(props.userOwnTokens)
    setTokenId(props.token)

  }, [props.userOwnTokens, props.token])

  const handleTransaction = (token) => {
    const arr = [0, 0, 0]
    arr[token] = arr[token] + 1
    setUserOwnTokens(arr)
    props.handleUserToken(arr)
  }
  if (typeof programs !== 'undefined' && typeof programs !== 'string') {
    programsDisplay = programs.map((program, index) => {
      if (props.displayStyle === 'large') {
        return (

          <li key={index}>{program.content}</li>

        )
      } else {
        return (
          <li key={index}>
            {program.content}
            {program.conditions !== '' ? <p>{program.conditions}</p> : ''}
          </li>

        )
      }

    })
  }

  console.log(props)
  if (props.lottery === 0) {



    return (
      <div className={stylesCollection.Infocard}>
        <div className={stylesCollection.Titreinfocard}>
          <p>{props.name}</p>
          <p>{props.contract.properties.supply}</p>
        </div>
        <div className={stylesCollection.Tableinfo}>

          <p>{t('Gallery.NormalBuy.ProgramText')}</p>
          <ul>
            {programsDisplay ? programsDisplay : ''}
          </ul>
        </div>
        <div className={stylesCollection.ButtonInfoCard}>
          <BuyButton userOwnTokens={props.userOwnTokens} handleTransaction={handleTransaction} contract={props.contract} />

        </div>
      </div>
    )
  } else if (props.lottery === 1) {
    return (
      <div className={stylesCollection.Infocard}>
        <div className={stylesCollection.Titreinfocard}>
          <p>{props.name}</p>
          <p>{t('Programs.Supply', {count : props.contract.properties.supply})} </p>
          </div>
        <div className={stylesCollection.Tableinfo}>

          <ul>
            {programsDisplay ? programsDisplay : ''}
          </ul>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <p>Le programme de ce NFT n'est pas encore disponible</p>
      </>
    )
  }

}

export default ProgramsDisplay