import React, { useEffect, useState } from 'react'
import styles from '../../styles/FAQ/FAQ.module.scss'
import { AiFillPlusSquare } from 'react-icons/ai'
import { id } from 'ethers/lib/utils'
import { useTranslation } from 'react-i18next'

function FAQ() {

    const [activeId, setActiveId] = useState({ activeId: null })
    const {t, i18n} = useTranslation()

    const questions = [
        { id: 0, content: t('FAQ.Questions.1') },
        { id: 1, content: t('FAQ.Questions.2') },
        { id: 2, content: t('FAQ.Questions.3') },
        { id: 3, content: t('FAQ.Questions.4') },
    ]
    const anwser =
        [t('FAQ.Reponses.1'),t('FAQ.Reponses.2'),t('FAQ.Reponses.3'),t('FAQ.Reponses.4')        ];

    const handleClick = (event) => {
        let parent;
        let questionId;

        if (event.target.nodeName === 'path') {
            parent = event.target.parentElement.parentElement.parentElement;
            questionId = parent.dataset.qId
        } else if (event.target.nodeName === 'svg') {
            parent = event.target.parentElement.parentElement;
            questionId = parent.dataset.qId
        }else if (event.target.nodeName === 'p') {
            parent = event.target.parentElement.parentElement;
            questionId = parent.dataset.qId
        }  
        else {
            questionId = event.target.parentElement.dataset.qId
        }

        if (activeId.activeId === null || activeId.activeId !== questionId) {
            setActiveId({ activeId: questionId })
        } else {
            setActiveId({ activeId: null })

        }

    }
    return (
        <div className={styles.faqContainer}>
            {questions.map((question) => {
                const active = activeId.activeId
                return (
                    <div className={styles.faqQuestion} data-q-id={question.id} key={question.id} onClick={handleClick}>
                        <div className={styles.questionTitle}>
                            <p >{question.content}</p> 
                            <AiFillPlusSquare />
                        </div>
                        <div className={active !== null && Number(active) === question.id ? styles.contentShowed : styles.contentHided}>
                            <p>{anwser[question.id]}</p>
                        </div>
                    </div>
                )
            })}

        </div>
    )
}

export default FAQ