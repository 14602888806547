// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Link,useLoaderData} from 'react-router-dom';
import Header from '../../UI/Header';
import axios from "axios"
import CollectionAPI from '../../../APIs/CollectionAPI';


function AdminCollectionsList(props){

    const [collections, setCollections] = useState()
    const currentUser = useLoaderData();


    const getCollection = useCallback(async () => {
        const response = await CollectionAPI.get('/')
        
        if(response.data.length !== 0){
            const collectionsArr = []
            response.data.forEach(async collection => {
                console.log(collection)
                collectionsArr.push(collection)
            })
            setCollections(collectionsArr)
        }else{
            setCollections('Aucune collection disponible')
        }

    },[setCollections])

    useEffect(()=>{
        getCollection();
    },[getCollection])

    let collectionsDisplay;
    console.log(collections)
    if (currentUser !== undefined&&currentUser.role === 'Administrator') {
    if(typeof collections !== 'undefined' && typeof collections !=='string'){
        console.log(typeof collections)
        collectionsDisplay = collections.map((collection, index) => {
            console.log(collections)
            return (
                <div key={index}>
                <h1>{collection.collection_name}</h1>
                <p>Nombre de contract associés : {collection.nb_contract}</p>
                <Link to={`/admin/collections/${collection.collection_name}`}>
                    <button>+ de détails</button>
                </Link>
                </div>
            )
        })
    }
    return (
           <React.Fragment>
            <Header/>
            <h1>Les collections NFSquat</h1>
            {collections !== undefined ? collectionsDisplay : 'Aucune collection'}
            <Link to='/admin/collections/create'>
                <button>Créer une collection</button>
            </Link>
           </React.Fragment>
    )
    }else{
        return (<></>)
    }
}

export default AdminCollectionsList;