const particlesBanner = {
  "fpsLimit": 165,
  "fullScreen": {
    "enable": false,
    "zIndex": -1
  },
  "particles": {
    "number": {
      "value": 90,
      "density": {
        "enable": true,
        "value_area": 500
      }
    },
    "color": {
      "value": "#ff0000",
      "animation": {
        "enable": true,
        "speed": 100,
        "sync": true
      }
    },
    "shape": {
      "type": "circle"
    },
    "opacity": {
      "value": 0.5,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 1,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 10,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 100,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1,
      "triangles": {
        "enable": true,
        "color": "#ffffff",
        "opacity": 0.05
      }
    },
    "move": {
      "enable": true,
      "speed": 1,
      "direction": "none",
      "random": false,
      "straight": false,
      "out_mode": "out",
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "events": {
      "onhover": {
        "enable": true,
        "mode": "grab"
      },
      "onclick": {
        "enable": false,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 200,
        "line_linked": {
          "opacity": 0.5
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 0.8
      },
      "repulse": {
        "distance": 200
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true,
  "background": {
    "color": "#181818",
    "image": "",
    "repeat": "no-repeat",
    "size": "cover"
  }
}
  export default  particlesBanner