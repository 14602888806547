// @ts-nocheck
import axios from 'axios';
import React, { useEffect, useCallback, useState } from 'react'
import Header from '../UI/Header'
import styles from '../../styles/Artists/ArtistCard.module.scss'
import { Link, useNavigate } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'
import { FaUser } from 'react-icons/fa'
import ArtistAPI from '../../APIs/ArtistAPI';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Navigation } from 'swiper';
function ArtistCard(props) {


    const [artist, setArtist] = useState({});
    const fetchArtist = useCallback(async () => {

        if (props.artistId !== '') {
            const response = await ArtistAPI.get(`/${props.artistId}`);
            if (response.data.length !== 0)
                setArtist({
                    firstname: response.data[0].firstname,
                    lastname: response.data[0].lastname,
                    pseudo: response.data[0].pseudo,
                    slug: response.data[0].slug,
                    image: response.data[0].image,
                    instagram: response.data[0].instagram,
                    twitter: response.data[0].twitter,
                    artistId: response.data[0].id

                })
        }
    }, [artist.firstname, artist.lastname, artist.pseudo, artist.slug, artist.image, artist.instagram, artist.twitter, artist.artistId, props.artistId])

    useEffect(() => {
        fetchArtist()
    }, [fetchArtist])

    if (artist !== null) {
        return (
            <div className={props.display === 'large' ? styles.artistsCardLarge : styles.artistsCard}>
                <Link reloadDocument to={'/talents/' + artist.artistId}>
                    <div className={styles.artistInformations}>
                        <div className={styles.minicardImgArtist}>{artist.image !== '' ? <img src={process.env.PUBLIC_URL + artist.image} alt='' /> : <FaUser />} <span className={styles.InfoSpan}>{artist.firstname} {artist.lastname}  {artist.pseudo !== '' ? <p><span className={styles.PseudoArtist}>{artist.pseudo}</span></p> : '' }</span></div>
                    </div>
                </Link>
            </div>
        )
    } else {
        <React.Fragment></React.Fragment>
    }

}



export default ArtistCard