import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async'
import CustomRouter from './Components/Router/CustomRouter';
import LoaderSpinner from './Components/Loading/LoaderSpinner';
import './i18n'
require('dotenv').config({ path: `../.env` })
const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

root.render(
 
  <React.StrictMode>
     <Suspense fallback={<LoaderSpinner width={'10em'} height={'10em'}/>}>
    <HelmetProvider>
      <CustomRouter />
    </HelmetProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
