import React from 'react'
import Header from '../../UI/Header'
import styles from '../../../styles/CG/CG.module.scss'
import FormContainer from '../../Form/FormContainer'
import Footer from '../../UI/Footer'
function BrunoVerjusRules() {
    const date = '10/03/2023 à 09h00 au 31/04/2023 00h00'
    const title = "Bruno Verjus – Homard façon Table"
    const quantity = '1101 (mille-cent un)'
    return (
        <>
            <section className={styles.container}>

                <h1>RÈGLEMENT TIRAGE AU SORT – DROP BRUNO VERJUS - Homard Façon Table</h1>
                <section className={styles.content}>
                    <h2>ARTICLE 1 – ORGANISATEUR </h2>
                    <p>La société S2R STARBRAND, société par actions simplifiée au capital social de 1 500,00 €, dont le siège social est situé au 9, rue du Lavoir Philibert à Domont (95330) immatriculée au Registre du Commerce et des Sociétés de Pontoise sous le numéro 503 742 611 (ci-après l’« Organisateur ») organise un tirage au sort avec obligation d’achat du {date} (ci-après la « Durée ») accessible uniquement sur le site Internet exploité par l’Organisateur en suivant le lien ci-après www.nfsquat.com  (ci-après le « Tirage »). </p>
                    <p>Le présent règlement a pour objet de déterminer les conditions et modalités de participation au Tirage (ci-après le « Règlement »).</p>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 2 – OBJET – DEFINITIONS </h2>
                    <p>L’Organisateur exploite le site Internet www.nfsquat.com (le « Site Internet » ou la « Marketplace »), et commercialise des œuvres d’art sous la forme de NFT liés à des programmes expérientiels ayant pour objectif de promouvoir l’art et les talents sous toutes leurs formes et dans tous les univers ( musique, danse, arts graphique, arts plastiques, sports, arts culinaires, explorateurs, photographes, etc ).</p>
                    <p>Pour les besoins de la promotion de la collection NFT intitulée « {title} » commercialisée sur la Marketplace et dans le cadre de la promotion du lancement de sa Marketplace, l’Organisateur a souhaité commercialiser une édition spéciale de NFT permettant de participer au Tirage selon les conditions et modalités prévues au présent Règlement. </p>
                    <p>A toutes fins utiles, il est convenu que les termes et expressions ci-après définis auront, dans le cadre du présent Règlement, la signification qui suit :</p>
                    <p>« Blockchain » : Désigne une technologie utilisant le stockage et la transmission de données, telle que la Blockchain Ethereum, permettant aux utilisateurs de participer à des transactions sécurisées avec des Cryptomonnaies, et des NFTs qui sont enregistrés de manière permanente sur ladite Blockchain</p>
                    <p>« Cryptomonnaie » : Désigne une monnaie numérique, telle que les ETH, utilisée par le biais d'un réseau informatique décentralisé et permettant aux utilisateurs d'effectuer des transactions enregistrées sur un système de Blockchain.</p>
                    <p>« NFT » : Désigne les jetons uniques non fongibles qui sont enregistrés de manière permanente dans un système de Blockchain permettant d’identifier et certifier un sous-jacent ou de donner accès à des récompenses et droits particuliers.</p>
                    <p>« Smart Contract » : Désigne les données et l'algorithme cryptés dans la Blockchain et permettant l’exécution automatique de transactions de NFT.</p>
                    <p>« Portefeuille Cryptographique » : Désigne un logiciel ou protocole d’authentification numérique accessible par l'intermédiaire d'un fournisseur de services de crypto-monnaies tiers dédié permettant l’identification des utilisateurs et de réaliser des transactions en Cryptomonnaie enregistrées sur une blockchain.</p>
                    <p>« Utilities » : Désigne les récompenses, droits ou accès de toute de nature associés le cas échéant à un NFT et exclusivement accessibles par le propriétaire du NFT correspondant. </p>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 2 – CONDITIONS ET MODALITES DE PARTICIPATION AU TIRAGE</h2>
                    <p>2.1. Le Tirage est ouvert à toute personne physique majeure à la date de participation, résidant en France métropolitaine, et à l’exclusion de toutes les personnes ayant directement ou indirectement participé à l’élaboration du Tirage et des membres du personnel de l’Organisateur (ci-après le ou les « Participants »).</p>
                    <ul>2.2. Pour participer au Tirage, le Participant doit :
                        <li>Se rendre sur le site Internet www.nfsquat.com, le Tirage se déroulant exclusivement sur Internet ;</li>
                        <li>Acheter au minimum 1 (un) NFT intitulé Stéphane de Bourgies – Studio Stories parmi les {quantity} exemplaires disponibles à l’achat pendant la Durée</li>
                        <li>Avoir accepté sans restriction ni réserve le Règlement.</li>
                    </ul>
                    <p>2.3. Dans l’hypothèse où les {quantity} exemplaires de NFT disponibles seraient vendus et écoulés avant l’expiration de la Durée, il ne sera plus possible de participer au Tirage. </p>
                    <p>2.4. La participation au Tirage est strictement personnelle et nominative. La participation au Tirage est limitée à une seule participation par personne (même compte effectuant l’achat, même Portefeuille Cryptographique, même nom, même prénom et même adresse e-mail). </p>
                    <p>2.5. Le non-respect des conditions de participation énoncées dans le présent Règlement est susceptible d’entraîner la nullité de la participation du Participant.</p>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 3 – DOTATIONS </h2>
                    <p>En participant au Tirage, chaque Participant est susceptible de remporter l’une (1) des 201 (deux-cent-une) dotations appartenant aux trois (3) catégories de lot décrites ci-après : </p>
                    <ul>Lot 1 :
                        <li>1 (un) exemplaire NFT UNIQUE, comportant au choix :</li>
                        <li>Une invitation pour 2 personnes à diner au restaurant TABLE à Paris*, reçus par le Chef qui vous accueillera personnellement</li>
                        <li>+ accès à l’ensemble des Utilities associées au NFT RARE du Lot n°2. </li>
                        <li>+ accès en avant-première à la prochaine vente des NFT du talent sur NFSQUAT dont l’œuvre est représentée par le NFT UNIQUE, </li>
                        <li>+ accès en avant-première à la prochaine vente des NFT UNIQUES de toute la galerie NFSQUAT.</li>
                        <li>La valorisation unitaire du Lot 1 est de 3 000 € ( trois-mille euros ) hors-taxes à la date du Tirage.</li>
                        <li>*hors transport, hôtel et restaurant - usage sous délais de 36 mois</li>
                    </ul>
                    <ul>Lot 2 :
                        <li>100 (cent) exemplaires de NFT RARE, chaque exemplaire du Lot 2 comportant un (1) NFT modèle SUPER RARE, avec les Utilities suivantes :</li>
                        <li>Accès à une master-class digitale interactive de 60 min avec Bruno VERJUS</li>
                        <li>+ accès à l’ensemble des Utilities associés au NFT LIMITED du Lot 3.</li>
                        <li>+ accès prioritaire à la prochaine vente des NFT du talent sur NFSQUAT dont l’œuvre est représentée par le NFT RARE</li>
                        <li>+ accès prioritaire à la prochaine vente des NFT RARE de toute la galerie NFSQUAT.</li>
                        <li>La valorisation unitaire d’un exemplaire du Lot 2 est de 480 € ( quatre-cent-quatre-vingt euros ) hors-taxes à la date du Tirage.</li>
                    </ul>
                    <ul>Lot 3 :
                        <li>1000 (mille) exemplaires de NFT LIMITED, chaque exemplaire du Lot 3 comportant un (1) NFT du modèle LIMITED avec les Utilities suivantes :</li>
                        <li>Accès à la recette du « homard façon TABLE », en format vidéo enregistré dans la cuisine du restaurant table</li>
                        <li>+ accès prioritaire à la prochaine vente des NFT du talent sur NFSQUAT dont l’œuvre est représentée par le NFT LIMITED</li>
                        <li>+ accès prioritaire à la prochaine vente des NFT LIMITED de toute la galerie NFSQUAT.</li>
                        <li>La valorisation unitaire d’un exemplaire du Lot 4 est de 200 € ( deux-cent euros ) hors-taxes à la date du Tirage.</li>
                    </ul>
                    <ul>Lot 4 :
                        <li>1000 (mille) exemplaires de NFT LIMITED, chaque exemplaire du Lot 4 comportant un (1) NFT du modèle LIMITED avec les Utilities suivantes :</li>
                        <li>Accès au podcast "Studio Stories" : les histoires des shooting des 4 photos contenues dans la collection de NFT, en format audio, enregistré dans le studio de Stéphane de Bourgies.</li>
                        <li>+ accès prioritaire à la prochaine vente des NFT du talent sur NFSQUAT dont l’œuvre est représentée par le NFT LIMITED</li>
                        <li>+ accès prioritaire à la prochaine vente des NFT LIMITED de toute la galerie NFSQUAT.</li>
                        <li>La valorisation unitaire d’un exemplaire du Lot 4 est de 200 € ( deux-cent euros ) hors-taxes à la date du Tirage.</li>
                    </ul>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 4 – MODALITÉS DE TIRAGE AU SORT ET DE DESIGNATION DES GAGNANTS </h2>
                    <p>Les exemplaires de chaque lot seront attribués de manière aléatoire aux Participants par tirage au sort. Chaque Participant ne peut recevoir qu’un seul exemplaire d’un des lots détaillés ci-avant. </p>
                    <p>Le tirage au sort des gagnants des différents lots parmi les Participants aura lieu dans un délai maximum de 10 (dix) jours ouvrés suivants l’expiration de la Durée (ci-après les « Gagnants »). </p>
                    <p>Les Gagnants seront informés des caractéristiques du lot remporté, dans un délai maximum de 10 (dix) jours ouvrés à compter de la date du Tirage, par e-mail et/ou par SMS le cas échéant selon les informations communiquées sur le Site Internet lors de l’achat du NFT permettant de participer au Tirage. </p>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 5 – MODALITÉS DE REMISE DES DOTATION</h2>
                    <p>Le Gagnant du Lot 1 sera contacté directement par l’Organisateur, à l’adresse e-mail renseignée lors de la participation au Tirage, afin qu’il puisse récupérer sa dotation.</p>
                    <p>Les Gagnants du Lot 2 seront contactés directement par l’Organisateur, à l’adresse e-mail renseignée lors de la participation au Tirage, afin qu’ils puissent récupérer la dotation </p>
                    <p>Les Gagnants du Lot 3 seront contactés directement par l’Organisateur à leur adresse e-mail renseignée lors de la participation au Tirage s’agissant des modalités d'activation à leur accès à la rencontre digitale interactive </p>
                    <p>La réception des Dotations par les Gagnants nécessite que le Portefeuille Cryptographique utilisé lors de la participation au Tirage soit actif, dans le cas contraire la Dotation ne pourra pas être attribuée ce que les Participants reconnaissent et déclarent expressément accepter.</p>
                    <p>Les Dotations ne peuvent être reprises, échangées ou faire l’objet d’une contre-valeur en espèce ou en Cryptomonnaies.</p>
                    <p>Les Dotations n’incluent pas les éventuels frais de transport, d’hébergement ou tout autre dépense personnelle en vue de bénéficier de la Dotation remportée, lesquels demeurant à la charge unique et exclusive du Gagnant</p>
                    <p>Le Dotations sont à à utiliser selon les modalités et conditions communiquées ultérieurement aux Gagnants. </p>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 6 – RESPONSABILITE </h2>
                    <p>La responsabilité de l’Organisateur ne saurait être recherchée dans l’hypothèse d’un cas de force majeure ou d’une cause étrangère indépendant de sa volonté affectant le bon déroulement du Tirage. Dans ce cas l’Organisateur pourra à sa discrétion annuler, modifier, écourter, prolonger le Tirage ou remplacer un des lot par un lot d’une même valeur.</p>

                    <p>L’Organisateur ne saurait voir sa responsabilité engagée du fait de l’impossibilité de contacter un ou plusieurs Gagnants suite au Tirage, de même qu’en cas de perte, de vol ou de dégradation des lot lors de leur acheminement le cas échéant.</p>
                    <p>L’Organisateur ne pourra pas non plus être responsable des erreurs éventuelles portant sur les informations personnelles communiquées par les Participants (prénom, nom, adresse e-mail, numéro de téléphone, adresser du Portefeuille Cryptographique).</p>
                    <p>L’Organisateur décline en outre toute responsabilité en cas de non-utilisation d’un lot par un Gagnant, aucun remboursement ne sera dû dans cette hypothèse.</p>
                    <p>L’Organisateur se dégage de toute responsabilité en cas de dysfonctionnement empêchant l’accès et/ ou le bon déroulement du Tirage notamment dû à des actes de malveillances externes. L’utilisation de robots ou de tous autres procédés similaires permettant de participer au Tirage de façon mécanique est proscrite, la violation de cette règle entraînant l’élimination définitive du Participant. </p>
                    <p>L’Organisateur pourra annuler tout ou partie du Tirage s’il apparaît que des fraudes sont intervenues sous quelque forme que ce soit, notamment de manière informatique dans le cadre de la participation au Tirage, tentative d’augmenter ses chances de gains par fraude, par quelque moyen que ce soit ou de la détermination des Gagnants. Il se réserve, dans cette hypothèse, le droit de ne pas attribuer les Lots aux fraudeurs et/ ou de poursuivre devant les juridictions compétentes les auteurs de ces fraudes.</p>
                    <p>Les Gagnants sont seuls responsables des formalités nécessaires à la réception des lots et aux conditions spécifiques de leur utilisation le cas échéant. L’Organisateur ne serait être responsable ou tenu de remplacer et/ou compenser un lot dans l’éventualité ou les Gagnants ne respecteraient pas ces conditions et se trouveraient dans l’impossibilité de bénéficier du lot gagné.</p>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 7 – DÉCLARATION NFT ET CRYPTOMONNAIES </h2>
                    <p>Les Participants se déclarent être informés des spécifiques et risques inhérents au marché des cryptomonnaies, ils reconnaissent notamment que : .</p>
                    <ul>
                        <li>La valeur des NFT et des Dotations est susceptibles d’évoluer postérieurement à l’expiration de la Durée et qu’elle est sujette à des fluctuations et des dépréciations non maitrisables par l’Organisateur résultant en partie de la loi de l’offre et de la demande ;</li>
                        <li>Les crypto-monnaies et les NFT sont associés à une forte volatilité et une fluctuation des prix ; </li>
                        <li>Les NFT ne sont pas à ce jour soumis à une réglementation spécifique et que les futures lois, réglementations ou décisions de justice, ainsi que toute nouvelle obligation fiscale, peuvent affecter de manière significative la possibilité de vendre ou d'acheter des NFT ainsi que les produits associés à ces opérations ; </li>
                        <li>Que des retards dans les transactions peuvent survenir compte tenu du mode de fonctionnement de la Blockchain ; </li>
                        <li>Que la blockchain présente un caractère immuable et que les NFT ne pourront en aucun cas être annulés ou retirés du marché compte tenu du caractère définitif de l’opération de créations desdits NFT et des transactions subséquentes éventuelles.</li>
                    </ul>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 8 – UTILISATION DES DONNÉES PERSONNELLES DES PARTICIPANTS  </h2>
                    <p>8.1. Pour pouvoir participer au Tirage, le Participant communique son nom, son prénom, son adresse e-mail et son numéro de téléphone à des fins de suivi du Tirage et de contact en cas de tirage au sort du Participant comme Gagnant. </p>
                    <p>Ces données sont également collectées à des fins promotionnelles, notamment dans le but d’adresser les offres commerciales de l’Organisateur ce que le Participant reconnaît et accepte expressément.</p>
                    <p>8.2. Conformément aux dispositions applicables du règlement (UE) 2016/679 du Parlement européen et du conseil du 27 avril 2016 sur la protection des personnes physiques à l'égard du traitement des données personnelles et sur la libre circulation de ces données (ci-après le « RGPD ») et de la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, chaque Participant bénéficie auprès de l’Organisateur : </p>
                    <ul>
                        <li>du droit d’accéder et de recevoir une copie des données personnelles le concernant et dont dispose l’Organisateur ; </li>
                        <li>du droit d’accéder à ses données personnelles afin de rectifier, modifier ou effacer toute erreur ou information incorrecte ; </li>
                        <li>de la possibilité de solliciter la suppression et l’effacement de l’ensemble de ses données dont dispose l’Organisateur ; </li>
                        <li>du droit de s’opposer à tout moment au traitement de ses données personnelles à des fins de marketing ou aux traitements effectués ; </li>
                        <li>du droit de demander la suspension du traitement effectué de ses données personnelles ; </li>
                        <li>du droit à la portabilité, c’est à dire du droit de recevoir les données que l’Organisateur possède dans un format structuré couramment utilisé et lisible et le droit de transmettre ces données à un autre responsable de traitement ; </li>
                        <li>du droit de définir des directives relatives au sort de ses données personnelles après son décès. </li>
                    </ul>
                    <p>L’ensemble de ces droits peuvent être exercés sur simple demande écrite du Participant, accompagnée d’une copie d’un justificatif d’identité en cours de validité et signé, adressée par courrier électronique à l’adresse e-mail suivante : contact@nfsquat.com</p>
                    <p>Afin que l’Organisateur puisse répondre dans un délai raisonnable, la demande doit être claire, précise et justifiée. L’Organisateur se réserve le droit de ne pas répondre à toute demande ne remplissant pas ces critères. </p>
                    <p>Chaque Participant dispose également du droit de contacter et d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (https://www.cnil.fr) et du droit d’obtenir réparation auprès des tribunaux compétents.</p>
                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 9 – ACCESSIBILITÉ DU RÈGLEMENT </h2>

                    <p>Le présent Règlement peut être consulté librement pendant la durée du Tirage visée à l’article 1 sur le Site Internet de l’Organisateur, accessible à l’adresse URL suivante : www.nfsquat.com.</p>

                </section>
                <section className={styles.content}>
                    <h2>ARTICLE 10 – LOI APPLICABLE ET DIFFÉREND </h2>
                    <p>Le présent règlement est régi par la loi française. </p>
                    <p>Les Parties feront leurs meilleurs efforts pour parvenir à un règlement amiable d’un éventuel litige survenant entre elles</p>
                
                
                </section>



            </section>

            <FormContainer />
            <Footer />
        </>
    )
}

export default BrunoVerjusRules