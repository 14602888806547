// @ts-nocheck
import React, { useCallback, useState, useEffect } from 'react'
import ContractAPI from '../../../APIs/ContractAPI';
import ProgramsAPI from '../../../APIs/ProgramsAPI';
import Footer from '../../UI/Footer'
import Header from '../../UI/Header'
import { useLoaderData } from 'react-router-dom'

function AdminCreateProgram() {
    const [program, setProgram] = useState({ content: '', conditions: '', contract: '', tokenId: '' });
    const currentUser = useLoaderData();

    const [contractList, setContractList] = useState();
    let contractDisplay;

    const getContractList = useCallback(
        async () => {
            const contractsArr = [];
            const response = await ContractAPI.get('/')
            response.data.forEach(contract => {
                contractsArr.push(contract)
            })
            setContractList(contractsArr)

        },
        [setContractList],
    )


    const handleChange = event => {
        const { name, value } = event.target
        if (name === 'contract') {
            setProgram({ ...program, [name]: event.target.selectedOptions[0].dataset.id })
        } else {
            setProgram({ ...program, [name]: value })
        }
    }

    const createProgram = async () => {
        const payload = {
            contract: program.contract,
            content: program.content,
            conditions: program.conditions,
            tokenId: program.tokenId,

        }

        await ProgramsAPI.post('/create', payload)
        window.location.replace(`${window.location.origin}/admin/programs`)
    }

    useEffect(() => {
        getContractList();
    }, [getContractList])
    if (typeof currentUser !== 'undefined') {
        if (currentUser.role  && currentUser.role === 'Administrator') {
            if (contractList) {
                contractDisplay = contractList.map((contract, key) => {
                    return (
                        <option data-id={contract.id_contracts} key={key} value={contract.id}>
                            {contract.name}
                        </option>
                    )
                })

            }

            return (
                <React.Fragment>
                    <Header />
                    <textarea onChange={handleChange} name='content' type="text" placeholder='Contenu du programme'></textarea>
                    <input onChange={handleChange} name='conditions' type="text" placeholder='Conditions du programme'></input>
                    <input onChange={handleChange} name='tokenId' type="number" placeholder='Token Id'></input>
                    <div >
                        <label htmlFor="contract">Choisissez un artiste:</label>
                        <select onChange={handleChange} name="contract" id="contract">
                            <option>Choissiez un contrat</option>
                            {contractDisplay ? contractDisplay : 'Aucun Artist'}
                        </select>
                        <button onClick={createProgram}>Créer le programme</button>

                    </div>

                    <Footer />
                </React.Fragment>
            )
        }
    } else {
        return <></>
    }
}

export default AdminCreateProgram