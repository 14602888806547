import React, { useState, useEffect, useContext } from 'react'
import { ethers } from 'ethers';
import ReceiptAPI from '../../APIs/ReceiptAPI';
import UserAPI from '../../APIs/UserAPI';
import * as ContractFunctions from '../../Functions/ContractFunctions'
import { CirclesWithBar } from 'react-loader-spinner';
import { FiCheckCircle } from 'react-icons/fi'
import { AiFillCloseCircle } from 'react-icons/ai'
import { IoMdCloseCircle } from 'react-icons/io'
import { useLoaderData, useNavigate } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import styles from '../../styles/NFTS/BuyButton.module.scss'
import stylesPopup from '../../styles/TransactionPopup/TransactionPopup.module.scss'
import { ProviderContext, UserContext } from '../Context/Context';
import { useTranslation } from 'react-i18next';
function BuyButton(props) {
  const [transactionReceipt, setTransactionReceipt] = useState();
  const [transactionIsLoading, setTransactionIsLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [currentContract, setCurrentContract] = useState();
  const [showPopup, setShowPopup] = useState(false)
  const [showEmailStep, setShowEmailStep] = useState(false)
  const [userOwnTokens, setUserOwnTokens] = useState([])
  const navigate = useNavigate();

  const [user] = useContext(UserContext)
  const [provider] = useContext(ProviderContext)
  const { t } = useTranslation();


  console.log(user)
  const mint = async (tokenId, contractName, contractAddress, collectionId) => {

    if (currentContract && user && user.hasOwnProperty("wallet") && provider.hasOwnProperty("_isProvider")) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        if (accounts[0] !== '') {
          setTransactionIsLoading(true)
          setTransactionStatus('waiting')
          const ABI = await ContractFunctions.loadAbiContract(contractName, collectionId)
          const signer = provider.getSigner()
          const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
          const cost = await contract.prices(tokenId)
          const maxSupply = await contract.supplies(tokenId);
          const totalSupply = await contract.totalSupply(tokenId);
          const gasPrice = await signer.getGasPrice();
          let overrides = {
            value: cost,
            gasPrice: gasPrice,
            gasLimit: 1000000
          }
          if ((Number(totalSupply) < Number(maxSupply))) {
            const transaction = await contract.mint(tokenId, overrides)

            if (transaction !== null) {
              setTransactionStatus('user confirm')
              const txHash = await transaction.wait()
              if (txHash !== null) {
                setTransactionReceipt(txHash)

                const payload = {
                  contract: currentContract.contractId,
                  token: currentContract.tokenId,
                  txHash: txHash.transactionHash,
                  from: txHash.from,
                  to: txHash.to,
                  gasUsed: Number(txHash.gasUsed),
                  gasPrice: Number(gasPrice),
                  price: Number(cost),
                  timestamp: txHash.timestamp
                }
                await ReceiptAPI.post("/create", payload)
                setTransactionStatus('success')
                props.handleTransaction(tokenId)
                setTransactionIsLoading(false)
              }
            }
          }
        }
      } catch (err) {
        if (err.code === 'ACTION_REJECTED') {

          setTransactionStatus('user reject')
        } else {
          setTransactionStatus('failed')
        }
        setTransactionIsLoading(false)
        setShowPopup(false)

        console.error(err)

      }

    } else {
      throw Error('Veuillez vous connecter')
    }


  }

  const mintWithMagic = async (tokenId, contractName, contractAddress, collectionId) => {

    if (currentContract && user && user.hasOwnProperty("wallet") && provider.hasOwnProperty("_isProvider")) {
      try {

        setTransactionIsLoading(true)
        setTransactionStatus('waiting')
        const ABI = await ContractFunctions.loadAbiContract(contractName, collectionId)
        const signer = provider.getSigner()
        console.log(signer)
        const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
        const cost = await contract.prices(tokenId)
        const maxSupply = await contract.supplies(tokenId);
        const totalSupply = await contract.totalSupply(tokenId);
        const gasPrice = await signer.getGasPrice();
        let overrides = {
          value: cost,
          gasPrice: gasPrice,
          gasLimit: 1000000
        }
        if ((Number(totalSupply) < Number(maxSupply))) {
          const transaction = await contract.mint(tokenId, overrides)

          if (transaction !== null) {
            setTransactionStatus('user confirm')
            const txHash = await transaction.wait()
            if (txHash !== null) {
              setTransactionReceipt(txHash)

              const payload = {
                contract: currentContract.contractId,
                token: currentContract.tokenId,
                txHash: txHash.transactionHash,
                from: txHash.from,
                to: txHash.to,
                gasUsed: Number(txHash.gasUsed),
                gasPrice: Number(gasPrice),
                price: Number(cost),
                timestamp: txHash.timestamp
              }
              await ReceiptAPI.post("/create", payload)
              setTransactionStatus('success')
              props.handleTransaction(tokenId)
              setTransactionIsLoading(false)
            }
          }
        }

      } catch (err) {
        if (err.code === 'ACTION_REJECTED') {

          setTransactionStatus('user reject')
        } else {
          setTransactionStatus('failed')
        }
        setTransactionIsLoading(false)
        setShowPopup(false)

        console.error(err)

      }

    } else {
      throw Error('Veuillez vous connecter')
    }


  }

  const handleMint = () => {
    if (user && user.hasOwnProperty('idusers')) {
      if (currentContract && userOwnTokens[currentContract.tokenId] === 1) {
        setTransactionStatus('success')
        setShowPopup(true)
      } else {
        if (user.email === '' && transactionStatus !== 'user reject') {
          setShowEmailStep(true)
          setShowPopup(false)
        } else {
          setShowPopup(true)
          if (transactionStatus !== 'waiting' && transactionStatus !== 'user confirm' && transactionStatus !== 'success' && currentContract) {
            mint(currentContract.tokenId, currentContract.contractName, currentContract.contractAddress, currentContract.collectionId)
          }
        }

      }

    }

  }

  const handleMagicMint = () => {
    if (user && user.hasOwnProperty('idusers')) {
      if (currentContract && userOwnTokens[currentContract.tokenId] === 1) {
        setTransactionStatus('success')
        setShowPopup(true)
      } else {
        if (user.email === '' && transactionStatus !== 'user reject') {
          setShowEmailStep(true)
          setShowPopup(false)
        } else {
          setShowPopup(true)
          if (transactionStatus !== 'waiting' && transactionStatus !== 'user confirm' && transactionStatus !== 'success' && currentContract) {
            mintWithMagic(currentContract.tokenId, currentContract.contractName, currentContract.contractAddress, currentContract.collectionId)
          }
        }

      }

    }

  }

  const handleNavigate = () => {
    navigate('/profile')

  }

  const handleUpdateEmail = async (event) => {
    if (user && user.hasOwnProperty('idusers')) {
      let parentDiv;
      if (event.target.nodeName === 'svg') {
        parentDiv = event.target.parentElement
      } else {
        parentDiv = event.target.parentElement.parentElement
      }
      const input = parentDiv.children[0]
      if (input.value) {
        const email = input.value
        const wallet = user.wallet
        const payload = {
          username: user.username,
          lastname: user.lastname,
          firstname: user.firstname,
          email,
          phone: user.phone,
          wallet

        }
        const response = await UserAPI.put(`update/${wallet}`, payload)
        if (response.status === 200) {
          setShowPopup(true)
          setShowEmailStep(false)
          if (transactionStatus !== 'waiting' && transactionStatus !== 'user confirm' && transactionStatus !== 'success' && currentContract) {
            mint(currentContract.tokenId, currentContract.contractName, currentContract.contractAddress, currentContract.collectionId)
          }
        }
      } else {
        console.log("veuillez enter une value")
      }
    }
  }

  useEffect(() => {
    setCurrentContract(props.contract)
    setUserOwnTokens(props.userOwnTokens)
    const found = props.userOwnTokens.find(element => element === 1);
    if (found === 1) {
      setTransactionStatus('success')
    }
  }, [props.userOwnTokens])

  console.log(currentContract)
  return (
    <>
      {
        showEmailStep ?
          <div className={stylesPopup.popupDisplayed}>
            <AiFillCloseCircle className={stylesPopup.closeBtn} onClick={() => setShowEmailStep(false)} />

            <div className={stylesPopup.emailPopup}>
              <div className={stylesPopup.emailContent}>
                <div className={stylesPopup.emailInput}>
                  <input type={"email"} placeholder={"Entrer votre email"}></input><BsArrowRightCircleFill onClick={handleUpdateEmail} />
                </div>

                <p>(vous aurez toujours la posibilité de mettre votre adresse mail à jour depuis votre profile)</p>
              </div>

            </div>
          </div>
          : ''
      }

      {showPopup ?
        <div className={stylesPopup.popupDisplayed}>
          <AiFillCloseCircle className={stylesPopup.closeBtn} onClick={() => setShowPopup(false)} />

          <div className={stylesPopup.popupContent}>

            <div className={transactionStatus === 'waiting' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>

              <CirclesWithBar
                height="50"
                width="50"
                color="#962ce5"
                wrapperStyle={{}}
                wrapperClass={stylesPopup.loaderSpinner}
                outerCircleColor="#962ce5"
                innerCircleColor="#5f93f1"
                barColor="#30d0e9"
                ariaLabel='circles-with-bar-loading'
                visible={transactionStatus === 'waiting' ? true : false}

              />

              {transactionStatus === 'waiting' ? <p className={stylesPopup.PopupInfos}>{t('Transaction.Waiting')}</p> : <p className={stylesPopup.PopupInfos}><FiCheckCircle className={stylesPopup.successIcon} color='white' size="25px" /> {t('Transaction.Confirm')}</p>}
            </div>
            {transactionStatus === 'user confirm' || transactionStatus === 'success' ?
              <>
                <span className={transactionStatus === 'user confirm' || transactionStatus === 'success' ? stylesPopup.popupLineActive : stylesPopup.popupLine}></span>
                <div className={transactionStatus === 'user confirm' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>

                  <CirclesWithBar
                    height="50"
                    width="50"
                    color="#962ce5"
                    wrapperStyle={{}}
                    wrapperClass=""
                    outerCircleColor="#962ce5"
                    innerCircleColor="#5f93f1"
                    barColor="#30d0e9"
                    ariaLabel='circles-with-bar-loading'
                    visible={transactionStatus === 'user confirm' ? true : false}

                  />

                  {transactionStatus === 'user confirm' ? <p className={stylesPopup.PopupInfos}>{t("Transaction.During")}</p> : <p className={stylesPopup.PopupInfos}><FiCheckCircle className={stylesPopup.successIcon} color='white' size="25px" /> {t("Transaction.Success")}</p>}
                </div>
              </>

              : ''}



            {transactionStatus === 'success' ?
              <>
                <span className={transactionStatus === 'success' ? stylesPopup.popupLineActive : stylesPopup.popupLine}></span>
                <div className={transactionStatus === 'success' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>
                  <div className={stylesPopup.popupSuccessMessage}>
                    <p className={stylesPopup.PopupInfos}><FiCheckCircle className={stylesPopup.successIcon} color='white' size="25px" /> {t("Transaction.Congrats")}</p>
                    <p className={stylesPopup.PopupInfos}>{t("Transaction.Congrats2", { collectionName: currentContract ? currentContract.collection_name : '' })}</p> <p className={`${stylesPopup.PopupInfos} ${stylesPopup.PopupInfosLite}`}>{t('Transaction.Conditions')}</p>
                    {transactionReceipt ? <a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${transactionReceipt.hash}`}>{t('Transaction.ShowTransacOnEther')} </a> : ''}
                    <button className={stylesPopup.ProfButton} onClick={handleNavigate}><BiUserCircle className={stylesPopup.successIcon} color='black' size="20px" /> {t('Transaction.Profile')}</button>
                  </div>

                </div>
              </>
              : ''}

            {transactionStatus === 'failed' ?
              <>
                <span className={transactionStatus === 'success' ? stylesPopup.popupLineActive : stylesPopup.popupLine}></span>
                <div className={transactionStatus === 'success' ? `${stylesPopup.popupMessage} ${stylesPopup.popupMessageActive}` : stylesPopup.popupMessage}>
                  <IoMdCloseCircle color='red' className={stylesPopup.successIcon} />
                  <p>{t('Transaction.Error')}</p>
                </div>
              </>
              : ''}

          </div>
        </div>
        : ''}


      {transactionStatus === ('success' || 'waiting' || 'user confirm') && currentContract && userOwnTokens[currentContract.tokenId] > 0
        ? <button className={`${stylesPopup.submitButton} `} disabled >{t("Transaction.ButtonText")}</button>
        : ''}

      {transactionStatus !== ('success' || 'waiting' || 'user confirm') && currentContract && userOwnTokens.length === 0 ?
        <button className={`${stylesPopup.submitButton}`} disabled>{t("Gallery.NormalBuy.ButtonText")} </button>
        : ''
      }
      {
        transactionStatus === '' && currentContract && userOwnTokens[currentContract.tokenId] > 0 ?
          <p>{t("Transaction.NotOwning")} </p>
          : ''

      }
    </>
  )

  //onClick={() => setShowPopup(true)} ligne 368 
  //{currentContract ? currentContract.name : ''} ligne 372
  //onClick={handleMagicMint} ligne 372
}

export default BuyButton