// @ts-nocheck
import React, { useContext } from 'react'
import Header from '../UI/Header'
import { Link, Outlet } from 'react-router-dom';
import { AiFillMail } from 'react-icons/ai'
import UserNFTs from './UserNFTs';
import Footer from '../UI/Footer';
import styles from '../../styles/Users/User.module.scss'
import { FaWallet } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
function User() {
    const [user,setUser] = useContext(UserContext)
    const url = window.location.pathname.split('/')[2]
    const { t } = useTranslation();
    if (user && user.hasOwnProperty('wallet')) {
        if (url === undefined) {
            const name = user.firstname
            return (
                <>
                    <Header />
                    <div className={styles.container}>
                        <Trans i18nKey="User.Title">{{ name }}</Trans>
                        <span>{user.wallet !== null ? <p><FaWallet /> {user.wallet}</p> : <p>{t('ConnectezVous')}</p>}</span>
                        <section className={styles.sectionProfil}>
                            <div className={styles.leftcol}>
                                <span className={styles.titleProfil}>{t('User.Informations')}</span>
                                <div className={styles.contentLeft}>
                                    <span className={styles.infoProfil}>{user.email !== null ? <p><AiFillMail /> {user.email}</p> : ""}</span>
                                    <span className={styles.infoProfil}>{user.username !== null ? `Pseudo : ${user.username}` : ""}</span>
                                    <span className={styles.infoProfil}>{user.firstname !== null ? `${t('User.Prenom')} : ${user.firstname}` : ""}</span>
                                    <span className={styles.infoProfil}> {user.lastname !== null ? `${t('User.Nom')} : ${user.lastname}` : ""}</span>
                                    <span className={styles.infoProfil}> {user.phone !== null ? `${t('User.Tel')} : ${user.phone}` : ""}</span>
                                    <span className={styles.PlaceButton}><Link reloadDocument className={`${styles.EditButton} ${styles.type1}`} to={'/profile/edit/' + user.wallet} ><FiEdit /> {t('User.Editer')}</Link></span>
                                </div>
                            </div>
                            <div className={styles.rightcol}>
                                <span className={styles.titleProduct}>{t('User.VosNFT')}</span>
                                <UserNFTs />
                            </div>
                        </section>
                    </div>

                    <Footer />
                </>
            )

        }
        else {
            return (
                <>
                <Header />
                <Outlet />
                </>
            )

        }
    }
    else if (user && user.hasOwnProperty(user) && user.user === 'loading') {
        return <>
            <Header />
            <p>Chargement du profile</p>
        </>
    } else {
        return <>
            <Header />
        </>
    }

}



export default User