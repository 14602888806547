// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import Header from '../UI/Header'
import { Link, useFetcher, useLoaderData } from 'react-router-dom';


function AdminDashboard() {



    const currentUser = useLoaderData();



    console.log(currentUser)

if(typeof currentUser !== 'undefined')

    if (currentUser.role === 'Administrator') {


    return (
        <React.Fragment>
            <Header />
            <h1>Dashboard Administrateur</h1>
            <Link to='/admin/collections'>
                <button>Collections</button>
            </Link>
         
            <Link to='/admin/contracts'>
                <button>Contrats</button>
            </Link>
            <Link to='/admin/programs'>
                <button>Programs</button>
            </Link>
            <Link to='/admin/talents/create'>
                <button>Créer Artiste</button>
            </Link>
        </React.Fragment>
    )
    }
    else{
        return (<></>)
    }

}


export default AdminDashboard;