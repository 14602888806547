// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { Link,useLoaderData} from 'react-router-dom';
import axios from 'axios'
import Header from '../../UI/Header';
import ArtistCard from '../../Artists/ArtistCard';
import CollectionAPI from '../../../APIs/CollectionAPI';


function AdminCollectionDetails(props) {


    const [collection, setCollection] = useState({ idcollection: '' })
    const [contracts, setContracts] = useState();

    const currentUser = useLoaderData();

    const getCollection = useCallback(async () => {

        const urlencoded = window.location.pathname.split('/')

        const urldecoded = decodeURI(urlencoded[3])
        const response = await CollectionAPI.get(`/name/${urldecoded}`)
        if (response.data.length !== 0) {
            setCollection(response.data[0])
        }


    }, [collection.idcollection, collection.artist, collection.collection_name, collection.nb_contract, collection.preview, collection.created_date, collection.modified_date])

    const getContractsForCollection = useCallback(async () => {
        if (collection.idcollection) {
            const response = await CollectionAPI.get(`/${collection.idcollection}/contracts`)
            if (response.data.length !== 0) {
                setContracts(response.data)
            }
        }


    }, [collection.idcollection, contracts])


    useEffect(() => {
        getCollection()
        getContractsForCollection()
    }, [getCollection])

    console.log(contracts)
    if (currentUser.role === 'Administrator') {
    let contractsDisplay;
    if (contracts !== undefined) {
        contractsDisplay = contracts.map((contract, index) => {
            return (
                <div key={index}>
                    <p>Nom du contrat : {contract.name}</p>
                    <p>Addresse du contrat : {contract.address}</p>
                    <p>Status : {contract.deployed === 1 ? 'En cours' : "A l'arrêt"}</p>
                    <Link to={`/admin/contracts/${contract.name}/`}>
                        <button>+ de détails</button>
                    </Link>
                </div>
            )
        })

    }



    return (
        <React.Fragment>
            <Header />
            <h1>{collection.collection_name ? collection.collection_name : ""}</h1>
            <ArtistCard artistId={collection.artist} />
            {contractsDisplay !== undefined ? contractsDisplay : ''}
        </React.Fragment>
    )
    }else{
        return(<></>)
    }
}



export default AdminCollectionDetails