import React from 'react';

import styles from '../../styles/UI/Banner.module.scss'
import glitchStyles from '../../styles/utils/glitch.module.scss'
import ParticlesBackground from '../UI/Particles/ParticlesBakcground'

function Banner(props) {

  

    return (
        <div className={styles.banner}>
            <ParticlesBackground  type='banner'/>


            <div className={styles.heroContainer}>
                 <h1 className={`${glitchStyles.hero} ${glitchStyles.glitch} ${glitchStyles.layers}`} data-text={props.content}>
                    <span>{props.content}</span>
                </h1>
                <p>{props.content2}</p>
            </div>
        </div>
    )
}
/*            <div className={styles.content}>
                <p className={styles.glitch}>
                    <span aria-hidden="true">{props.content}</span>
                    {props.content}
                    <span aria-hidden="true">{props.content}</span>
                </p>
            </div>*/
export default Banner;