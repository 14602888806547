// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import styles from '../../styles/NFTS/NFTArtistPreview.module.scss'
import stylescard from '../../styles/NFTS/NFTArtistPreviewTalent.module.scss'
import ArtistAPI from '../../APIs/ArtistAPI';
import CollectionAPI from '../../APIs/CollectionAPI';
import Image from '../Image/Image';



function NFTArtistPreview(props) {


    const [collections, setCollection] = useState([]);


    const getArtistCollection = useCallback(async () => {
        const response = await CollectionAPI.get(`/artists/${props.artistId}`)
        const collectionsArr = [];
        response.data.forEach(element => {
            if (response.data.length !== 0) {
                collectionsArr.push({ idCollection: element.idcollection, collectionName: element.collection_name, artistId: element.artist, preview: `${element.preview}` })
            }
        });
        setCollection(collectionsArr)



    }, [collections.collectionName, collections.artistId, collections.preview, props.artistId])


    useEffect(() => {
        getArtistCollection()
    }, [getArtistCollection, props.artistId])


    let collectionDisplay;

    if (collections.collectionName !== null) {
        collectionDisplay = collections.map((collection, index) => {
            console.log(collection)
            return (


                <div key={index} className={`${stylescard.card} ${stylescard.shadow}`} >

                    <img className={stylescard.cardimg} src={collection.preview} />
                    <Link reloadDocument to={`/gallery/${collection.collectionName}`} state={{ data: collection.collectionName }}>
                        <div className={stylescard.imageOverlay}>
                            <h3 className={stylescard.h3Inside}>{collection.collectionName ? collection.collectionName : ''}</h3>

                        </div>
                    </Link>
                </div>

            )
        })
        return (
            <div className={styles.container}>
                {collectionDisplay ? collectionDisplay : ''}
            </div>
        )

    }
    else {
        return (<React.Fragment></React.Fragment>)
    }

}
export default NFTArtistPreview;