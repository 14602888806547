import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown';
import styles from '../../styles/UI/Countdown.module.scss'
import stylesPerso from '../../styles/UI/CountdownPerso.module.scss'
function CustomCountdown(props) {

  const timestamp = props.timestamp;
  const [currentStyle, setCurrentStyle] = useState()

  useEffect(() => {
    if(props.styleName){
        setCurrentStyle(props.styleName)
    }
}, [props.styleName])
  return (
    <div className={currentStyle && currentStyle === 'basic' ? styles.countdown : stylesPerso.countdown  }>
      <Countdown className={currentStyle && currentStyle === 'basic' ? styles.countdownTimer : stylesPerso.countdownTimer} date={new Date(Number(timestamp))} />
      <p className={currentStyle && currentStyle === 'basic' ? styles.countdownTitle : stylesPerso.countdownTitle}>Fin le : {props.dateString}</p>
    </div>
  )
}

export default CustomCountdown