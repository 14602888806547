// @ts-nocheck
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom';
import UserAPI from '../../APIs/UserAPI';
import styles from '../../styles/Users/User.module.scss';
import { AiOutlineWarning } from 'react-icons/ai'
import { BsPatchCheckFill } from 'react-icons/bs'
import { UserContext } from '../Context/Context';

function EditUser() {
    const [updatedUserValue, setUpdatedUserValue] = useState(null)
    const [successMsg, setSuccessMsg] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [isUpdated, setIsUpdated] = useState(false);
    const [user, setUser] = useContext(UserContext)
    const navigate = useNavigate();



    const  fetchUser = useCallback(async ()=> {
        if (user && user.hasOwnProperty('wallet')) {
            const res = await UserAPI.get(`/${user.wallet}`)
            console.log(res)
            setUpdatedUserValue(res.data[0])
        }else {
            setErrorMsg('Veuillez vous connecter')
        }


    },[user])

    async function updateUser() {
        let payload;

        if ((user.wallet && user.wallet === updatedUserValue.wallet) || (user.role === 'Administrator')) {
            payload = { username: updatedUserValue.username, firstname: updatedUserValue.firstname, lastname: updatedUserValue.lastname, email: updatedUserValue.email, phone: updatedUserValue.phone }
        } else {
            return
        }
        const res = await UserAPI.put(`/update/${user.wallet}`, payload).catch((error) => console.log(error))
        if(res.data){
            if (res.data.changedRows >= 1) {
                setSuccessMsg('Votre profil à bien été mis à jour !');
                setIsUpdated(true);
            } else {
                setErrorMsg('Une erreur est surveneue lors de la mise à jour de votre profile. Veuillez réessayer')
            }
        }else{
            setErrorMsg('Une erreur est surveneue lors de la mise à jour de votre profile. Veuillez réessayer')
        }
   

        console.log(res)
        console.log(isUpdated)

    }


    const navigateToProfile = () => {
        navigate('/profile')
        window.location.reload()
    }

    const handleChange = event => {

        if (event) {
            const { name, value } = event.target
            if (value && user != null && updatedUserValue === null) {
                setUpdatedUserValue({ ...user, [name]: value })

            } else if (value && updatedUserValue != null) {
                setUpdatedUserValue({ ...updatedUserValue, [name]: value })
            }

            event.preventDefault();
        }

    }


    useEffect(() => {
        fetchUser()
    }, [user, successMsg, errorMsg, fetchUser])

    console.log(updatedUserValue)



    if (updatedUserValue !== null && user && user.hasOwnProperty('wallet')) {
        const url = window.location.pathname.split('/')[3].toLowerCase()
        const walletLowerCase = user.wallet.toLowerCase()
        return (
            
                <div className={styles.edituser}>
                    <h1>Profil de {user.username}</h1>
                    <p>{user ? `Addresse du wallet : ${user.wallet}` : "Veuillez vous connecter "}</p>
                    <p><input name='username' placeholder="Pseudo" onChange={handleChange} defaultValue={updatedUserValue.username !== null ? `${updatedUserValue.username}` : ""}></input></p>
                    <p><input name='firstname' placeholder="Prénom" onChange={handleChange} defaultValue={updatedUserValue.firstname !== null ? `${updatedUserValue.firstname}` : ""}></input></p>
                    <p><input name='lastname' placeholder="Nom" onChange={handleChange} defaultValue={updatedUserValue.lastname !== null ? `${updatedUserValue.lastname}` : ""}></input></p>
                    <p><input name='email' placeholder="Email" onChange={handleChange} defaultValue={updatedUserValue.email !== null ? `${updatedUserValue.email}` : ""}></input></p>
                    <p><input name='phone' placeholder="Téléphone" onChange={handleChange} defaultValue={updatedUserValue.phone !== null ? `${updatedUserValue.phone}` : ""}></input></p>
                    {isUpdated ? <button onClick={navigateToProfile}>Retour au profil</button> : ''}
                    {(user && user.hasOwnProperty('wallet') & walletLowerCase === url) || (user && user.hasOwnProperty('wallet') && user.role === 'Administrator') ? <button onClick={updateUser}>Mettre à jour</button> : ''}
                    {successMsg != null ? <p><BsPatchCheckFill /> {successMsg}</p> : ''}
                    {errorMsg != null ? <p><AiOutlineWarning /> {errorMsg}</p> : ''}
                </div>
            
        )
    } else if(user && user.hasOwnProperty('user') && user.user === 'loading') {
        return (<h1>Loading</h1>)
    }else {
        <p>Vous n'êtes pas connecté</p>
    }

}

export default EditUser