// @ts-nocheck
import { ethers } from 'ethers';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios'
import Header from '../../UI/Header';
import * as ContractFunctions from '../../../Functions/ContractFunctions'
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ContractAPI from '../../../APIs/ContractAPI';
import Image from '../../Image/Image';
import styles from '../../../styles/Admin/ContractDetails.module.scss'
import { useLoaderData } from 'react-router-dom'
import { ProviderContext } from '../../Context/Context';

function ContractDetails(props) {
    const currentUser = useLoaderData();

    const [contract, setContract] = useState({
        address: '',
        collection_id: '',
        created_date: '',
        deployed: '',
        id_contracts: '',
        modified_date: '',
        name: '',
        owner: ''
    })

    const [metadata, setMetadata] = useState([])

    const [provider] = useContext(ProviderContext)


    const getContract = useCallback(async () => {
        const contractName = window.location.pathname.split('/');
        const decodedUri = decodeURI(contractName[3])
        if(provider.hasOwnProperty("_isProvider")){
            try {
                const response = await ContractAPI.get(`/name/${decodedUri}`)
                console.log(response)
                if (response.data.length !== 0) {
                    const responseData = response.data[0];
                    const jsonContract = await ContractFunctions.loadAbiContract(decodedUri, responseData.collection_id)
                    const newContract = new ethers.Contract(responseData.address, jsonContract.abi, provider)
    
                    const uri = await newContract.uri(0)
                    
                    let uriFormatted;
                    if (uri) {
                    
                        if (uri.substring(0, 4) === 'ipfs') {
                            uriFormatted = uri.replace('ipfs://', 'https://nfsquat.mypinata.cloud/ipfs/')
                        } else {
                            uriFormatted = uri
                        }
    
                        const tokenIds = [0, 1, 2];
                        let metadatasArr = [];
                        console.log(uriFormatted)
                        for (let i = 0; i < tokenIds.length; i++) {
    
                            let metadata_pinata;
                            if (i > 0) {
                                metadata_pinata = await axios.get(uriFormatted.replace('0.json', `${i}.json`))
                            } else {
    
                                metadata_pinata = await axios.get(uriFormatted)
                            }
    
    
    
                            if (metadata_pinata.data.length !== 0) {
                                metadatasArr.push(metadata_pinata.data)
                            }
                        }
                        setMetadata(metadatasArr)
    
    
                    }
    
    
                    setContract(response.data[0])
    
                }
            } catch (e) {
                if(e) throw e
            }
        }
       

    }, [contract.address, contract.collection_id, contract.created_date, contract.deployed, contract.id_contracts, contract.modified_date, contract.name, contract.owner, metadata.length])


    function formatImage(image) {
        let ipfsURL;
        if (image) {
           
            if (image.includes('gateway.pinata.cloud')) {
                ipfsURL = image.replace('gateway.pinata.cloud', 'nfsquat.mypinata.cloud')
            } else {
                ipfsURL = image
            }
        }
        return ipfsURL

    }

    useEffect(() => {
        getContract()
    }, [getContract])

    if (typeof currentUser !== 'undefined') {
        if (currentUser.role === 'Administrator') {
            let metadatasDisplay = '';
            if (metadata) {
                console.log(metadata)
                console.log(contract)
                metadatasDisplay = metadata.map((meta, index) => {
                    return (
                        <div key={index} className={styles.details}>
                            <p>{meta.name}</p>
                            <Image urls={[formatImage(meta.image)]} alt=''/>
                        </div>
                    )
                })

                return (

                    <React.Fragment>
                        <Header />
                        <h1>{typeof metadata.name !== 'undefined' ? metadata.name : ''}</h1>
                        <div className={styles.container}>

                            {metadatasDisplay !== '' ? metadatasDisplay : ''}
                        </div>
                        <p>Propriétaire : {contract.owner !== '' ? contract.owner : ''}</p>
                        <p>Addresse du contrat : {contract.address !== '' ? contract.address : ''}</p>
                        <Link to={`/admin/contracts/${contract.address}/edit`}>
                            <FaEdit />
                        </Link>
                    </React.Fragment>
                )
                // {contract.collection_id !== '' ? <CollectionsCard collectionId={contract.collection_id} /> : ''}
            }
        } else {
            return (
                <React.Fragment>
                    <Header />
                    <h1>Loading</h1>
                </React.Fragment>
            )
        }
    } else {
        return (<></>)
    }

}



export default ContractDetails