// @ts-nocheck
import { ethers } from 'ethers'
import CollectionAPI from '../APIs/CollectionAPI';
import ContractAPI from '../APIs/ContractAPI';



const getCurrentAccount = async () => {
    try {
        const eth_account = await window.ethereum.request({ method: 'eth_requestAccounts' })
        return eth_account[0]
    } catch (e) {
        throw Error(e.messagge)
    }
}

const setContractMaxSupply = async (owner, contractName, contractAddress, collectionId, maxSupply) => {
    if (typeof window.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' })
        if ((account[0] !== "" && owner.toLowerCase() === account[0])) {
            try {


                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const ABI = await loadAbiContract(contractName, collectionId)
                const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                const currentMaxSupply = await contract.maxSupply()
                if (currentMaxSupply !== maxSupply) {
                    try {
                        const transaction = await contract.setMaxSupply(maxSupply)
                        await transaction.wait();
                    } catch (e) {
                        console.log(e.message)
                        console.log(e.code)
                    }

                } else {
                    throw Error('Invalid number Supply type')
                }
            } catch (e) {
                throw Error(e.messagge)
            }
        } else {
            throw Error('Veuillez installer Metamask')

        }

    }
}

const setContractBaseURI = async (owner, contractName, contractAddress, collectionId, newURI) => {
    if (typeof window.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' })
        if ((account[0] !== "" && owner.toLowerCase() === account[0])) {
            try {

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const ABI = await loadAbiContract(contractName, collectionId)
                const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                const currentBaseURI = await contract.uri(0)
                if (currentBaseURI !== newURI) {
                    try {
                        const transaction = await contract.setURI(newURI)
                        await transaction.wait();
                        await ContractAPI.post('/update', { baseURI: newURI, address: contractAddress })

                    } catch (e) {
                        console.log(e.message)
                        console.log(e.code)
                    }

                } else {
                    throw Error('Invalid baseURI type')
                }
            } catch (e) {
                throw Error(e.messagge)
            }
        } else {
            throw Error('Veuillez installer Metamask')

        }
    }
}

const setContractMaxMint = async (owner, contractName, contractAddress, collectionId, maxMint) => {

    if (typeof window.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' })
        if ((account[0] !== "" && owner.toLowerCase() === account[0])) {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const ABI = await loadAbiContract(contractName, collectionId)
                const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                const currentMaxMintAmount = await contract.maxMintAmount()
                if (currentMaxMintAmount !== maxMint) {
                    try {
                        const transaction = await contract.setmaxMintAmount(maxMint)
                        await transaction.wait();
                    } catch (e) {
                        console.log(e.message)
                        console.log(e.code)
                    }

                } else {
                    throw Error('Invalid amount')
                }
            } catch (e) {
                throw Error(e.messagge)
            }
        } else {
            throw Error('Veuillez installer Metamask')
        }

    }
}
const setContractStatus = async (owner, contractName, contractAddress, collectionId, status) => {

    if (typeof window.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' })
        if ((account[0] !== "" && owner.toLowerCase() === account[0])) {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const ABI = await loadAbiContract(contractName, collectionId)
                const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                const currentStatus = await contract.paused()
                if (currentStatus.toString() !== status) {
                    try {
                        const transaction = await contract.pause(status)
                        await transaction.wait();
                    } catch (e) {
                        console.log(e.message)
                        console.log(e.code)
                    }

                } else {
                    throw Error('Invalid type')
                }

            } catch (e) {
                throw Error(e.messagge)
            }
        }
    } else {
        throw Error("Veuillez installer metamask")
    }

}

const setContractCost = async (owner, contractName, contractAddress, collectionId, cost) => {
    if (typeof window.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_accounts' })
        if ((account[0] !== "" && owner.toLowerCase() === account[0])) {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const ABI = await loadAbiContract(contractName, collectionId)
                const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                const ethersToWei = ethers.utils.parseUnits(String(cost), "ether")
                const currentCost = await contract.cost()
                if (currentCost !== ethersToWei) {
                    try {
                        const transaction = await contract.setCost(ethersToWei)
                        await transaction.wait()
                    } catch (e) {
                        console.log(e.message)
                        console.log(e.code)
                    }
                } else {
                    throw Error('Invalid type')
                }
            } catch (e) {
                if (e)
                    throw Error(e.message)
            }

        }
    } else {
        throw Error('Veuillez installer metamask')

    }
}


const withdraw = async (contractName, contractAddress, collectionId) => {
    if (typeof window.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_accounts' })
        if (account[0] !== "") {
            const response = await ContractAPI.get(`/name/${contractName}`)
            if (response.data.length !== 0) {
                const owner = response.data[0].owner
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const ABI = await loadAbiContract(contractName, collectionId)
                    const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                    if (account[0] === owner.toLowerCase()) {
                        try {
                            const transaction = await contract.withdraw(account[0])
                            await transaction.wait()
                        } catch (e) {
                            console.log(e.message)
                            console.log(e.code)
                        }
                    } else {
                        throw Error('Invalid Owner')
                    }
                } catch (e) {
                    throw Error(e.messagge)
                }
            }
        }

    } else {
        throw Error('Veuillez installer metamask')
    }
}


const deployContractERC721 = async (contract) => {
    if (contract.name.length !== 0 || typeof window.ethereum !== 'undefined') {
        try {
            const account = await window.ethereum.request({ method: 'eth_accounts' })
            console.log(account)
            if (account[0] !== '') {
                const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_METAMASK_ACCOUNT_KEY
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const wallet = new ethers.Wallet(PRIVATE_KEY, provider)
                const collectionName = contract.collectionName.replaceAll(' ', '-')
                const ABI = await import(`../artifacts/contracts/${collectionName.toLowerCase()}/${contract.name}.sol/${contract.name}.json`)
                const bytecode = ABI.bytecode
                const factory = new ethers.ContractFactory(ABI.abi, bytecode, wallet)
                const newContract = await factory.deploy(contract.name, contract.symbol, contract.ipfs)

                console.log(factory)
                const payload = {
                    name: contract.name,
                    collection_id: contract.collectionId,
                    address: newContract.address,
                    owner: account[0].toLowerCase(),
                    deployed: true,
                    ipfs: contract.ipfs,
                    filename: contract.filename
                }

                console.log(payload)
                await ContractAPI.post('/create', payload)
                await newContract.deployed()
            } else {
                throw Error("Veuillez vous connecter à votre compte Metamask")
            }
        } catch (e) {
            throw Error(e.message)
        }
    } else {
        throw Error('Veuillez installer metamask')
    }
}

const deployContractERC1155 = async (contract) => {
    if (contract.name.length !== 0 || typeof window.ethereum !== 'undefined') {
        try {
            const account = await window.ethereum.request({ method: 'eth_accounts' })
            console.log(account)
            if (account[0] !== '') {
                console.log(contract)
                console.log(account)
                let collectionNameFormatted;
                if (contract.collectionName.includes('#')) {
                    collectionNameFormatted = contract.collectionName.replace('#', '')
                } else {
                    collectionNameFormatted = contract.collectionName
                }
                
                const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_METAMASK_ACCOUNT_KEY
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const wallet = new ethers.Wallet(PRIVATE_KEY, provider)
                const collectionName = collectionNameFormatted.replaceAll(' ', '-')
                const ABI = await import(`../artifacts/contracts/${collectionName.toLowerCase()}/${contract.name}.sol/${contract.name}.json`)
                const bytecode = ABI.bytecode
                const factory = new ethers.ContractFactory(ABI.abi, bytecode, wallet)


                const newContract = await factory.deploy(contract.name, contract.symbol, contract.ipfs, contract.prices, {gasLimit: 5000000})

                console.log(newContract)
                const payload = {
                    name: contract.name,
                    collection_id: contract.collectionId,
                    address: newContract.address,
                    owner: account[0].toLowerCase(),
                    deployed: true,
                    ipfs: contract.ipfs,
                }

                console.log(payload)
                await ContractAPI.post('/create', payload)
                await newContract.deployed()
            } else {
                throw Error("Veuillez vous connecter à votre compte Metamask")
            }
        } catch (e) {
            throw Error(e.message)
        }
    } else {
        throw Error('Veuillez installer metamask')
    }
}

const mint = async (tokenId, contractName, contractAddress, collectionId) => {

    if (contractName.length !== 0 || typeof window.ethereum !== 'undefined') {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            if (accounts[0] !== '') {
                const ABI = await loadAbiContract(contractName, collectionId)
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner()
                const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                const cost = await contract.prices(tokenId)
                const maxSupply = await contract.supplies(tokenId);
                const totalSupply = await contract.totalSupply(tokenId);

                const gasPrice = signer.getGasPrice();
                let overrides = {
                    value: cost 
                }
                let transaction;
                if ((Number(totalSupply) < Number(maxSupply))) {
                    const gasLimit = contract.estimateGas.publicMint(tokenId, overrides)
                    overrides.gasLimit = gasLimit
                    overrides.gasPrice = gasPrice
                    transaction =  await contract.publicMint(tokenId, overrides)
                    await transaction.wait().then((res) => {
                        return res
                    })

                } else {
                    console.log((Number(maxSupply) > 1))
                    console.log((totalSupply.toNumber() < maxSupply))
                    console.log(Number(maxSupply))
                    console.log(Number(totalSupply))
                }
            } else {
                throw Error('Veuillez vous connecter metamask')

            }
        } catch (err) {
            console.error(err)

        }

    } else {
        throw Error('Veuillez installer metamask')
    }


}

const burn = async (contractName, _contractAddress) => {

    if (contractName.length !== 0 || typeof window.ethereum !== 'undefined') {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            if (accounts[0] !== '') {
                const response = await ContractAPI.get(`/name/${contractName}`)
                if (response.data.length !== 0) {
                    const owner = response.data[0].owner
                    if (accounts[0] === owner) {

                        const contractToDelete = await ContractAPI.delete(`/delete/address/${_contractAddress}`)
                        if (contractToDelete.status === 200) {
                            window.location.replace('http://localhost:3000/admin/contracts')
                        } else {
                            throw Error("Le contrat n'existe pas ")
                        }
                    }

                }

            } else {
                throw Error('Veuillez vous connecter metamask')

            }
        } catch (err) {
            console.log(err)
            throw Error('Veuillez vous connecter metamask')
        }

    } else {
        throw Error('Veuillez installer metamask')
    }


}

const setMintStatus = async (owner, contractName, contractAddress, collectionId) => {


    if (typeof window.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' })
        if ((account[0] !== "" && owner.toLowerCase() === account[0])) {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const ABI = await loadAbiContract(contractName, collectionId)
                const contract = new ethers.Contract(contractAddress, ABI.abi, signer);
                const publicMintStatus = await contract.publicMintOpen()
                if (publicMintStatus === true) {
                    try {
                        const transaction = await contract.editMintWindows(false, true)
                        await transaction.wait();
                    } catch (e) {
                        console.log(e.message)
                        console.log(e.code)
                    }

                } else if (publicMintStatus === false) {
                    try {
                        const transaction = await contract.editMintWindows(true, false)
                        await transaction.wait();
                    } catch (e) {
                        console.log(e.message)
                        console.log(e.code)
                    }
                } else {
                    throw Error('Invalid type')
                }

            } catch (e) {
                throw Error(e.messagge)
            }
        }
    } else {
        throw Error("Veuillez installer metamask")
    }

}

const loadAbiContract = async (contractName, collectionId) => {

    if (contractName.length !== 0) {
        try {
            const collection = await CollectionAPI.get(`/id/${collectionId}`)
            if (collection.data.length !== 0) {
                const collectionName = collection.data[0].collection_name.replaceAll(' ', '-')
                const json = await import(`../artifacts/contracts/${collectionName.toLowerCase()}/${contractName}.sol/${contractName}.json`)
                return json
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export { getCurrentAccount, setContractMaxSupply, setContractBaseURI, setContractCost, setContractMaxMint, setContractStatus, withdraw, deployContractERC721, deployContractERC1155, loadAbiContract, mint, burn, setMintStatus }



