// @ts-nocheck
import React, { useEffect, useState, useCallback } from 'react'
import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import { ethers } from 'ethers'
import App from '../../App';
import Galery from '../Galery/Galery'
import ArtistCollection from '../Artists/ArtistCollection'
import Artist from '../Artists/Artist'
import User from '../Users/User';
import CreateArtist from '../Admin/Artists/CreateArtist';
import EditUser from '../Users/EditUser'
import EditContract from '../Admin/Contracts/EditContract'
import EditArtist from '../Admin/Artists/EditArtist';
import CreateContract from '../Admin/Contracts/CreateContract';
import AdminDashboard from '../Admin/AdminDashboard';
import AdminCollectionsList from '../Admin/Collections/AdminCollectionsList';
import CreateCollection from '../Admin/Collections/CreateCollection';
import ContractsList from '../Admin/Contracts/ContractsList';
import ContractDetails from '../Admin/Contracts/ContractDetails';
import AdminCollectionDetails from '../Admin/Collections/AdminCollectionDetails';
import Collection from '../Galery/Collection';
import UserAPI from '../../APIs/UserAPI'
import AdminProgramList from '../Admin/Programs/AdminProgramList'
import '../../styles/UI/Header.scss';
import AdminCreateProgram from '../Admin/Programs/AdminCreateProgram';
import CG from '../CG/CG';
import LotteryRules from '../CG/LotteryRules';
import Default404 from '../UI/Default404';
import { ProviderContext, UserContext } from '../Context/Context';

import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
function CustomRouter() {


    const [user, setUser] = useState(null)
    const [provider, setProvider] = useState(null)
    const ethereum = window.ethereum;
    const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY,
        {
            extensions: [new ConnectExtension()]
        })


    if (typeof ethereum !== 'undefined') {
        const handleAccountChanged = (accounts) => {
            if (accounts[0] !== '') {
                userExistInDb()
            }
        }

        ethereum.on('accountsChanged', handleAccountChanged)
    }


    const userExistInDb = async (wallet) => {
        const user = await UserAPI.get(`/${wallet}`)
        if (user.data.length !== 0) {
            return user.data[0]
        } else {
            return false
        }
    }


    const createUserForDB = async (payload) => {
        const userCreated = await UserAPI.post(`/create`, payload)
        if (userCreated.affectedRows !== 0) {
            const newUser = await UserAPI.get(`/${payload.wallet}`)
            return newUser
        }
    }

    if (typeof window.ethereum !== 'undefined') {
        ethereum.on('accountsChanged', async () => {
            checkUserMagic();
        })
    }

    const checkUserMagic = useCallback(async () => {

        await magic.wallet.getInfo()
            .then(async (response) => {
                let accounts;
                if (provider && provider.hasOwnProperty('_isProvider')) {
                    accounts = await provider.listAccounts().then(accounts => accounts[0]);
                } else {
                    const providerPerso = new ethers.providers.Web3Provider(magic.rpcProvider);
                    accounts = await providerPerso.listAccounts().then(accounts => accounts[0]);

                }
                const userExist = await userExistInDb(accounts)
                if (userExist) {
                    userExist.chainId = 1
                    setUser(userExist);
                } else {
                    const payload = { wallet: accounts, walletType: response.walletType }
                    const newUser = await createUserForDB(payload)
                    if (newUser.data.length !== 0) {
                        newUser.data[0].chainId = 1
                        setUser(newUser.data[0])
                    } else {
                        setUser({ user: null })
                    }
                }


            }).catch((e) => {
                console.log(e)
                console.log('nop')
                setUser({ user: null })


            })

    }, [setUser, magic.rpcProvider, magic.wallet, provider])


    useEffect(() => {
        if (!user) {

            setUser({ user: 'loading' });
            checkUserMagic();
        }
    }, [checkUserMagic, user]);



    console.log(user)
    useEffect(() => {
        if (provider === null) {
            setProvider({ loading: true })
            const provider = new ethers.providers.Web3Provider(magic.rpcProvider);
            setProvider(provider)
        }
        console.log(provider)
    }, [provider])

    const routes = [

        <Route exact path='/' element={<App />} />,
        <Route exact path='/gallery' element={<Galery />} />,
        <Route exact path='/gallery/:collectionName' element={<Collection />} />,
        <Route exact path='/talents' element={<ArtistCollection />} />,
        <Route exact path='/talents/:artistSlug' element={<Artist />} />,
        <Route exact path='/about-us' element={<App />} />,
        <Route exact path='/cgu' element={<CG />} />,
        <Route exact path='/cgv' element={<CG />} />,
        <Route exact path='/privacy' element={<CG />} />,
        <Route exact path='/lottery-rules' element={<LotteryRules />} />,
        <Route exact path='/profile' element={<User />}  >
            <Route path='/profile/edit/:wallet' element={<EditUser />} />
        </Route>,
        <Route exact path='/admin' element={<AdminDashboard />} />,
        <Route exact path='/admin/talents/create' element={<CreateArtist />} />,
        <Route exact path='/admin/talents/edit/:artistSlug' element={<EditArtist />} />,

        <Route exact path='/admin/collections' element={<AdminCollectionsList />} />,
        <Route exact path='/admin/collections/:collectionName' element={<AdminCollectionDetails />} />,
        <Route exact path='/admin/collections/create' element={<CreateCollection />} />,

        <Route exact path='/admin/contracts' element={<ContractsList />} />,
        <Route exact path='/admin/contracts/:contractName' element={<ContractDetails />} />,
        <Route exact path='/admin/contracts/:contractAdress/edit' element={<EditContract />} />,
        <Route exact path='/admin/contracts/create' element={<CreateContract />} />,

        <Route exact path='/admin/programs' element={<AdminProgramList />} />,
        <Route exact path='/admin/programs/create' element={<AdminCreateProgram />} />,
        <Route exact path='*' element={<Default404 />} />



    ]

    const router = createBrowserRouter(createRoutesFromElements(routes))




    return (
        <UserContext.Provider value={[user, setUser]}>
            <ProviderContext.Provider value={[provider, setProvider]}>

                <RouterProvider router={router} />
            </ProviderContext.Provider>
        </UserContext.Provider>
    )
}

export default CustomRouter;