// @ts-nocheck
import React from "react";
import Particles from "react-tsparticles";
import {loadFull} from 'tsparticles'
import particlesBanner from "./particles_banner";
import particlesContent from './particles_content'
import styles from '../../../styles/UI/Particles.module.scss'

function ParticlesBackground(props){


    const particlesInit = async (main) => {
        await loadFull(main)
    }

    const particlesLoaded = (container) => {
    }

if(props.type === 'banner'){
    return (
        <Particles
        id='tsparticles-banner'
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesBanner}
        className={styles.particlesBanner}></Particles>
    )
}
if(props.type === 'content'){
    return (
        <Particles
        id='tsparticles-content'
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesContent}
        className={styles.particlesContent}></Particles>
    )
}


}

export default ParticlesBackground